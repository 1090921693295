import { getGlobal } from "../global";
import { PyScriptInterpreter, PyScriptLinkage } from "./types";

const g = getGlobal();

export const RuntimeStatus = {
  NOT_STARTED: "Not Started",
  INITIALIZING: "Initializing...",
  LOADING_PYODIDE_PACKAGES: "Loading pyodide packages...",
  LOADING_MICROPIP_PACKAGES: "Loading micropip packages...",
  LOADING_PYSCRIPT_APPS: "Loading pyscript apps...",
  RUNNING_INIT_CODE: "Running imports and definitions...",
  RUNNING: "Running",
  ERROR: "Error",
};

export interface PyWorkerConfig {
  pyodideVersion: string;
  pyodidePackages: string[];
  pyscriptApps: string[];
  micropipPackages: string[];
  initCode: string;
}

export interface PyScriptSettings extends PyWorkerConfig {
  defaultInterpreter: PyScriptInterpreter;
  defaultMode: number;
}

export interface RegistryRequest {
  address: string;
  code: string;
  mode: number;
  data: Record<string, any>;
}

export interface RegistryResponse {
  mode: number;
  Python_typeName: string;
  preview?: any[][];
  size?: number[];
  result?: any;
  errorKind?: string;
  error?: string;
}

export interface PyWorker {
  readStdout: () => Promise<string[]>;
  readStderr: () => Promise<string[]>;
  setRequest: (rndId: string, value: RegistryRequest) => Promise<void>;
  getResponse: (rndId: string, clear?: boolean) => Promise<RegistryResponse>;
  setCloudData: (address: string, data: any) => Promise<void>;
  delCloudData: (address: string) => Promise<void>;
  loadPackages: (pkgs: string[]) => Promise<string | null>;
  loadPyScriptApps: (pkgs: string[]) => Promise<string | null>;
  micropipInstall: (pkgs: string[]) => Promise<string | null>;
  _runInit: (initCode: string) => Promise<runInitError | null>;
  resetKernel: (clearIsolated?: boolean) => Promise<void>;
  missingFromXLResults: (names: string[]) => Promise<string[]>;
  initialize: () => Promise<void>;
  runPythonCode: (rndId: string) => Promise<void>;
  runUDF: (rndId: string, funcName: string, address: string, args: any[]) => Promise<void>;
  evalPythonCode: (rndId: string, code: string, linkage: PyScriptLinkage) => Promise<void>;
}

export interface runInitError {
  Python_typeName: string;
  error: string;
  errorKind: string;
  mode: number;
}

export interface WorkerInfo {
  worker: PyWorker;
  terminate: () => void;
  loadingFlag: boolean;
  config: PyWorkerConfig | (() => Promise<PyWorkerConfig>);
}

export async function getPyWorker(
  info: WorkerInfo,
  setRuntimeStatusFn?: (status: string) => void,
  customInit?: (py: PyWorker) => Promise<void>
): Promise<PyWorker> {
  if (info.worker === undefined) {
    if (!info.loadingFlag) {
      // Grab the flag
      info.loadingFlag = true;
      // Wait for PyWorker to be available
      while (!g.PyWorker) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    } else {
      // Wait for 0.5 seconds and try again
      await new Promise((resolve) => setTimeout(resolve, 500));
      return await getPyWorker(info);
    }
    // Resolve any delayed settings
    if (typeof info.config === "function") {
      info.config = await info.config();
    }
    const rawWorker = await g.PyWorker("/pyscript/static/pyworker/worker.py", {
      version: info.config.pyodideVersion,
      config: {
        sync_main_only: true,
        files: {
          "/pyscript/static/pyworker/utils.py": "",
          "/pyscript/static/pyworker/udf.py": "",
        },
      },
    });
    info.terminate = () => rawWorker.terminate();
    const py: PyWorker = rawWorker.sync;

    // Pre-load packages
    await py.loadPackages(["setuptools"]);
    if (info.config.pyodidePackages?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYODIDE_PACKAGES);
      const error = await py.loadPackages(info.config.pyodidePackages);
      if (error) throw new Error(error);
    }

    if (info.config.pyscriptApps?.length) {
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_PYSCRIPT_APPS);
      const error = await py.loadPyScriptApps(info.config.pyscriptApps);
      if (error) throw new Error(error);
    }

    if (info.config.micropipPackages?.length) {
      await py.loadPackages(["micropip"]);
      if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.LOADING_MICROPIP_PACKAGES);
      await py.micropipInstall(info.config.micropipPackages);
      const error = await py.micropipInstall(info.config.micropipPackages);
      if (error) throw new Error(error);
    }
    // Initialize
    if (setRuntimeStatusFn) setRuntimeStatusFn(RuntimeStatus.RUNNING_INIT_CODE);
    await py.initialize();
    if (customInit) {
      await customInit(py);
    } else {
      const error = await py._runInit(info.config.initCode);
      if (error) {
        const errorMessage = `Error Kind: ${error.errorKind}\nError Details: ${error.error}`;
        throw new Error(errorMessage);
      }
    }

    info.worker = py;
  }
  return info.worker;
}
