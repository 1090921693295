import React from "react";
import { CodeBuilder } from "./CodeBuilder";

export class ValueTracker<T> {
  initialValue: T;
  value: T;
  isUpdated: boolean;

  constructor(val: T) {
    this.initialValue = val;
    this.value = val;
    this.isUpdated = false;
  }

  update(val: T): ValueTracker<T> {
    const newVal = new ValueTracker<T>(val);
    newVal.initialValue = this.initialValue;
    newVal.isUpdated = true;
    return newVal;
  }

  reset(): ValueTracker<T> {
    return new ValueTracker<T>(this.initialValue);
  }
}

export interface UIAttr {
  visibleDependencies?: Dependency[];
  getReact: (labelWidth: number) => React.ReactNode;
  getCode?: (code: CodeBuilder) => void;
}

export interface ChartAttr extends UIAttr {
  component: React.ReactNode;
  enabledDependencies?: Dependency[];
  getCode: (code: CodeBuilder) => void; // CodeBuilder is modified inplace
  codeKey?: string;
  callKey?: string;
  codeValueMap?: Record<string, any>;
}

export interface Dependency {
  object: ChartAttr;
  isSatisfied?: () => boolean;
}

export interface Chart {
  baseAttrs: UIAttr[];
  designAttrs: UIAttr[];
}

export function areDependenciesSatisfied(dependencies: Dependency[]) {
  if (dependencies) {
    const depVals = dependencies.map((dep: Dependency) => dep?.isSatisfied?.());
    const allDepsSatisfied = depVals.reduce((accum, curVal) => accum && curVal, true);
    return allDepsSatisfied;
  }
  return true;
}

export const Orientation = { Horizontal: "h", Vertical: "v" };

export const colorOrder = ["Column Order", "Reverse Column Order", "Alphabetical A-Z", "Alphabetical Z-A"];

export const IntervalOptions = [
  "No error bars",
  "Confidence Interval",
  "Percentage Interval",
  "Standard Error",
  "Standard Deviation",
];

export const IntervalValueMap = {
  "No error bars": "None",
  "Confidence Interval": "ci",
  "Percentage Interval": "pi",
  "Standard Error": "se",
  "Standard Deviation": "sd",
};

export const Estimators = {
  Mean: "mean",
  Median: "median",
  Max: "max",
  Min: "min",
  Sum: "sum",
};

export const RegEstimators = {
  Mean: "np.mean",
  Median: "np.median",
  Max: "np.max",
  Min: "np.min",
};

export const Axes = {
  Linear: "linear",
  Log: "log",
};

export const Markers = {
  Point: ".",
  Circle: "o",
  Square: "s",
  Plus: "+",
  Diamond: "D",
  TriangleUp: "^",
  TriangleDown: "v",
  Star: "*",
  X: "x",
};

export const LineStyle = {
  Solid: "-",
  Dashed: "--",
};

export const RegFunction = {
  Logistic: "logistic",
  Lowess: "lowess",
  Robust: "robust",
  Logx: "logx",
};

export const BinStats = {
  Count: "count",
  Frequency: "frequency",
  Probability: "probability",
  Percent: "percent",
  Density: "density",
};

export const Elements = { Bars: "bars", Step: "step", Poly: "poly" };

export const LegendPosition: Record<string, { location: string; extra?: string }> = {
  Best: { location: "best" },
  "Upper Left": { location: "upper left" },
  "Upper Center": { location: "upper center" },
  "Upper Right": { location: "upper right" },
  "Middle Left": { location: "center left" },
  "Middle Center": { location: "center" },
  "Middle Right": { location: "center right" },
  "Lower Left": { location: "lower left" },
  "Lower Center": { location: "lower center" },
  "Lower Right": { location: "lower right" },
  Right: { location: "center left", extra: ", bbox_to_anchor=(1.0, 0.5)" },
  Top: { location: "lower center", extra: ", bbox_to_anchor=(0.5, 1.0)" },
  "Top (3 Columns)": { location: "lower center", extra: ", bbox_to_anchor=(0.5, 1.0), ncol=3, frameon=False" },
};
