// export type AuthTokenInfo = {
//   access_token: string;
//   expires_in: number;
//   token_type: string; // Bearer
//   scope?: string;
//   id_token?: string;
// };

export type AuthSessionInit = {
  session_id: string;
  decoder: string;
  remember_me: boolean;
};

export type UserProfile = {
  is_confirmed?: boolean;
  is_disabled?: boolean;
};

export type Subscription = {
  expires_at?: string;
  id?: string;
  org_id?: string;
  product_code?: ProductCode;
};

export type User = {
  company?: string;
  company_size?: string;
  country?: string;
  created_at: string;
  email: string;
  external_id?: string;
  first_home_visit?: boolean;
  first_name?: string;
  id: string;
  industry?: string;
  last_name?: string;
  partner_customer_id?: string;
  partner_id?: string;
  position?: string;
  promotional_content?: boolean;
  state?: string;
  updated_at?: string;
  username?: string;
};

export type Account = {
  profile?: UserProfile;
  subscriptions?: Subscription[];
  user: User;
  metadata: {
    is_starter_tier: boolean;
    is_pro_tier: boolean;
  };
};

export enum ProductCode {
  CommercialSubscription = "commercial_subscription",
  SecuritySubscription = "security_subscription",
  StarterSubscription = "starter_subscription",
  ProfessionalSubscription = "professional_subscription",
  BusinessSubscription = "business_subscription",
  FreeSubscription = "free_subscription",
}

export const ProductCodeToName: Record<string, string> = {
  [ProductCode.FreeSubscription]: "Free Tier",
  [ProductCode.StarterSubscription]: "Starter Tier",
  [ProductCode.ProfessionalSubscription]: "Professional Tier",
  [ProductCode.CommercialSubscription]: "Professional Tier",
  [ProductCode.BusinessSubscription]: "Business Tier",
  [ProductCode.SecuritySubscription]: "Business Tier",
};

export type Organization = {
  bio: string;
  created_at: string;
  deletion_scheduled: boolean;
  email: string;
  id: string;
  max_members: number;
  members_count: number;
  name: string;
  role: string;
  title: string;
  type: string;
  url: string;
};

export type Organizations = Organization[];

export type OrganizationGroup = {
  id: string;
  name: string;
  org_id: string;
  created_at: string;
  updated_at: string;
};

export type OrganizationUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  groups: OrganizationGroup[];
  role: "member" | "admin";
  subscriptions: string[];
};

export type OrganizationUsers = {
  filtered_count: number;
  items: OrganizationUser[];
  total_count: number;
};
