import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Field,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from "@fluentui/react-components";
import { Add16Filled, ArrowMoveRegular, CopyRegular, DeleteRegular } from "@fluentui/react-icons";
import { Row } from "../../components/Layout/Space";
import PlotService, { VizDef, VizDefIdentifier } from "../../../data/plot-api";
import { plotsLookup } from "../../../data/plot-types";
import { useChartManager } from "../../hooks/plots/useCentralViz";
import { PopoverMenuButton } from "../../components/PopoverMenuButton";
import { runExcelSafeContext } from "xlcommon/src/excel/excel-grid-utils";
import styles from "../../styles.module.scss";

const COLUMNS = [
  { columnKey: "plotType", label: "Chart Type", width: 105 },
  { columnKey: "sheetName", label: "Sheet", width: null },
  { columnKey: "addr", label: "Cell", width: 45 },
  { columnKey: "actions", label: "", width: 30 },
  //   { columnKey: "lastModified", label: "Last Modified" },
];

export type ChartTableRow = VizDefIdentifier;

function Visualizations() {
  const navigate = useNavigate();

  const { plotType, tab, setPlotType, setTab, hydrateContext } = useChartManager();

  useEffect(() => {
    if (plotType && tab) {
      navigate("/viz/edit");
    }
  }, [plotType, tab]);

  async function hydrateSetup(item: VizDefIdentifier) {
    const def: VizDef = await PlotService.getVizDef(item.bindId);
    hydrateContext(def, item.formula);
    setPlotType(item.plotType);
    setTab(def.common.typedCode ? "code" : "setup");
  }

  async function hydrateSetupNoOutput(item: VizDefIdentifier) {
    const def: VizDef = await PlotService.getVizDef(item.bindId);
    def.common.outputCell = {};
    hydrateContext(def, item.formula);
    setPlotType(item.plotType);
    setTab(def.common.typedCode ? "code" : "setup");
  }

  const [vizDefinitions, setVizDefinitions] = useState<VizDefIdentifier[]>([]);
  async function loadVizDefinitions() {
    const defs: VizDefIdentifier[] = await PlotService.listVizDefs();
    // PlotService.listVizDefs returns a new object reference every time
    setVizDefinitions(defs);
  }

  useEffect(() => {
    (async () => {
      await loadVizDefinitions();
    })();
  }, []);

  const [error, setError] = useState<string>("");

  async function movePromptCallback(result: Office.AsyncResult<Office.Binding>) {
    if (result.value === undefined) return;

    const item: VizDefIdentifier = result.asyncContext;

    await runExcelSafeContext(async (context) => {
      const currSheet = context.workbook.worksheets.getItem(item.sheetName);
      const currRange = currSheet.getRange(item.addr);
      const binding = context.workbook.bindings.getItem(result.value.id);
      const newRange = binding.getRange();
      currRange.moveTo(newRange);
      binding.delete(); // no need to keep the binding from the prompt
      await context.sync();
    });
    // Refresh vizdef table
    await loadVizDefinitions();
  }

  async function moveItem(item: VizDefIdentifier) {
    const options = { asyncContext: item, promptText: "Select new location for chart" };
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, movePromptCallback);
  }

  async function deleteItem(item: VizDefIdentifier) {
    try {
      await PlotService.deleteVizDef(item.bindId);
      await loadVizDefinitions();
    } catch (e) {
      console.error(e);
      setError("An error occurred when deleting your visualization. Please try again.");
    }
  }

  function getCellContent(item: VizDefIdentifier, key: string) {
    const text = item[key];
    if (key === "plotType") {
      return (
        <>
          <img
            style={{ height: 20, width: 20, verticalAlign: "bottom", marginRight: 5 }}
            src={plotsLookup[text].imgIcon}
          />
          <Text>{plotsLookup[text].name}</Text>
        </>
      );
    } else {
      return text;
    }
  }

  return (
    <>
      {!plotType && (
        <div>
          <Row justifyContent="flex-end">
            <Button
              style={{ color: styles.baseText, backgroundColor: styles.primary }}
              appearance="transparent"
              className="secondary"
              icon={<Add16Filled />}
              iconPosition="before"
              onClick={() => navigate("/viz/create")}
              data-testid="new-chart"
            >
              New Chart
            </Button>
          </Row>
          <div style={{ marginTop: 15 }}>
            <Field validationMessage={error} />
            <Table aria-label="Data table" style={{ borderBottom: `1px solid ${styles.gray200}` }}>
              <TableHeader>
                <TableRow style={{ padding: "8px" }}>
                  {COLUMNS.map((column) => {
                    return (
                      <TableHeaderCell
                        key={column.columnKey}
                        style={{
                          fontWeight: "bold",
                          color: styles.gray800,
                          width: column.width,
                          padding: "8px 4px",
                        }}
                      >
                        {column.label}
                      </TableHeaderCell>
                    );
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {vizDefinitions?.length === 0 ? (
                  <TableRow style={{ padding: "8px" }}>
                    <TableCell>No results</TableCell>
                    {new Array(COLUMNS.length - 1).fill(0).map((value, index) => (
                      <TableCell key={`${value}-${index}`}></TableCell>
                    ))}
                  </TableRow>
                ) : (
                  vizDefinitions.map((item) => (
                    <TableRow key={item.bindId} style={{ border: 0, padding: "8px" }}>
                      {COLUMNS.map((column) => {
                        if (column.columnKey !== "actions" && item[column.columnKey]) {
                          return (
                            <TableCell
                              key={column.columnKey}
                              style={{
                                height: 33,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                padding: "8px 4px",
                              }}
                              onClick={() => hydrateSetup(item)}
                            >
                              {getCellContent(item, column.columnKey)}
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell>
                        <PopoverMenuButton
                          kind="definition"
                          id={item.bindId}
                          actions={[
                            {
                              actionName: "Clone",
                              onClickAction: async () => await hydrateSetupNoOutput(item),
                              icon: <CopyRegular />,
                            },
                            {
                              actionName: "Move",
                              onClickAction: async () => await moveItem(item),
                              icon: <ArrowMoveRegular />,
                            },
                            {
                              actionName: "Delete",
                              onClickAction: async () => await deleteItem(item),
                              icon: <DeleteRegular />,
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
}

export default Visualizations;
