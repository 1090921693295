import { Icons, getPreviousChatName, showBackButton, useStore } from "@anaconda/assistant-components";
import React from "react";

export function BackButton() {
  const [shouldShowBackButton, previousChatName, gotoPreviousChat] = useStore((state) => [
    showBackButton(state),
    getPreviousChatName(state),
    state.gotoPreviousChat,
  ]);

  // Don't show the back button unless necessary (see showBackButton in state-fns.ts)
  if (!shouldShowBackButton) {
    return null;
  }

  return (
    <>
      <div className="as-back-button-container">
        {/* <Tooltip title="Resume previous chat"> */}
        <div className="as-back-button-icon" onClick={gotoPreviousChat}>
          <Icons.ChevronLeft />
        </div>
        {/* </Tooltip> */}
        <div className="as-back-button-title-container">
          <div className="as-back-button-title">Resume previous chat: {previousChatName}</div>
        </div>
      </div>
    </>
  );
}
