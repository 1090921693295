import * as React from "react";
import TableChooser from "../../../excel/components/TableChooser";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { ChatRequestOptions, ChatStarter } from "@anaconda/assistant-components";
import { getTypedContentFromSelection } from "../../../data/data-api-upload";

type TableRangeChooserPageProps = {
  rangeState: RangeSelection;
  setRangeState: (newSelection: RangeSelection) => void;
  chatStarter: ChatStarter;
  makeChatRequest: (options: ChatRequestOptions) => void;
  activeChatKey?: string;
  closeTableChooser: () => void;
};

export function TableRangeChooserPage(props: TableRangeChooserPageProps) {
  const { rangeState, setRangeState, chatStarter, makeChatRequest, closeTableChooser, activeChatKey } = props;
  /**
   * Callback for when a table is chosen.
   */
  React.useEffect(() => {
    if (activeChatKey) {
      closeTableChooser();
    }
  }, [activeChatKey]);

  async function tableChosen(selection: RangeSelection) {
    setRangeState(selection);
    const data = await getTypedContentFromSelection(selection, 5);

    // Let's imitate the format of df.head() on Python.
    // One example of how it looks is as follows:
    // \n   Date    Max Temp    n_rides\n0  2015-05-31    83.0    469\n1  2015-06-01    56.0    113\n2  2015-06-02    63.0    139\n3  2015-06-03    71.0    131\n4  2015-06-04    78.0    207\n
    const head_str = `\n${data.map((row) => row.join("\t")).join("\n")}\n`;

    makeChatRequest({
      context: {
        type: chatStarter.contextType,
        variables: { name: "_df", head_str, range_selection: JSON.stringify(selection) },
      } as any,
      instruction: chatStarter.label.replace("a table or range", selection.displayName),
    });
  }

  return (
    <div style={{ margin: 16 }}>
      <h3>{chatStarter.label}</h3>
      <p>Choose input:</p>
      <TableChooser selection={rangeState} onSelection={tableChosen} />
    </div>
  );
}
