import { QueryObserverOptions, UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import {
  Project,
  ProjectFile,
  ProjectFilesResponse,
  ProjectPermission,
  ProjectPermissionResponse,
  ProjectsResponse,
  SupportedTags,
} from "../../data/projects/models";
import { authenticatedRequest } from "./request";
import { queryClient } from "../../taskpane";

const STALE_TIME_MINUTES = 3;
const STALE_TIME_MS = STALE_TIME_MINUTES * 60 * 1000;

// TODO: Replacement of ProjectsService
export const useProjects = (options: {
  tag: SupportedTags;
  enabled?: QueryObserverOptions["enabled"];
  filter?: (project: Project) => Project;
}) => {
  const projectsQuery = useQuery<ProjectsResponse>({
    queryKey: ["projects"],
    queryFn: () => authenticatedRequest({ path: "" }),
    staleTime: STALE_TIME_MS,
    enabled: options.enabled ?? true, // Enable / disable automatic refetching when the query mounts or changes query keys
  });
  return {
    ...projectsQuery,
    // Optimistically re-map response "items" key to Project type
    data: projectsQuery?.data?.items.filter((project) => {
      // Don't show soft-deleted projects
      if (project.metadata.hidden) return false;
      // Only show projects with the right tag
      if (!project.metadata.tags.includes(options.tag)) return false;
      // Apply extra filter (if provided)
      if (options.filter) return options.filter(project);
      // If passed all checks above, include it
      return true;
    }) as Project[],
  };
};

// TODO: Invalidated files query in projects-api, but should be migrated to react-query:
// updateProjectMetadata
// uploadFile
// downloadFileByName
// deleteFileByName
export const useProjectFiles = (projectId: string, options?: { enabled?: QueryObserverOptions["enabled"] }) => {
  const filesQuery = useQuery<ProjectFilesResponse>({
    queryKey: [`files-${projectId}`],
    queryFn: () => authenticatedRequest({ path: `/${projectId}/files` }),
    staleTime: STALE_TIME_MS,
    enabled: options?.enabled ?? true, // Enable / disable automatic refetching when the query mounts or changes query keys
  });

  return {
    ...filesQuery,
    // Optimistically re-map response "items" key to ProjectFile type
    data: filesQuery?.data?.items as ProjectFile[],
  };
};

export const useProjectPermissions = (projectId: string, options?: { enabled?: QueryObserverOptions["enabled"] }) => {
  const permissionsQuery = useQuery<ProjectPermissionResponse>({
    queryKey: [`permissions-${projectId}`],
    queryFn: () => authenticatedRequest({ path: `/${projectId}/permissions` }),
    staleTime: STALE_TIME_MS,
    enabled: options?.enabled ?? true, // Enable / disable automatic refetching when the query mounts or changes query keys
    retry: false, // Don't retry if error until stale (errors will retry indefinitely if set to true)
  });

  return {
    ...permissionsQuery,
    // Optimistically re-map response "items" key to ProjectFile type
    data: permissionsQuery?.data?.items as ProjectPermission[],
  };
};

// Example mutation not currently in use:
type FileIdentifier = {
  projectId: string;
  fileName: string;
  csvString: string;
};

export const useUploadFile = ({ projectId, fileName, csvString }: FileIdentifier) => {
  const mutation: UseMutationResult = useMutation<Response>({
    mutationKey: ["uploadFile"],
    mutationFn: () => {
      let formData = new FormData();
      formData.append("files", new Blob([csvString]), fileName);
      return authenticatedRequest({ path: `/${projectId}/files/${encodeURIComponent(fileName)}?redirect=False` });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`files-${projectId}`] });
    },
  });
  return mutation;
};
