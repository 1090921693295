import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Body1Stronger,
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  Text,
} from "@fluentui/react-components";
import { Add16Regular, ChevronRight16Regular } from "@fluentui/react-icons";
import { Col, Row } from "../../components/Layout/Space";
import { useAddSettingToDatabase } from "../../../queryclient/settings/settings";
import { Icon } from "@fluentui/react/lib/Icon";
import { SupportedTags } from "../../../data/projects/models";
import { tagToRoutePrefix, tagToUserSettings } from "../../../data/projects/utils";

// Note: In react development strict mode, this will automatically navigate to connections
export const GettingStartedDialog = ({ tag, open }: { tag: SupportedTags; open: boolean }) => {
  const navigate = useNavigate();
  const [hideLanding, setHideLanding] = useState<CheckboxProps["checked"]>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(null);
  const mutation = useAddSettingToDatabase(tagToUserSettings(tag).LandingSeen);

  return (
    <Dialog open={openDialog ?? open} modalType="non-modal">
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Icon
              iconName="cancel"
              style={{ fontSize: 16, position: "absolute", top: 10, right: 10, cursor: "pointer" }}
              onClick={() => {
                setOpenDialog(false);
                mutation.mutate(hideLanding);
              }}
            />
            <div className="tab-content">
              <Col gap={10}>
                <Body1Stronger data-testid="gs-text1" className="center" style={{ fontSize: 22 }}>
                  {tag === "data" && "Access your data anywhere"}
                  {tag === "code_snippet" && "Access your code anywhere"}
                </Body1Stronger>
                <Text data-testid="gs-text2" className="center" style={{ fontSize: 16, margin: "10px 0px 10px 0px" }}>
                  {tag === "data" && "Share, analyze, and visualize your data using Anaconda Toolbox."}
                  {tag === "code_snippet" && "Share and reuse code snippets using Anaconda Toolbox."}
                </Text>
                <Checkbox
                  style={{ right: 8, marginBottom: 10 }}
                  checked={hideLanding}
                  onChange={(_, data) => {
                    setHideLanding(data.checked);
                  }}
                  label={
                    <Row alignItems="center">
                      <span style={{ fontWeight: 400, position: "relative", right: 7 }}>
                        {"Don't show this message again"}
                      </span>
                    </Row>
                  }
                />
                <Button
                  size="medium"
                  appearance="primary"
                  icon={<Add16Regular />}
                  iconPosition="before"
                  data-testid="get-started"
                  onClick={() => navigate(`${tagToRoutePrefix(tag)}/new-project`)}
                >
                  Create a Project
                </Button>
                <Button
                  size="medium"
                  appearance="outline"
                  icon={<ChevronRight16Regular />}
                  iconPosition="after"
                  data-testid="learn-more"
                  onClick={() =>
                    window.open(`https://docs.anaconda.com/excel/toolbox${tagToRoutePrefix(tag)}`, "_blank")?.focus()
                  }
                >
                  Learn More
                </Button>
              </Col>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default GettingStartedDialog;
