import { SettingsPage, getActiveChat, useStore } from "@anaconda/assistant-components";
import React from "react";

export function SettingsWrapper() {
  const [account, enableDataCollection, setEnableDataCollection] = useStore((state) => [
    state.account,
    state.settings.enableDataCollection,
    state.setEnableDataCollection,
    getActiveChat(state),
  ]);

  const isStarterTier = Boolean(account?.metadata?.is_starter_tier);
  const isProTier = Boolean(account?.metadata?.is_pro_tier);
  const addCodeToNotebook = "below-active-cell";

  return (
    <SettingsPage
      enableDataCollection={enableDataCollection}
      setEnableDataCollection={setEnableDataCollection}
      addCodeToNotebook={addCodeToNotebook}
      setAddCodeToNotebook={() => {}}
      isProTier={isProTier}
      isStarterTier={isStarterTier}
    />
  );
}
