import React, { useEffect, useState, createContext, useContext, ReactNode } from "react";
import { isPythonEnabled } from "xlcommon/src/excel/excel-grid-utils";
import Env from "xlcommon/src/environ";

const DEBUG = false;

export type PythonContextType = {
  hasPython: boolean;
};

export const PythonContext = createContext<PythonContextType>({
  hasPython: false,
});

export const usePython = () => {
  const context = useContext<PythonContextType>(PythonContext);
  return context;
};

export const PythonContextProvider = ({ children }: { children: ReactNode }) => {
  const [hasPython, setHasPython] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const pythonEnabled = (await isPythonEnabled()) || Env.UNLOCKED;
      setHasPython(pythonEnabled);
      if (DEBUG) console.log(`hasPython=${pythonEnabled}`);
    })();
  }, []);

  return (
    <PythonContext.Provider
      value={{
        hasPython,
      }}
    >
      {children}
    </PythonContext.Provider>
  );
};
