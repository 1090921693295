import React from "react";
import {
  FluentIcon,
  Home16Filled,
  Home24Filled,
  Cloud16Filled,
  TableFilled,
  CodeBlock24Filled,
  DataBarVerticalAscending16Filled,
  Person16Filled,
  Person24Filled,
  Settings16Filled,
  Settings24Filled,
  SignOut24Filled,
} from "@fluentui/react-icons";
import { Sparkles } from "../Icon";
import styles from "../../styles.module.scss";
import "./NavMenu.scss";

export type NavMenuItem = {
  title: string;
  Icon: FluentIcon;
  path: string;
  disabled?: boolean;
  requiresPython?: boolean; // TODO: remove this as Anaconda Code is always available
  newSection?: boolean;
  priority?: boolean;
};

/****************************
 * Nav Menu (vertical dropdown)
 ****************************/
export const NavMenuItems: NavMenuItem[] = [
  {
    title: "Home",
    Icon: Home24Filled,
    path: "/",
  },

  {
    title: "Visualizations",
    Icon: (props) => <DataBarVerticalAscending16Filled fontSize={20} {...props} />,
    path: "/viz",
    priority: true,
  },
  {
    title: "Assistant",
    Icon: (props) => <Sparkles fill={styles.gray500} {...props} />,
    path: "/ai",
    priority: true,
  },
  {
    title: "Datasets",
    Icon: () => <TableFilled fontSize={20} />,
    path: "/data",
  },
  {
    title: "Code Snippets",
    Icon: CodeBlock24Filled,
    path: "/snippets",
  },
  {
    title: "Settings",
    Icon: Settings24Filled,
    path: "/settings",
    newSection: true,
  },
  {
    title: "Account",
    Icon: Person24Filled,
    path: "/profile",
  },
  {
    title: "Sign out",
    Icon: SignOut24Filled,
    path: "/profile",
  },
];

/****************************
 * Nav Bar (horizontal)
 ****************************/
export type NavBarItem = {
  title: string;
  Icon: FluentIcon;
  path: string;
  disabled?: boolean;
  requiresPython?: boolean;
  priority?: boolean;
};

export const NavBarItems: NavBarItem[] = [
  {
    title: "Home",
    Icon: Home16Filled,
    path: "/",
  },
  {
    title: "Visualizations",
    Icon: DataBarVerticalAscending16Filled,
    path: "/viz",
    priority: true,
  },
  {
    title: "Assistant",
    Icon: (props) => <Sparkles fill={styles.gray500} {...props} />,
    path: "/ai",
    priority: true,
  },
  {
    title: "Datasets",
    Icon: () => <TableFilled fontSize={20} />,
    path: "/data",
  },
  {
    title: "Code Snippets",
    Icon: CodeBlock24Filled,
    path: "/snippets",
  },
  {
    title: "Settings",
    Icon: Settings16Filled,
    path: "/settings",
  },
  {
    title: "Account",
    Icon: Person16Filled,
    path: "/profile",
  },
];

/****************************
 * Start Page Menu
 ****************************/

export type StartMenuHeading = {
  title: string;
  Icon: FluentIcon;
  items: StartMenuItem[];
};

export type StartMenuItem = {
  text: string;
  path: string;
  disabled?: boolean;
  requiresPython?: boolean;
};

export const StartMenuItems: StartMenuHeading[] = [
  {
    title: "Visualizations",
    Icon: DataBarVerticalAscending16Filled,
    items: [
      {
        text: "Visualize with Python",
        path: "/viz",
      },
    ],
  },
  {
    title: "Anaconda Assistant",
    Icon: (props) => <Sparkles {...props} />,
    items: [
      {
        text: "AI Chat-based Python Help",
        path: "/ai",
      },
    ],
  },
  {
    title: "Anaconda Cloud",
    Icon: Cloud16Filled,
    items: [
      {
        text: "Datasets",
        path: "/data",
      },
      {
        text: "Code Snippets",
        path: "/snippets",
      },
    ],
  },
];
