import React, { useReducer, useState, ReactNode, useContext, createContext } from "react";
import {
  BaseDesign,
  BaseSetup,
  BaseCommon,
  BasePlotContext,
  BaseChart,
  BaseChartManagerContext,
  VizTabType,
} from "./PlotTypes";
import { PlotType } from "../../../data/plot-types";
import { CodeBuilder } from "../../pages/Visualization/MVCShared/CodeBuilder";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import { barSetup, barDesign } from "./useBar";
import { boxDesign, boxSetup } from "./useBox";
import { countDesign, countSetup } from "./useCount";
import { lineDesign, lineSetup } from "./useLine";
import { regplotDesign, regplotSetup } from "./useRegression";
import { scatterDesign, scatterSetup } from "./useScatter";
import { violinDesign, violinSetup } from "./useViolin";
import { pairwiseDesign, pairwiseSetup } from "./usePair";
import { displotDesign, displotSetup } from "./useDisplot";
import PlotService, { VizDef } from "../../../data/plot-api";

const DEBUG = false;

interface ChartContextType<T> {
  state: ChartState extends T ? T : never;
  dispatch: React.Dispatch<any>;
  plotType: PlotType;
  tab: VizTabType;
  defModified: boolean;
  setPlotType: (plotType: PlotType) => void;
  setTab: (tab: VizTabType) => void;
  setDefModified: (state: boolean) => void;
}

interface Action {
  type: "updateSetup" | "updateDesign" | "updateCommon" | "updateCommonWithReset" | "resetContext" | "setCodeFragments";
  chartType: PlotType;
  updates?: Partial<BaseSetup | BaseDesign | BaseCommon | CodeBuilder>;
  resetValue?: string;
}

interface ChartState extends BaseChart<BaseSetup, BaseDesign> {}

type InitialChartState = {
  [key in PlotType]: ChartState;
};

const initialChart: InitialChartState = {
  [PlotType.bar]: {
    setup: barSetup,
    design: barDesign,
    common: { plotType: PlotType.bar, hasHeaders: true },
  },
  [PlotType.box]: {
    setup: boxSetup,
    design: boxDesign,
    common: { plotType: PlotType.box, hasHeaders: true },
  },
  [PlotType.count]: {
    setup: countSetup,
    design: countDesign,
    common: { plotType: PlotType.count, hasHeaders: true },
  },
  [PlotType.line]: {
    setup: lineSetup,
    design: lineDesign,
    common: { plotType: PlotType.line, hasHeaders: true },
  },
  [PlotType.regression]: {
    setup: regplotSetup,
    design: regplotDesign,
    common: { plotType: PlotType.regression, hasHeaders: true },
  },
  [PlotType.scatter]: {
    setup: scatterSetup,
    design: scatterDesign,
    common: { plotType: PlotType.scatter, hasHeaders: true },
  },
  [PlotType.violin]: {
    setup: violinSetup,
    design: violinDesign,
    common: { plotType: PlotType.violin, hasHeaders: true },
  },
  [PlotType.pairwise]: {
    setup: pairwiseSetup,
    design: pairwiseDesign,
    common: { plotType: PlotType.pairwise, hasHeaders: true },
  },
  [PlotType.distribution]: {
    setup: displotSetup,
    design: displotDesign,
    common: { plotType: PlotType.distribution, hasHeaders: true },
  },
};

function handleDeselectionInUpdates(updates: Record<string, any>, initial: Record<string, any>, resetValue: string) {
  Object.keys(updates).forEach((key: string) => {
    if (updates[key] === resetValue) {
      if (initial[key] instanceof ValueTracker) {
        updates[key] = initial[key].reset();
      } else {
        updates[key] = initial[key];
      }
    }
  });
}

function rebuildFromUpdates<T>(base: T, updates: Record<string, any>): T {
  return {
    ...base,
    ...Object.fromEntries(
      Object.keys(updates).map((k) => {
        let v = updates[k];
        // Check for incorrect passing of existing base structure into rebuildFromUpdates.
        // Doing so will include ValueTracker objects as update values, which should never happen.
        // All updates `v` should be normal values.
        if (v instanceof ValueTracker) throw Error(`Attempting to update ${k} with ValueTracker`);
        // If `k` is already a ValueTracker within `base`, update it with the new `v`
        if (base[k] instanceof ValueTracker) {
          v = base[k].update(v);
        }
        return [k, v];
      })
    ),
  };
}

function chartReducer(chartObj: InitialChartState, action: Action) {
  const { resetValue, updates, chartType } = action;
  let state = chartType ? chartObj[chartType] : {};
  switch (action.type) {
    case "updateSetup":
      if (resetValue !== null) handleDeselectionInUpdates(updates, initialChart[chartType].setup, resetValue);
      return {
        ...chartObj,
        [chartType]: {
          ...state,
          setup: rebuildFromUpdates(state["setup"], updates),
        },
      };
    case "updateDesign":
      if (resetValue !== null) handleDeselectionInUpdates(updates, initialChart[chartType].design, resetValue);
      return {
        ...chartObj,
        [chartType]: {
          ...state,
          design: rebuildFromUpdates(state["design"], updates),
        },
      };
    case "updateCommon":
      return {
        ...chartObj,
        [chartType]: {
          ...state,
          common: rebuildFromUpdates(state["common"], updates),
        },
      };
    case "updateCommonWithReset":
      return {
        ...chartObj,
        [chartType]: {
          setup: { ...initialChart[chartType].setup },
          design: { ...initialChart[chartType].design },
          common: rebuildFromUpdates(state["common"], updates),
        },
      };
    case "resetContext":
      return Object.keys(initialChart).reduce((acc, key) => {
        acc[key as PlotType] = {
          setup: initialChart[key].setup,
          design: initialChart[key].design,
          common: { plotType: key as PlotType, hasHeaders: true },
        };
        return acc;
      }, {} as InitialChartState);
    case "setCodeFragments":
      return {
        ...chartObj,
        [chartType]: {
          ...state,
          codeFragments: updates,
        },
      };

    default:
      return chartObj;
  }
}

const ChartContext = createContext<ChartContextType<any>>({} as ChartContextType<any>);

const useChartContext = () => {
  const context = useContext(ChartContext);
  return context;
};

interface ChartProviderProps {
  children: ReactNode;
}

export function useChart<T, K>(chartType: PlotType): BasePlotContext<T, K> {
  const { state, dispatch, setDefModified } = useChartContext();
  const stateObject = state[chartType] || {};

  function updateSetup(updates: BaseSetup, resetValue: string = null) {
    dispatch({ type: "updateSetup", chartType, updates, resetValue });
    if (updates && Object.keys(updates).length === 1 && updates.headers !== undefined) {
      // Updating headers doesn't count as a modification
    } else {
      setDefModified(true);
    }
    if (DEBUG) console.log(`updateSetup with updates=${JSON.stringify(updates)}`);
  }
  function updateDesign(updates: BaseDesign, resetValue: string = null) {
    dispatch({ type: "updateDesign", chartType, updates, resetValue });
    setDefModified(true);
    if (DEBUG) console.log(`updateDesign with updates=${JSON.stringify(updates)}`);
  }
  function updateCommon(updates: BaseCommon) {
    // Updating the input data forces a full reset of setup and design
    dispatch({ type: updates.inputData ? "updateCommonWithReset" : "updateCommon", chartType, updates });
    setDefModified(true);
    if (DEBUG) console.log(`updateCommon with updates=${JSON.stringify(updates)}`);
  }
  function setCodeFragments(cb: CodeBuilder) {
    dispatch({ type: "setCodeFragments", chartType, updates: cb });
  }
  async function serializeContext() {
    await PlotService.saveVizDef(stateObject);
  }

  return {
    setup: stateObject["setup"],
    design: stateObject["design"],
    common: stateObject["common"],
    codeFragments: stateObject["codeFragments"],
    updateSetup,
    updateDesign,
    updateCommon,
    setCodeFragments,
    serializeContext,
  };
}

export function useChartManager(): BaseChartManagerContext {
  const { dispatch, plotType, tab, defModified, setPlotType, setTab, setDefModified } = useChartContext();

  function hydrateContext(vizDef: VizDef, formula: string) {
    const chartType = vizDef.common.plotType;
    dispatch({ type: "resetContext", chartType });

    if (formula !== vizDef.writtenFormula) {
      // If grid formula has been modified, treat it like typedCode
      vizDef.common.typedCode = formula;
    }

    dispatch({ type: "updateSetup", chartType, updates: vizDef.setup, resetValue: null });
    dispatch({ type: "updateDesign", chartType, updates: vizDef.design, resetValue: null });
    dispatch({ type: "updateCommon", chartType, updates: vizDef.common, resetValue: null });
    setDefModified(false);
    if (DEBUG) console.log(`hydrateContext ${JSON.stringify(vizDef)}`);
  }
  function resetContext() {
    dispatch({ type: "resetContext" });
    setDefModified(false);
    if (DEBUG) console.log(`resetContext`);
  }
  return {
    plotType,
    tab,
    defModified,
    setPlotType,
    setTab,
    setDefModified,
    resetContext,
    hydrateContext,
  };
}

const ChartProvider = ({ children }: ChartProviderProps) => {
  const [state, dispatch] = useReducer(chartReducer, initialChart);
  const [plotType, setPlotType] = useState<PlotType>();
  const [tab, setTab] = useState<VizTabType>();
  const [defModified, setDefModified] = useState<boolean>(false);
  return (
    <ChartContext.Provider
      value={{
        state,
        dispatch,
        plotType,
        tab,
        defModified,
        setPlotType,
        setTab,
        setDefModified,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

export default ChartProvider;
