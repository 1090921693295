import React from "react";

export const PrivacyPolicy = () => (
  <a
    style={{ position: "absolute", bottom: 10 }}
    className="themed-link"
    href="https://legal.anaconda.com/policies/en/?name=privacy-policy"
    target="_blank"
    rel="noopener noreferrer"
  >
    Privacy Policy
  </a>
);
