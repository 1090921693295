import { EditorView } from "@uiw/react-codemirror";

export const editorStyles = EditorView.theme(
  {
    "&.cm-editor.cm-focused": {
      outline: "none",
    },
  },
  {}
);
