import {
  runExcelSafeContext,
  getFullAddressFromRangeSelection,
  splitFullAddress,
  RangeSelection,
} from "../excel/excel-grid-utils";

export function isPyScriptCloudDataCard(cellValue: Excel.CellValue): boolean {
  if (cellValue.type !== "Entity") return false;
  if (cellValue.text !== "PyScript Data") return false;
  if (!cellValue.properties?.projectId || !cellValue.properties?.fileName || !cellValue.properties?.sqlQuery)
    return false;
  return true;
}

export async function writePyScriptCloudDataCard(
  projectId: string,
  fileName: string,
  query: string,
  sourceSelection: RangeSelection
) {
  const cardView: Excel.CellValue = {
    type: Excel.CellValueType.entity,
    text: "PyScript Data",
    properties: {
      projectId: { type: Excel.CellValueType.string, basicValue: projectId },
      fileName: { type: Excel.CellValueType.string, basicValue: fileName },
      sqlQuery: { type: Excel.CellValueType.string, basicValue: query },
    },
    layouts: {
      compact: { icon: Excel.EntityCompactLayoutIcons.cloud },
      card: {
        title: "PyScript Data",
        sections: [{ layout: "List", properties: ["projectId", "fileName", "sqlQuery"] }],
      },
    },
    provider: {
      description: "Powered by PyScript",
      logoSourceAddress: "https://xltb.anaconda.cloud/pyscript/static/assets/pyscript-logo-32.png",
      logoTargetAddress: "https://pyscript.com",
    },
  };

  const fullAddress = await getFullAddressFromRangeSelection(sourceSelection);
  const [worksheetId, addr] = splitFullAddress(fullAddress);

  await runExcelSafeContext(async (context) => {
    const sheet = context.workbook.worksheets.getItemOrNullObject(worksheetId);
    const rng = sheet.getRange(addr);
    rng.valuesAsJson = [[cardView]];
    await context.sync();
  });

  // Clear out cloud data cache
  // TODO: communicate with PyScript to delete cloud data
  // try {
  //   const pyworker = await getPyWorker();
  //   pyworker.delCloudData(fullAddress);
  // } catch (e) {
  //   console.error(`Unable to get Pyodide -- ${e}`);
  // }
}
