import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Spinner, Text } from "@fluentui/react-components";
import { Col, Row } from "../components/Layout/Space";
import { SignOut24Filled } from "@fluentui/react-icons";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import { InitialCircle } from "../components/Initial";
import { getOrganizations, logout } from "../../auth/auth-api";
import { Organizations, ProductCode, ProductCodeToName } from "../../auth/models";
import { snakeEyesRecord } from "../../analytics/snake-eyes";
import { useAccount } from "../../queryclient/account/account";
import * as AuthAPI from "../../auth/auth-api";
import Env from "xlcommon/src/environ";
import "./Profile.scss";

// type ActiveSubscription = {
//   expires_at: string;
//   product_code: string;
// };

type OrgTiers = {
  organizationName: string;
  subscription: ProductCode;
};

const AppVersion: React.FC = () => (
  <div>
    <h3>App Info</h3>
    <section style={{ margin: 0 }}>
      <Row justifyContent="space-between" alignItems="center">
        <h4>Version</h4>
        <Text>{process.env.REACT_APP_TOOLBOX_VERSION}</Text>
      </Row>
    </section>
  </div>
);

export const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [orgTiers, setOrgTiers] = useState<OrgTiers[]>();
  const [myTier, setMyTier] = useState<string>("Free Tier");

  const [isLoggedIn, setLoggedIn] = useState<boolean>(AuthAPI.isLoggedIn());
  useEffect(() => {
    (async () => {
      if (!Env.signedIn) await AuthAPI.login();
      setLoggedIn(Env.signedIn);
    })();
  }, []);

  const queryClient = useQueryClient();
  const { data: account } = useAccount({ enabled: isLoggedIn });

  useEffect(() => {
    (async () => {
      if (account?.user?.email && isLoggedIn) {
        try {
          const orgs: Organizations = await getOrganizations();
          let orgSubs = [];

          snakeEyesRecord({ event: "profile/view", eventParams: { num_orgs: orgs?.length || 0 } });

          // Get individual subscription (which should not have an organization ID)
          if (account && account?.subscriptions?.length > 0) {
            const mySub = account.subscriptions.find((sub) => sub.org_id === undefined);
            if (mySub) {
              const tier = ProductCodeToName[mySub.product_code];
              setMyTier(tier);
            }
          }

          // Obtain organization name associated with the subscriptions
          if (orgs?.length > 0) {
            orgs.forEach((org) => {
              // `api/account subscriptions` only show organizations with a paid subscription
              const subs = account?.subscriptions?.filter((sub) => org.id === sub.org_id);
              if (subs?.length > 0) {
                orgSubs.push({
                  organizationName: org.title,
                  subscription: subs[0].product_code,
                });
              } else {
                // This is an org with a free subscription
                orgSubs.push({
                  organizationName: org.title,
                  subscription: ProductCode.FreeSubscription,
                });
              }
            });

            setOrgTiers([...orgSubs]);
          }
        } catch (e) {
          // TODO: Send to future monitoring service
          console.log(e);
          if (e.message === "AuthRequiredException") {
            await handleLogout();
          }
        }
        setLoading(false);
      }
    })();
  }, [account?.user?.email]);

  async function handleLogout() {
    // Removing the query is like a cache invalidation without refetch
    await queryClient.removeQueries({ queryKey: ["account"], exact: true });
    await logout();
  }

  async function handleLogoutWithNav() {
    await handleLogout();
    navigate("/");
  }

  if (loading) {
    return (
      <div className="profile">
        <h3>Profile</h3>
        <section>
          <Spinner size="extra-small" />
        </section>
        <AppVersion />
      </div>
    );
  }

  return (
    <Suspense fallback={<Spinner size="extra-small" />}>
      <div className="profile">
        <h3>Profile</h3>
        <>
          <section>
            <Row alignItems="center" gap={8}>
              <InitialCircle
                initial={
                  account?.user?.first_name
                    ? account?.user?.first_name.charAt(0).toUpperCase()
                    : account?.user?.email.charAt(0).toUpperCase()
                }
              />
              <ul>
                <li data-testid="user-name">
                  {account?.user?.first_name} {account?.user?.last_name}
                </li>
                <li data-testid="user-email">{account?.user?.email}</li>
                <li data-testid="user-company">{account?.user?.company}</li>
                <li data-testid="user-industry">{account?.user?.industry}</li>
                <li data-testid="user-country">{account?.user?.country}</li>
              </ul>
            </Row>
          </section>
          <Divider />
          <h3>Subscriptions</h3>
          <section>
            <h4>Individual Subscripton</h4>
            <Row justifyContent="space-between" alignItems="center">
              <div data-testid="individual-tier" style={{ marginLeft: 6 }}>
                {myTier}
              </div>
              {/* TODO: Implement web hooks with upgrade button */}
              {/* <Button className="bright" appearance="primary" size="medium">
                <a href={`${Env.CLOUD_URL}/profile/subscriptions`} target="_blank" rel="noreferrer">
                  Upgrade
                </a>
              </Button> */}
            </Row>
            {orgTiers?.length > 0 && (
              <>
                <h4>Organizational Subscriptons</h4>
                <Col>
                  {orgTiers?.map((orgTier) => (
                    <Row key={orgTier.organizationName} justifyContent="space-between">
                      <div style={{ marginLeft: 6 }}>{orgTier.organizationName}</div>
                      <div data-testid={orgTier.organizationName.split(" ").join("-")}>
                        {ProductCodeToName[orgTier.subscription]}
                      </div>
                    </Row>
                  ))}
                </Col>
              </>
            )}
          </section>
        </>
        <Divider />
        <Col gap={25}>
          <div style={{ marginTop: 12 }}>
            To view and update other settings, please visit {/* TODO: Redirect to be signed in */}
            <a
              data-testid="cloud-link"
              className="themed-link"
              href={`${Env.CLOUD_URL}/profile`}
              target="_blank"
              rel="noreferrer"
            >
              Anaconda Cloud
            </a>
            .
          </div>
          <Button
            data-testid="logout"
            appearance="secondary"
            size="medium"
            icon={<SignOut24Filled />}
            onClick={() => handleLogoutWithNav()}
          >
            Sign out
          </Button>
          <AppVersion />
        </Col>
        <PrivacyPolicy />
      </div>
    </Suspense>
  );
};

export default Profile;
