import React from "react";
import "./PlaceholderItem.scss";

export function PlaceholderItem() {
  return (
    <div className="ph-item">
      <div className="ph-row">
        <div className="ph-col-12"></div>
      </div>
    </div>
  );
}
