import { useStore } from "@anaconda/assistant-components";
import { getChatStarters } from "./ChatStarters";

/**
 * Initializes the assistant by hiding it if there's no active notebook.
 * Also, if the current widget is a notebook and has a path,
 * sets the path in the store if it's not set.
 */
export function initializeAssistant() {
  const state = useStore.getState();
  useStore.getState().updateAccount();
  useStore.getState().setPath("/", { user: state.user || "", dataframes: [] }, getChatStarters);
}
