import React, { CSSProperties, ReactNode } from "react";
import "./Space.scss";

export const Col = ({
  children,
  gap,
  alignItems,
  justifyContent,
  height,
}: {
  children: ReactNode;
  gap?: number;
  alignItems?: string;
  justifyContent?: string;
  height?: string | number;
}) => (
  <div style={{ gap, alignItems, justifyContent, height }} className="col">
    {children}
  </div>
);
export const Row = ({
  children,
  gap,
  alignItems,
  justifyContent,
  flexWrap,
}: {
  children: ReactNode;
  gap?: number;
  alignItems?: string;
  justifyContent?: string;
  flexWrap?: CSSProperties["flexWrap"];
}) => (
  <div className="row" style={{ gap, alignItems, justifyContent, flexWrap }}>
    {children}
  </div>
);

export const ActionButtonRow = ({
  children,
  alignItems,
  justifyContent,
  backgroundColor = "#fff",
}: {
  children: ReactNode;
  alignItems?: string;
  justifyContent?: string;
  backgroundColor?: string;
}) => (
  <footer>
    <div className="row" style={{ padding: 18, backgroundColor, alignItems, justifyContent }}>
      {children}
    </div>
  </footer>
);
