import * as Sentry from "@sentry/react";
import Env from "xlcommon/src/environ";

// Only use in production due to cost limitations
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    environment: Env.BASE_URL,
    /*
     * https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/#dsn-utilization
      "DSNs are safe to keep public because they only allow submission of new events. While there is a risk of abusing a DSN, 
      where any user can send events to your organization with any information they want, this is a rare occurrence. 
      Sentry provides controls to block IPs and similar concerns. 
      You can also rotate (and revoke) DSNs by navigating to [Project] > Settings > Client Keys (DSN).""
    */
    dsn: "https://82adc2a6c035edda262cfa5d2b2d02ac@o4506633492365312.ingest.us.sentry.io/4506871910236160",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    // This is set low due to cost limitations
    tracesSampleRate: 0.02,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", Env.BASE_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
