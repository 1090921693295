import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Label, Input } from "@fluentui/react-components";
import { Dismiss16Regular, FolderSearch16Regular } from "@fluentui/react-icons";
import { Project, ProjectFile, SupportedTags } from "../../../data/projects/models";
import { tagToRoutePrefix } from "../../../data/projects/utils";
import DateModified from "../../components/CustomDatePicker";
import { SearchDropdown } from "../../components/SearchDropdown";
import { Col, Row } from "../../components/Layout/Space";
import "./Filter.scss";
import { useDataFilters } from "../../hooks/useDataFilters";
import { useProjects } from "../../../queryclient/projects/projects";

export function applyProjectFilters(
  fileMap: Record<string, ProjectFile[]>,
  projects: Project[],
  dateModifiedFrom: Date,
  dateModifiedTo: Date,
  projectFilter: string,
  fileFilter: string
): Project[] {
  if (!projects) return [];

  let fltProjects = filterProject(projects, projectFilter);

  // If no file-level filters are defined, skip file-level filtering of projects
  if (!fileMap || (!dateModifiedFrom && !dateModifiedTo && !fileFilter)) return fltProjects;

  const finalProjects: Project[] = [];
  fltProjects.forEach((project) => {
    const files = fileMap[project.id];
    let fltFiles = filterFileName(files, fileFilter);

    fltFiles = filterFilesByDateModified(fltFiles, dateModifiedFrom, dateModifiedTo);
    if (fltFiles.length > 0) {
      finalProjects.push(project);
    }
  });

  return finalProjects;
}

export function applyFileFilters(
  files: ProjectFile[],
  dateModifiedFrom: Date,
  dateModifiedTo: Date,
  fileFilter: string
): ProjectFile[] {
  if (!files) return files;

  let fltFiles = filterFileName(files, fileFilter);

  fltFiles = filterFilesByDateModified(fltFiles, dateModifiedFrom, dateModifiedTo);

  return fltFiles;
}

function filterFilesByDateModified(files: ProjectFile[], dateModifiedFrom: Date, dateModifiedTo: Date): ProjectFile[] {
  if (!files) return [];
  return files.filter((file) => {
    const lastModified = new Date(file.last_modified);
    const from = !dateModifiedFrom || lastModified >= dateModifiedFrom;
    const to = !dateModifiedTo || lastModified <= dateModifiedTo;
    return from && to;
  });
}

function filterProject(projects: Project[], projectId: string): Project[] {
  if (!projectId || projectId === "All") {
    return [...projects];
  } else {
    return projects.filter((project) => {
      return project.id === projectId;
    });
  }
}

function filterFileName(files: ProjectFile[], file: string): ProjectFile[] {
  if (!files) return [];
  let normalizedFilter = file.trim();
  const pattern = new RegExp(normalizedFilter, "gi");

  return files.filter((file) => {
    return file.name.search(pattern) >= 0;
  });
}

const FilterPage = ({ tag }: { tag: SupportedTags }) => {
  const { data: projects = [] } = useProjects({ tag });
  const navigate = useNavigate();
  const {
    dateModifiedFrom,
    dateModifiedTo,
    projectFilter,
    fileFilter,
    setDateFrom,
    setDateTo,
    setFileFilter,
    setProjectFilter,
  } = useDataFilters();

  const clearFilteredItems = () => {
    setDateFrom(null);
    setDateTo(null);
    setProjectFilter("");
    setFileFilter("");
  };

  return (
    <div className="filter-page">
      <Row justifyContent="space-between" alignItems="center">
        <Label style={{ fontSize: 15 }}>Filter</Label>
        <Button appearance="subtle" aria-label="close" onClick={() => navigate(-1)} icon={<Dismiss16Regular />} />
      </Row>
      <div>
        {/* TODO */}
        {/* <FilterTags label="Shared With" sharedWith={[]} /> */}
        <DateModified
          selectedFrom={dateModifiedFrom}
          setSelectedFrom={setDateFrom}
          selectedTo={dateModifiedTo}
          setSelectedTo={setDateTo}
        />
        <Col gap={5}>
          <Label style={{ fontSize: 13, marginTop: 5 }}>Project</Label>
          <SearchDropdown
            options={[
              { name: "All", value: "All" },
              ...projects
                .filter((project) => !project.metadata.hidden)
                .map((project) => ({ name: project.title, value: project.id })),
            ]}
            value={projectFilter}
            placeholder="All"
            style={{ width: 250 }}
            Icon={<FolderSearch16Regular style={{ color: "#000000" }} />}
            transparent={true}
            data-testid="project-filter"
            onChange={(selected: string) => {
              setProjectFilter(selected);
            }}
          />
          <Label style={{ fontSize: 13 }}>File</Label>
          <Input
            value={fileFilter}
            placeholder="Enter File Name..."
            onChange={(e) => setFileFilter(e.target.value)}
            style={{ minWidth: 190, width: 250, maxHeight: 30 }}
          />
        </Col>
      </div>
      <footer style={{ padding: 18 }}>
        <Col>
          <Button
            data-testid="apply-filters"
            appearance="primary"
            style={{ padding: 8 }}
            onClick={() => navigate(tagToRoutePrefix(tag))}
          >
            Apply Filters
          </Button>
          <Button data-testid="reset" appearance="secondary" style={{ padding: 8 }} onClick={clearFilteredItems}>
            Reset
          </Button>
        </Col>
      </footer>
    </div>
  );
};

export default FilterPage;
