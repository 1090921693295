export const EXCEL_BASE = 25569; // Jan 1, 1970 in Excel date representation
export const MILLISECONDS_PER_DAY = 86400000; // 24*60*60*1000

export function convertEpochToExcel(epochMilliseconds: number): number {
  return EXCEL_BASE + epochMilliseconds / MILLISECONDS_PER_DAY;
}

export function convertExcelToEpoch(excelTimestamp: number): number {
  return (excelTimestamp - EXCEL_BASE) * MILLISECONDS_PER_DAY;
}

export function convertDateToExcel(date: Date): number {
  return convertEpochToExcel(date.getTime());
}

export function convertExcelToDate(excelTimestamp: number): Date {
  return new Date(convertExcelToEpoch(excelTimestamp));
}

export function convertExcelToISO(excelTimestamp: number): string {
  return isoNormalize(convertExcelToDate(excelTimestamp));
}

export function isoNormalize(d: Date): string {
  const isoString = d.toISOString();
  if (d.getMilliseconds() > 0) return isoString.slice(0, -1);
  else return `${isoString.slice(0, -5)}`; // remove milliseconds
}

export function updateValueType(valueAsJson: any, valueType: string, category: string, format: string): string {
  // Dates and Times are stored as doubles with formatting that comes from
  // one of three possible formatting categories
  if (valueType !== "Double") return valueType;

  // Check for formatting which usually indicates a datetime
  if (category === "Custom" || category === "Date" || category === "Time") {
    return determineValueType(format, valueType);
  }

  // Check for formatted date
  if (valueAsJson?.type === "FormattedNumber") {
    return determineValueType(valueAsJson.numberFormat, valueType);
  }
  // Check for Python-in-Excel formatted date (ExternalCodeServiceObject with a FormattedNumber hiding as preview)
  if (valueAsJson?.type === "ExternalCodeServiceObject" && valueAsJson.preview?.type === "FormattedNumber") {
    return determineValueType(valueAsJson.preview.numberFormat, valueType);
  }

  return valueType;
}

function determineValueType(fmt: string, fallback: string): string {
  // Determining whether something is a Date, Time, or Datetime vs a Double
  // is way harder than it should be.
  // Excel stores dates as Doubles, and the only way to tell is to look at
  // the numberFormat string, which is... not straightforward at all.
  //
  // Some examples:
  // -------------
  // m/d/yy
  // [$-x-sysdate]dddd, mmmm dd, yyyy
  // yyyy-mm-dd;@
  // m/d;@
  // m/d/yy;@
  // mm/dd/yy;@
  // [$-en-US]d-mmm;@
  // [$-en-US]d-mmm-yy;@
  // [$-en-US]dd-mmm-yy;@
  // [$-en-US]mmm-yy;@
  // [$-en-US]mmmm-yy;@
  // [$-en-US]mmmm d, yyyy;@
  // [$-en-US]m/d/yy h:mm AM/PM;@
  // m/d/yy h:mm;@
  // [$-en-US]mmmmm;@
  // [$-en-US]mmmmm-yy;@
  // m/d/yyyy;@
  // [$-en-US]d-mmm-yyyy;@
  // [$-x-systime]h:mm:ss AM/PM
  // h:mm;@
  // [$-en-US]h:mm AM/PM;@
  // h:mm:ss;@
  // [$-en-US]h:mm:ss AM/PM;@
  // mm:ss.0;@
  // [h]:mm:ss;@
  // [$-en-US]m/d/yy h:mm AM/PM;@
  // m/d/yy h:mm;@
  // yyyy-mm-dd hh:mm:ss
  // [$-en-US]mmmmm-yy;@
  // [$-en-US]m/d/yy h:mm AM/PM;@
  // m/d/yy
  // [$-en-US]h:mm:ss AM/PM
  // d.m.yy;@
  // [$-zh-HK]dddd, d mmmm, yyyy;@

  // Step 1: Ignore the initial [$-county-format] piece
  if (fmt.startsWith("[$-")) {
    fmt = fmt.slice(fmt.indexOf("]") + 1);
  }
  // Step 2: Look for "h" or "m:s" in fmt -- indicates a time
  const isTime = fmt.includes("h") || fmt.includes("m:s");
  // Step 3: Look for "yy" or "d" or "m" which occurs prior to an "h" -- indicates a date
  const isDate =
    fmt.includes("yy") ||
    fmt.includes("d") ||
    (!isTime && fmt.includes("m")) ||
    (isTime && fmt.includes("m") && fmt.indexOf("m") < fmt.indexOf("h"));

  if (isDate && isTime) return "Datetime";
  if (isDate) return "Date";
  if (isTime) return "Time";

  return fallback;
}
