export type UserSettingType = {
  category: string;
  settingName: string;
  value: boolean | string | number;
};

enum DataConnectors {
  LandingSeen = "DataConnectors.LandingSeen",
}

enum CodeSnippets {
  LandingSeen = "CodeSnippets.LandingSeen",
}

enum PyScriptEditor {
  DarkMode = "PyScriptEditor.DarkMode",
}

enum EditCodeWarning {
  Seen = "EditCodeWarning.Seen",
}

export const UserSettings = {
  DataConnectors,
  CodeSnippets,
  PyScriptEditor,
  EditCodeWarning,
};

function splitName(name: string, defaultValue: boolean | string | number): UserSettingType {
  const [cat, sett] = name.split(".");
  return { category: cat, settingName: sett, value: defaultValue };
}

export const UserSettingDefaults: Record<string, UserSettingType> = {
  [DataConnectors.LandingSeen]: splitName(DataConnectors.LandingSeen, false),
  [CodeSnippets.LandingSeen]: splitName(CodeSnippets.LandingSeen, false),
  [PyScriptEditor.DarkMode]: splitName(PyScriptEditor.DarkMode, false),
  [EditCodeWarning.Seen]: splitName(EditCodeWarning.Seen, false),
};
