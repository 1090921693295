import { getGlobal } from "xlcommon/src/global";
import {
  runExcelSafeContext,
  readFromRange,
  getValueTypesOfRange,
  convertDataToTyped,
  getFullAddressFromRangeSelection,
  RangeSelection,
} from "xlcommon/src/excel/excel-grid-utils";
import ProjectsService from "./projects/projects-api";
import { PROJECT_DEFINITIONS } from "./models";

const g = getGlobal();

export async function csvFromSelection(sourceSelection: RangeSelection, fileName: string) {
  // Read data and convert to CSV
  let data = await getTypedContentFromSelection(sourceSelection);
  let csvString = convertToCsv(data);

  // Ensure filename ends in .csv
  let name = fileName;
  if (name.toLowerCase().endsWith(".csv")) {
    name = `${name.slice(0, -4)}.csv`;
  } else {
    name = `${name}.csv`;
  }
  return {
    name,
    csvString,
  };
}

export async function uploadFileFromSelection(
  sourceSelection: RangeSelection,
  projectId: string,
  fileName: string
): Promise<string> {
  // Read data and convert to CSV
  let { name, csvString } = await csvFromSelection(sourceSelection, fileName);
  return await ProjectsService.uploadCSV(projectId, name, csvString);
}

export async function getTypedContentFromSelection(
  sourceSelection: RangeSelection,
  rowLimit: number = -1,
  colLimit: number = -1
): Promise<any[][]> {
  // Read data and convert to strings
  const address = await getFullAddressFromRangeSelection(sourceSelection);
  const data = await readFromRange(address, rowLimit, colLimit);
  const valueTypes = await getValueTypesOfRange(address, colLimit);
  return convertDataToTyped(data, valueTypes, true);
}

function convertToCsv(data: any[][]): string {
  let rowStrings: string[] = data.map((row) => {
    let encoded = row.map((val) => {
      // Handle non-strings with a try/catch
      // This is faster than doing a `typeof` on each value checking for "string"
      let needsQuoting = false;
      try {
        if (val.includes(",") || val.includes('"')) needsQuoting = true;
      } catch {
        val = String(val);
        if (val.includes(",") || val.includes('"')) needsQuoting = true;
      }

      if (needsQuoting) {
        // Values containing a comma or double-quote need to be wrapped in double-quotes
        // Double-quotes are replaced with double-double-quotes
        return `"${val.replace(/"/g, '""')}"`;
      } else {
        return val;
      }
    });
    return encoded.join(",");
  });
  return rowStrings.join("\r\n");
}

/**********************************************************
 * Utilities used for Testing
 **********************************************************/

async function testUtilClearProjectDefinitions() {
  return await runExcelSafeContext(async (context) => {
    const settings = context.workbook.settings;
    const savedSources = settings.getItemOrNullObject(PROJECT_DEFINITIONS);
    await context.sync();

    if (!savedSources.isNullObject) {
      savedSources.delete();
      await context.sync();
    }
  });
}
g.testUtilClearProjectDefinitions = testUtilClearProjectDefinitions;

async function testUtilDeleteProject(name: string) {
  const projects = await ProjectsService.getMyProjects();
  for (let project of projects.items) {
    if (project.name === name || project.title === name) {
      let resp = await ProjectsService.deleteProjectById(project.id);
      if (resp.status < 300) return "Success";
      else return `Error deleting project ${project.id}`;
    }
  }
  return `No project found with name ${name}`;
}
g.testUtilDeleteProject = testUtilDeleteProject;

// Expose common functions
g.testUtilGetListOfProjects = ProjectsService.getMyProjects;
