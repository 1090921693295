import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ChevronDown20Filled,
  ChevronUp20Filled,
  Chat20Regular,
  PeopleCommunity20Filled,
  Book20Regular,
  Document20Regular,
} from "@fluentui/react-icons";
import {
  Button,
  Popover,
  PopoverProps,
  PopoverSurface,
  PopoverTrigger,
  PositioningImperativeRef,
} from "@fluentui/react-components";
import { Col } from "./Layout/Space";
import { navigate } from "../utils";
import "./FeedbackMenu.scss";

const ALLOWED_PATHS = ["/", "/data", "/snippets", "/viz", "/settings"]; // , "/edublocks"
export const FEEDBACK_URL =
  "https://docs.google.com/forms/d/1-7joCjzG2-MJrM9_9Sj0ffprnj5hfeE4BJXSRRleyhI/viewform?edit_requested=true";
const COM_URL = "https://community.anaconda.cloud/c/python-in-excel";
const DOCS_URL = "https://docs.anaconda.com/excel/toolbox/";

export const FeedbackMenu: React.FC<{ triggerButtonStyles?: CSSProperties }> = ({ triggerButtonStyles }) => {
  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const positioningRef = useRef<PositioningImperativeRef>(null);
  useEffect(() => {
    if (buttonRef.current) {
      positioningRef.current?.setTarget(buttonRef.current);
    }
  }, [buttonRef, positioningRef]);

  const [open, setOpen] = useState(false);
  const handleOpenChange: PopoverProps["onOpenChange"] = (_, data) => setOpen(data.open || false);

  if (!ALLOWED_PATHS.includes(location.pathname)) {
    return <></>;
  }
  return (
    <div className="feedback-menu">
      <Popover open={open} onOpenChange={handleOpenChange} positioning={{ positioningRef }}>
        <PopoverTrigger disableButtonEnhancement>
          <Button
            ref={buttonRef}
            className="fixed-button"
            style={{
              borderRadius: "100%",
              backgroundColor: "#6859f0",
              boxShadow: "#BBB 0px 0px 4px 1px",
              ...triggerButtonStyles,
            }}
            size="small"
            appearance="primary"
            icon={open ? <ChevronDown20Filled /> : <ChevronUp20Filled />}
          />
        </PopoverTrigger>
        <PopoverSurface style={{ boxShadow: "unset", backgroundColor: "transparent", paddingRight: 5 }}>
          <Col gap={6} alignItems="flex-end">
            {/* 
            Comment out until we get the link to the documentation
            <Button className="feedback-button" icon={<Info20Regular />}>
              Documentation
            </Button> 
            */}
            <Button className="feedback-button" icon={<Book20Regular />} onClick={() => navigate(DOCS_URL)}>
              User Documentation
            </Button>
            <Button className="feedback-button" icon={<PeopleCommunity20Filled />} onClick={() => navigate(COM_URL)}>
              Community Forums
            </Button>
            <Button className="feedback-button" icon={<Chat20Regular />} onClick={() => navigate(FEEDBACK_URL)}>
              Report bugs &amp; feedback
            </Button>
            <Button className="feedback-button" icon={<Document20Regular />}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="https://legal.anaconda.com/policies/en/?name=privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Button>
          </Col>
        </PopoverSurface>
      </Popover>
    </div>
  );
};
