import React, { useEffect, useState } from "react";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { snakeEyesRecord } from "../../../analytics/snake-eyes";
import { RangeType } from "xlcommon/src/excel/excel-grid-utils";
import { Row } from "../../components/Layout/Space";
import { useChart, useChartManager } from "../../hooks/plots/useCentralViz";
import { BaseDesign as IBaseDesign, BaseSetup as IBaseSetup } from "../../hooks/plots/PlotTypes";
import styles from "../../styles.module.scss";
import "./Viz.scss";

interface ActionButtonsProps {
  onPrimaryClick: () => Promise<void>;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onPrimaryClick }) => {
  const navigate = useNavigate();
  const { plotType, defModified, setPlotType, setTab, setDefModified, resetContext } = useChartManager();
  const { common, updateCommon } = useChart<IBaseSetup, IBaseDesign>(plotType);
  const [pendingWrite, setPendingWrite] = useState<boolean>(false);

  useEffect(() => {
    if (!plotType) {
      navigate("/viz");
    }
  }, [plotType]);

  useEffect(() => {
    if (!pendingWrite) return;

    if (common.outputCell?.identifier) {
      onPrimaryClick();
      setPendingWrite(false);
      setDefModified(false);
      snakeEyesRecord({
        event: "viz/chart_applied",
      });
    }
  }, [pendingWrite, common.outputCell]);

  function promptCallback(result: Office.AsyncResult<Office.Binding>) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      setPendingWrite(false);
      return;
    }

    updateCommon({ outputCell: { rangeType: RangeType.CellBinding, identifier: result.value.id } });
    snakeEyesRecord({
      event: "viz/chart_created",
    });
  }

  async function handleCreateOrApply() {
    // If outputCell is not defined, force the user to choose it now
    // Unfortunately, this is not a synchronous or awaitable action,
    //   so promptCallback and useEffect are needed to handle the response
    if (!common.outputCell || !common.outputCell.identifier) {
      let options = { promptText: "Select a cell to run the chart code" };
      Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
    }

    setPendingWrite(true);
  }

  async function handleCancel() {
    // Reset chart
    resetContext();
    setTab(null);
    setPlotType(null);
  }

  return (
    <div className="viz-action">
      <Row justifyContent="flex-end">
        <Button
          data-testid="cancel"
          appearance="subtle"
          onClick={handleCancel}
          style={{ color: styles.gray500, padding: 8, width: 140 }}
        >
          Cancel
        </Button>
        <Button
          data-testid="create-or-apply"
          appearance="primary"
          disabled={
            !common.inputData || (common.outputCell?.identifier && !defModified) || common.validationErrors?.size > 0
          }
          iconPosition="after"
          onClick={handleCreateOrApply}
          style={{ padding: 8, width: 140 }}
        >
          {common.outputCell?.identifier ? "Apply" : "Create"}
        </Button>
      </Row>
    </div>
  );
};

export default ActionButtons;
