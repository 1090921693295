import { refreshRangeSelection, runExcelSafeContext } from "xlcommon/src/excel/excel-grid-utils";
import ProjectsService from "./projects/projects-api";
import {
  CloudProjectDefinition,
  CloudProjectDefinitions,
  CloudProjectFileDefinition,
  PROJECT_DEFINITIONS,
  VERSION,
} from "./models";

// Note:
// The file extension must be saved in the data definition, because when we download files and their names, they have extensions on them.
// Failure to do this will result in missing connection arrows due to differing file names stored in the definitions (i.e. `test` versus `test.csv`).
// When uploading, we add on a file extension to the file name, because we only have the data set title and can assume type based on workbook

export async function loadProjectDefinitions(): Promise<CloudProjectDefinitions> {
  return await runExcelSafeContext(async (context) => {
    const settings = context.workbook.settings;
    const savedSources = settings.getItemOrNullObject(PROJECT_DEFINITIONS);
    savedSources.load("value");
    await context.sync();

    if (savedSources.isNullObject) {
      return {
        projects: {} as CloudProjectDefinitions["projects"],
        version: VERSION,
      };
    } else {
      let projectDefinitions = JSON.parse(savedSources.value);
      if (!projectDefinitions.projects || projectDefinitions.version < VERSION) {
        return {
          projects: {} as CloudProjectDefinitions["projects"],
          version: VERSION,
        };
      }

      // Update range selections for Bindings
      for (let i = 0; i < projectDefinitions.projects.length; i++) {
        let project: CloudProjectDefinition = projectDefinitions.projects[i];
        Object.values(project.files).map(
          async (fileDefinition) =>
            (fileDefinition.sourceSelection = await refreshRangeSelection(fileDefinition.sourceSelection))
        );
      }
      return projectDefinitions;
    }
  });
}

export async function updateProjectDefinitions(projectId: string, newDefinition: CloudProjectFileDefinition) {
  const cloudProjectDefs = await loadProjectDefinitions();
  if (cloudProjectDefs.projects[projectId] && cloudProjectDefs.projects[projectId].files) {
    // Replace file definition associated with this project
    cloudProjectDefs.projects[projectId].files[newDefinition.fileName] = { ...newDefinition };
  } else {
    // No project definition exists yet - Add new definition for project and file
    const projectRes = await ProjectsService.getProjectById(projectId);
    const project = await projectRes.json();

    cloudProjectDefs.projects[projectId] = {
      id: projectId,
      title: project.title, // get project title and name
      name: project.name,
      files: {
        [newDefinition.fileName]: { ...newDefinition },
      },
    } as CloudProjectDefinition;
  }

  await saveProjectDefinitions({
    projects: cloudProjectDefs.projects,
    version: VERSION,
  } as CloudProjectDefinitions);
}

export async function saveProjectDefinitions(data: CloudProjectDefinitions) {
  await runExcelSafeContext(async (context) => {
    const settings = context.workbook.settings;
    let serialized = JSON.stringify(data);
    settings.add(PROJECT_DEFINITIONS, serialized);
    await context.sync();
  });
}
