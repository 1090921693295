import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { Chart, Markers } from "../MVCShared/types";
import {
  AxisDropdownAttr,
  CheckBoxAttr,
  CollapsibleAttr,
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  LegendAttr,
  MultiSelectDropdownAttr,
  PaletteAttr,
  SpinnerAttr,
  GridlinesAttr,
  MultiChartBorders,
} from "../MVCShared/PlotAttributes";
import { CodeBuilder, buildCode, buildReactFromAttrs } from "../MVCShared/CodeBuilder";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { DiagonolKws, PlotStyle } from "./PairPlotKws";
import { PairSetup, PairDesign as IPairDesign } from "../../../../taskpane/hooks/plots/usePair";

const PairPlotContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    PairSetup,
    IPairDesign
  >(PlotType.pairwise);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...PairPlotChart.baseAttrs, ...PairPlotChart.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const MarkerAttr = DropdownAttr({
    label: "Marker",
    value: design.markers,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ markers: e.selectedOptions }),
    codeKey: "marker",
    codeRequiresInteraction: true,
    multiselect: true,
    selectedOptons: design.markers,
    dataTestID: "marker",
  });

  MarkerAttr.getCode = (code: CodeBuilder) => {
    if (design.markers.length > 0) {
      const markerCodes = design.markers.map((marker) => `'${Markers[marker]}' `);
      code.plotAttrs.push(`markers=[${markerCodes}]`);
    }
  };

  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const paletteAttr = PaletteAttr({
    value: design.palette,
    onChange: (_, data) => {
      updateDesign({ palette: data.optionText });
    },
    codeKey: "palette",
    placeholder: "Accent",
    codeRequiresInteraction: true,
    dataTestID: "palette",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
  });

  const variablesAttr = MultiSelectDropdownAttr({
    value: setup.vars,
    onChange: (_, data) => {
      updateSetup({ vars: data.selectedOptions });
    },
    dataTestID: "vars",
    label: "Variables",
    options: setup.headers,
    codeKey: "vars",
    multiselect: true,
    selectedOptons: setup.vars,
    hasHeaders: common.hasHeaders,
  });

  const colAttr = MultiSelectDropdownAttr({
    label: "Column",
    value: setup.xVars,
    onChange: (_, data) => {
      updateSetup({ xVars: data.selectedOptions });
    },
    enabledDependencies: [dependencyEqualsValue(variablesAttr, [])],
    dataTestID: "x-var",
    options: setup.headers,
    codeKey: "x_vars",
    multiselect: true,
    selectedOptons: setup.xVars,
    hasHeaders: common.hasHeaders,
  });

  const rowAttr = MultiSelectDropdownAttr({
    label: "Row",
    value: setup.yVars,
    onChange: (_, data) => {
      updateSetup({ yVars: data.selectedOptions });
    },
    enabledDependencies: [dependencyEqualsValue(variablesAttr, [])],
    dataTestID: "y-var",
    options: setup.headers,
    codeKey: "y_vars",
    multiselect: true,
    selectedOptons: setup.yVars,
    hasHeaders: common.hasHeaders,
  });

  const PairPlotChart: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      // Note: Users can plot x & y variables or just pass in all the variables they want plotted, I'm not sure what we want but both are cool to me
      variablesAttr,
      rowAttr,
      colAttr,
      ColorBy,
      CheckBoxAttr({
        label: "Drop NA Values",
        value: setup.dropna,
        onChange: (_, e) => updateSetup({ dropna: e.checked }),
        codeKey: "dropna",
        codeRequiresInteraction: true,
        dataTestID: "dropna",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Chart Configuration", tooltip: "Configure subplots and layout" }),
      DropdownAttr({
        value: setup.kind,
        onChange: (_, data) => {
          updateSetup({ kind: data.optionValue });
        },
        dataTestID: "kind",
        label: "Subplot Type",
        options: ["Scatter", "Histogram", "KDE", "Regression"],
        codeValueMap: { Scatter: "scatter", Histogram: "hist", KDE: "kde", Regression: "reg" },
        codeRequiresInteraction: true,
        codeKey: "kind",
      }),
      DropdownAttr({
        value: setup.diagKind,
        onChange: (_, data) => {
          updateSetup({ diagKind: data.optionValue });
        },
        dataTestID: "diagKind",
        label: "Diagonal Type",
        options: ["Auto", "Hist", "Kde"],
        codeValueMap: { Auto: "auto", Hist: "hist", Kde: "kde" },
        codeKey: "diag_kind",
        codeRequiresInteraction: true,
      }),
    ],
    designAttrs: [
      PlotStyle({
        lineStyle: design.lineStyle,
        lineWidth: design.lineWidth,
        markerSize: design.markerSize,
        codeRequiresInteraction: true,
      }),
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          MultiChartBorders({
            left: design.leftSpine,
            right: design.rightSpine,
            bottom: design.bottomSpine,
            top: design.topSpine,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
            chartType: PlotType.pairwise,
            dataTestID: "spine-checkbox",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            multiChart: true,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Color" }),
      paletteAttr,
      DividerAttr(),
      MarkerAttr,
      SpinnerAttr({
        label: "Marker Size",
        value: design.markerSize,
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        onChange: (data: number) => updateDesign({ markerSize: data }),
        codeRequiresInteraction: true,
        visibleDependencies: [dependencyNotEqualsValue(MarkerAttr, [])],
      }),
      DividerAttr(),
      DropdownAttr({
        label: "Line Style",
        value: design.lineStyle,
        onChange: (_, data) => updateDesign({ lineStyle: data.optionValue }),
        options: ["Solid", "Dashed"],
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Line Width",
        value: design.lineWidth,
        onChange: (data: number) => updateDesign({ lineWidth: data }),
        suffix: "px",
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Scale", tooltip: "Set chart size" }),
      SpinnerAttr({
        label: "Height",
        value: design.height,
        step: 0.1,
        min: 0,
        max: 2 ** 32 - 1,
        suffix: "in",
        onChange: (data: number) => updateDesign({ height: data }),
        codeKey: "height",
        codeRequiresInteraction: true,
        dataTestID: "height",
      }),
      SpinnerAttr({
        label: "Aspect Ratio",
        value: design.aspect,
        step: 0.1,
        min: 0,
        max: 2 ** 32 - 1,
        suffix: "in",
        onChange: (data: number) => updateDesign({ aspect: data }),
        codeKey: "aspect",
        codeRequiresInteraction: true,
        dataTestID: "aspect",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend", tooltip: "Legend only visible for some combinations of inputs" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          DiagonolKws({
            stat: design.stat,
            element: design.element,
            bins: design.bin,
            codeRequiresInteraction: true,
          }),
          DropdownAttr({
            label: "Element",
            options: ["Bars", "Step", "Poly", "None"],
            placeholder: "Bar",
            value: design.element,
            onChange: (_, data) => updateDesign({ element: data.optionValue }),
            codeRequiresInteraction: true,
          }),
          DropdownAttr({
            value: design.stat,
            onChange: (_, data) => updateDesign({ stat: data.optionValue }, "--Select--"),
            label: "Bin Statistic",
            options: ["--Select--", "Count", "Frequency", "Probability", "Percent", "Density"],
          }),
          SpinnerAttr({
            onChange: (data: number) => updateDesign({ bin: data }),
            label: "Bin Size",
            min: 0,
            max: 2 ** 32 - 1,
            step: 1,
            value: design.bin,
            dataTestID: "bin",
          }),
        ],
      }),

      DividerAttr(),
    ],
  };
  return PairPlotChart;
};

const PairForm = () => {
  const pairChart = PairPlotContext();
  return buildReactFromAttrs(pairChart.baseAttrs, 140);
};

export const PairDesign = () => {
  const pairDesign = PairPlotContext();
  return buildReactFromAttrs(pairDesign.designAttrs, 120);
};

export default PairForm;
