import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface BarSetup extends BaseSetup {
  // TODO: specifics
  primaryInterval?: number;
  secondInterval?: number;
  bootstrapSamples?: ValueTracker<number>;
  seed?: ValueTracker<number>;
  estimator?: string;
  errorBar?: string;
  isCollapsed: boolean;
}

export interface BarDesign extends BaseDesign {
  // TODO: specifics
  colorOrder?: string;
  width: ValueTracker<number>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  errorColor: ValueTracker<string>;
  dodge: ValueTracker<boolean>;
  isCollapsed: boolean;
  legendPosition: string;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  alpha: ValueTracker<number>;
}

export const barSetup: BarSetup = {
  orientation: new ValueTracker("Vertical"),
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  xAxisScale: "",
  yAxisScale: "",
  primaryInterval: 95,
  secondInterval: 1,
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  estimator: "Mean",
  errorBar: "No error bars",
  isCollapsed: true,
};

export const barDesign: BarDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker(true),
  color: new ValueTracker(styles.seabornDefault),
  fillStyle: "One Color",
  palette: new ValueTracker("Tab10"),
  colorOrder: "",
  width: new ValueTracker(0.8),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  errorColor: new ValueTracker("#000000"),
  dodge: new ValueTracker(false),
  isCollapsed: true,
  legendPosition: "",
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
  alpha: new ValueTracker(1),
};
