import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface LineSetup extends BaseSetup {
  styleMarkersBy?: string;
  styleLinesBy?: string;
  sizeByLines?: string;
  primaryInterval: number;
  secondInterval: number;
  bootstrapSamples: ValueTracker<number>;
  seed: ValueTracker<number>;
  sort: ValueTracker<boolean>;
  estimator?: string;
  errorBar?: string;
  isCollapsed: boolean;
}

export interface LineDesign extends BaseDesign {
  // TODO: specifics
  marker: string;
  lineStyle: ValueTracker<string>;
  errorStyle: string;
  lineWidth: ValueTracker<number>;
  errorBarColor: ValueTracker<string>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  isDesignCollapsed: boolean;
  legendPosition: string;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

export const lineSetup: LineSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  xAxisScale: "",
  yAxisScale: "",
  estimator: "Mean",
  errorBar: "No error bars",
  primaryInterval: 95,
  secondInterval: 1,
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  sort: new ValueTracker(true),
  isCollapsed: true,
  styleMarkersBy: "",
  styleLinesBy: "",
  sizeByLines: "",
};

export const lineDesign: LineDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fillStyle: "",
  marker: "None",
  palette: new ValueTracker("Tab10"),
  errorStyle: "",
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  errorBarColor: new ValueTracker("#000000"),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  isDesignCollapsed: true,
  legendPosition: "",
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
