export const LD_KEY_ASSISTANT_USE_DEV_API_ENDPOINT = "assistant-use-dev-api-endpoint";

export const ASSISTANT_EXTENSION_NAMESPACE = "aext_assistant_server";

// TODO:
// Make this dynamic by reading it from the backend
export const CLIENT_VERSION = "4.0.3";

// The maximum amount of input tokens to allow sending up. The current model, gpt-3.5-turbo-0613, supports 4096
// and so it looks like we will want to adjust this based on the model in the future. We're limiting to 3/4 in the
// input in order to allow tokens in the response.
export const TOKEN_LIMIT = 4096;

// We can use this to show/hide the settings button
// which will open the settings page when we have configurable settings in the future
export const SHOW_SETTINGS = true;

function getHostName() {
  if (typeof window === "undefined") {
    return "anaconda.cloud";
  }
  return window.location.host.replace(/^nb\./, "");
}

export const ENV = {
  PROD: {
    LEGACY_CLOUD_API_BASE_URL: "https://anaconda.cloud/api",
    CLOUD_API_BASE_URL: `https://${getHostName()}/api`,
    STAGING_CLOUD_API_BASE_URL: "https://nucleus-latest.anacondaconnect.com/api",
  },
};
