import React, { useEffect, useState } from "react";
import { TableAdd20Regular } from "@fluentui/react-icons";
import { WorkbookStructure, readWorkbookStructure, runExcelSafeContext } from "xlcommon/src/excel/excel-grid-utils";
import {
  RangeType,
  RangeSelection,
  refreshRangeSelection,
  displayRangeSelection,
  isBinding,
  isSpillSelection,
  isTableSelection,
} from "xlcommon/src/excel/excel-grid-utils";
import { SearchDropdown } from "../../taskpane/components/SearchDropdown";

interface TableChooserProperties {
  selection: RangeSelection;
  onSelection: (newSelection: RangeSelection) => void; // called when the selection updates
  // Only Tables and Entire Sheets are safe to write to.
  safeForWriting?: boolean; // Setting this to true will restrict selection to only tables and entire sheets.
  placeholder?: string; // text to display if initialValue is blank
  dataTestId?: string;
  size?: "small" | "large";
  width?: string;
}

export default function TableChooser({
  selection,
  onSelection,
  safeForWriting = false,
  placeholder = "Click to select",
  dataTestId,
  size = "large",
  width,
}: TableChooserProperties) {
  const [wbStruct, setWbStruct] = useState<WorkbookStructure>({
    names: [],
    tables: [],
    sheets: [],
    sheetsWithoutTables: [],
  });
  async function getWbStruct() {
    const result = await readWorkbookStructure();
    setWbStruct(result);
  }
  useEffect(() => {
    getWbStruct();
  }, []);

  async function selectionChanged(newSelection: string) {
    // Ignore already selected entry
    if (newSelection === displayRangeSelection(selection)) {
      return;
    }

    if (isBinding(selection)) {
      // Delete old binding
      try {
        await runExcelSafeContext(
          async (context) => {
            let oldBinding = context.workbook.bindings.getItem(selection.identifier);
            oldBinding.delete();
            await context.sync();
          },
          { bindId: selection.identifier }
        );
      } catch (e) {
        console.log(e);
      }
    }

    // Open prompt dialog for "Choose Range" option
    if (newSelection === "Choose Range") {
      Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, promptCallback);
      return;
    }

    // Convert string value into a RangeSelection
    let [kind, name] = newSelection.slice(1).split("] ");
    let rs: RangeSelection = {
      rangeType: kind as RangeType,
      identifier: name,
    };
    // Replace table and sheet names with ids
    switch (rs.rangeType) {
      case RangeType.Table:
        await runExcelSafeContext(async (context) => {
          let t = context.workbook.tables.getItem(name);
          t.load("id");
          await context.sync();
          rs = {
            ...rs,
            identifier: t.id,
            displayName: name,
          };
        });
        break;
      case RangeType.Sheet:
        await runExcelSafeContext(async (context) => {
          let s = context.workbook.worksheets.getItem(name);
          s.load("id");
          await context.sync();
          rs = {
            ...rs,
            identifier: s.id,
            displayName: name,
          };
        });
        break;
    }
    onSelection(rs);
  }

  async function promptCallback(result: Office.AsyncResult<Office.Binding>) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }

    let rs: RangeSelection = {
      rangeType: RangeType.RangeBinding,
      identifier: result.value.id,
    };

    // Check for table or spill binding
    await runExcelSafeContext(
      async (context) => {
        let b = context.workbook.bindings.getItem(result.value.id);
        let rng = b.getRange();
        const tblId = await isTableSelection(context, rng);
        if (tblId !== null) {
          rs = {
            identifier: tblId,
            rangeType: RangeType.Table,
          };
        } else if (await isSpillSelection(context, rng)) {
          rs = {
            ...rs,
            rangeType: RangeType.SpillBinding,
          };
        }
      },
      { bindId: result.value.id }
    );

    onSelection(await refreshRangeSelection(rs));
  }

  if (safeForWriting) {
    return (
      <SearchDropdown
        size={size}
        style={{ width }}
        onClick={getWbStruct}
        Icon={<TableAdd20Regular />}
        placeholder={placeholder}
        value={displayRangeSelection(selection)}
        options={[
          {
            type: "group",
            name: "Tables",
            items: wbStruct.tables.map((tableName) => ({
              key: `[Table] ${tableName}`,
              name: tableName,
              value: `[Table] ${tableName}`,
            })),
          },
          {
            type: "group",
            name: "Entire Sheet",
            items: wbStruct.sheetsWithoutTables.map((sheetName) => ({
              key: `[Sheet] ${sheetName}`,
              name: sheetName,
              value: `[Sheet] ${sheetName}`,
            })),
          },
        ]}
        onChange={(selectedValue: string) => selectionChanged(selectedValue)}
        data-testid={dataTestId}
      />
    );
  } else {
    return (
      <SearchDropdown
        size={size}
        style={{ width }}
        onClick={getWbStruct}
        Icon={<TableAdd20Regular />}
        placeholder={placeholder}
        value={displayRangeSelection(selection)}
        options={[
          {
            type: "group",
            name: "Manual Selection",
            items: isBinding(selection)
              ? [
                  {
                    key: "Bound Range",
                    name: selection.displayName,
                    value: selection.displayName,
                  },
                  {
                    key: "Choose Range",
                    name: "Choose Range",
                    value: "Choose Range",
                  },
                ]
              : [
                  {
                    key: "Choose Range",
                    name: "Choose Range",
                    value: "Choose Range",
                  },
                ],
          },
          {
            type: "group",
            name: "Tables",
            items: wbStruct.tables.map((tableName) => ({
              key: `[Table] ${tableName}`,
              name: tableName,
              value: `[Table] ${tableName}`,
            })),
          },
          {
            type: "group",
            name: "Named Ranges",
            items: wbStruct.names.map((rangeName) => ({
              key: `[Named] ${rangeName}`,
              name: rangeName,
              value: `[Named] ${rangeName}`,
            })),
          },
          {
            type: "group",
            name: "Entire Sheet",
            items: wbStruct.sheetsWithoutTables.map((sheetName) => ({
              key: `[Sheet] ${sheetName}`,
              name: sheetName,
              value: `[Sheet] ${sheetName}`,
            })),
          },
        ]}
        onChange={(selectedValue: string) => selectionChanged(selectedValue)}
        data-testid={dataTestId}
      />
    );
  }
}
