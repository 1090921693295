import React, { useState, createContext, useContext, ReactNode } from "react";

export type FilterContextType = {
  dateModifiedFrom: Date;
  dateModifiedTo: Date;
  projectFilter: string;
  fileFilter: string;
  setDateFrom: (date: Date) => void;
  setDateTo: (date: Date) => void;
  setProjectFilter: (project: string) => void;
  setFileFilter: (file: string) => void;
};

export const FilterContext = createContext<FilterContextType>({
  dateModifiedFrom: null,
  dateModifiedTo: null,
  projectFilter: "",
  fileFilter: "",
  setDateFrom: () => Promise<void>,
  setDateTo: () => Promise<void>,
  setProjectFilter: () => Promise<void>,
  setFileFilter: () => Promise<void>,
});

export const useDataFilters = () => {
  const context = useContext<FilterContextType>(FilterContext);
  return context;
};

export const DataFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [dateModifiedFrom, setDateFrom] = useState<Date>(null);
  const [dateModifiedTo, setDateTo] = useState<Date>(null);
  const [projectFilter, setProjectFilter] = useState<string>("");
  const [fileFilter, setFileFilter] = useState<string>("");

  return (
    <FilterContext.Provider
      value={{
        dateModifiedFrom,
        setDateFrom,
        dateModifiedTo,
        setDateTo,
        projectFilter,
        setProjectFilter,
        fileFilter,
        setFileFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
