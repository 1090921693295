import React from "react";
import { Col } from "../../../taskpane/components/Layout/Space";
import { Checkbox, Text } from "@fluentui/react-components";
import { UserSettings } from "../../../queryclient/settings/types";
import { useAddSettingToDatabase } from "../../../queryclient/settings/settings";

export const LockedLabel = () => {
  return (
    <Col gap={10}>
      <Text style={{ fontSize: 14 }}>
        {"You've made changes to the code for this chart. Click "}
        <b>Edit Code</b>
        {" to continue making changes on the Code tab."}
      </Text>
      <Text style={{ fontSize: 14 }}>
        Clicking <b>Discard Code Edits</b> to use the Setup or Design tabs will remove any changes you made to the code,
        as we cannot reflect all code changes on those tabs.
        <br />
        <a
          className="themed-link secondary"
          href="https://docs.anaconda.com/excel/toolbox/visualize/#locked-setup-and-design-tabs"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>
      </Text>
    </Col>
  );
};

export const WarningLabel = () => {
  const mutation = useAddSettingToDatabase(UserSettings.EditCodeWarning.Seen);

  return (
    <Col gap={0}>
      <Text style={{ fontSize: 14, marginBottom: 0 }}>
        Editing code will lock the Setup and Design tab. All changes will need to be in Code. You may return to using
        Setup and Design at any time, but all custom code will be reverted.
      </Text>
      <a
        className="themed-link secondary"
        href="https://docs.anaconda.com/excel/toolbox/visualize/#locked-setup-and-design-tabs"
        target="_blank"
        rel="noreferrer"
      >
        Learn More
      </a>
      <Checkbox label="Do not show again" style={{ right: 8 }} onChange={(_, e) => mutation.mutate(e.checked)} />
    </Col>
  );
};
