import { Project, SupportedTags } from "./models";
import { Account } from "../../auth/models";
import { UserSettings } from "../../queryclient/settings/types";

function projCompare(a: Project, b: Project): number {
  if (a.owner?.name !== null && b.owner?.name !== null) {
    // Sort based on owner name, then project name
    return a.owner.name < b.owner.name ? -1 : a.owner.name > b.owner.name ? +1 : a.name < b.name ? -1 : +1;
  }
  // Sort only based on project name
  return a.name < b.name ? -1 : +1;
}

export function selectMyProjects(allProjects: Project[], myAccount: Account): Project[] {
  if (!allProjects) return [];
  return allProjects
    .filter((project) => project.owner.id === myAccount?.user?.id && project.metadata.hidden !== true)
    .sort(projCompare);
}

export function selectUserProjects(allProjects: Project[], myAccount: Account): Project[] {
  if (!allProjects) return [];
  return allProjects
    .filter(
      (project) =>
        project.owner.type === "user" && project.owner.id !== myAccount?.user?.id && project.metadata.hidden !== true
    )
    .sort(projCompare);
}

export function selectOrgProjects(allProjects: Project[]): Project[] {
  if (!allProjects) return [];
  return allProjects
    .filter((project) => project.owner.type === "organization" && project.metadata.hidden !== true)
    .sort(projCompare);
}

export function tagToAppName(tag: SupportedTags): string {
  switch (tag) {
    case "data":
      return "data_connectors";
    case "code_snippet":
      return "code_snippets";
    default:
      throw Error(`Unhandled tag: ${tag}`);
  }
}

export function tagToTitle(tag: SupportedTags): string {
  switch (tag) {
    case "data":
      return "Dataset";
    case "code_snippet":
      return "Snippet";
    default:
      throw Error(`Unhandled tag: ${tag}`);
  }
}

export function tagToRoutePrefix(tag: SupportedTags): string {
  switch (tag) {
    case "data":
      return "/data";
    case "code_snippet":
      return "/snippets";
    default:
      throw Error(`Unhandled tag: ${tag}`);
  }
}

export function tagToUserSettings(tag: SupportedTags) {
  switch (tag) {
    case "data":
      return UserSettings.DataConnectors;
    case "code_snippet":
      return UserSettings.CodeSnippets;
    default:
      throw Error(`Unhandled tag: ${tag}`);
  }
}
