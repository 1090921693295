import React, { useCallback, useState } from "react";
import { ColorPicker, getColorFromString, IColor, IColorPickerStyles, mergeStyleSets } from "@fluentui/react";
import { Row } from "../../../components/Layout/Space";
import "../Viz.scss";
import styles from "../../../styles.module.scss";

interface ColorPickerProps {
  onChange: (hexColor, rgbaColor) => void;
  label?: string;
  labelWidth: number;
  defaultColor?: string;
}

export const VizColorPicker: React.FC<ColorPickerProps> = ({ label, labelWidth, onChange, defaultColor }) => {
  const initialColor = defaultColor ? getColorFromString(defaultColor)! : getColorFromString(styles.seabornDefault);
  const [color, setColor] = useState(initialColor);
  const [collapsed, setCollapsed] = useState(true);

  const updateColor = useCallback((_: any, colorObj: IColor) => setColor(colorObj), []);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <Row justifyContent="space-between" alignItems="center">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <div className={classNames.wrapper} style={{ width: "100%" }}>
          <div
            className={classNames.colorBox}
            style={{ backgroundColor: color.str, marginLeft: 6 }}
            onClick={toggleCollapse}
          ></div>
          {!collapsed && (
            <>
              <ColorPicker
                color={color}
                onChange={(ev, updatedColor) => {
                  updateColor(ev, updatedColor);
                  onChange(updatedColor.hex, updatedColor);
                }}
                alphaType="alpha"
                showPreview={true}
                styles={colorPickerStyles}
                strings={{
                  hueAriaLabel: "Hue",
                }}
              />
            </>
          )}
        </div>
      </Row>
    </div>
  );
};

const classNames = mergeStyleSets({
  wrapper: { display: "flex", alignItems: "center" },
  colorBox: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    border: "none",
    transition: "all 0.3s ease-in-out",
  },
});

const colorPickerStyles: Partial<IColorPickerStyles> = {
  panel: { padding: 12 },
  root: {
    maxWidth: 250,
    minWidth: 250,
  },
  colorRectangle: { height: 200 },
};

export default VizColorPicker;
