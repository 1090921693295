import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@fluentui/react-components";
import { Col } from "../components/Layout/Space";
import { FEEDBACK_URL } from "../components/FeedbackMenu";
import styles from "../styles.module.scss";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "90%",
        margin: 16,
      }}
    >
      <Col alignItems="center" justifyContent="center" gap={12}>
        <h1 style={{ margin: 0, lineHeight: "29px", fontSize: 24 }}>Something Went Wrong</h1>
        <p style={{ margin: 10 }}>Please restart the add-in and try again.</p>
        <Button
          appearance="primary"
          style={{ width: "100%", height: 40 }}
          onClick={() => {
            navigate("/");
            // Attempt reload from home
            window.location.reload();
          }}
        >
          Restart
        </Button>
        <Button
          appearance="transparent"
          className="secondary"
          style={{ width: "100%", height: 40, backgroundColor: "transparent", borderColor: styles.secondary }}
          onClick={() => window.open(FEEDBACK_URL, "_blank")?.focus()}
        >
          Send Feedback
        </Button>
      </Col>
    </div>
  );
};

export default ErrorPage;
