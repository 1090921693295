import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Text,
} from "@fluentui/react-components";
import { Warning20Regular } from "@fluentui/react-icons";
import { Col } from "../../../components/Layout/Space";
import { ConnectionKind } from "./ConnectionItem";
import { ProjectFile } from "../../../../data/projects/models";
import { CloudProjectFileDefinition, Direction } from "../../../../data/models";
import "./ConflictDialog.scss";

export enum ConflictChoice {
  OverwriteCloud = "overwriteCloud",
  UploadNewFilename = "uploadNewFilename",
  OverwriteWorkbook = "overwriteWorkbook",
  ReuploadToCloud = "reuploadToCloud",
}

export const ConflictDialog = ({
  open,
  setOpen,
  kind,
  item,
  connection,
  callback,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  kind: ConnectionKind;
  item: ProjectFile;
  connection: CloudProjectFileDefinition;
  callback: (choice: ConflictChoice) => void;
}) => {
  function getMessage() {
    const top = (
      <Text weight="bold">
        {connection.sourceSelection.displayName}
        {connection.direction === Direction.Upload ? " -> " : " <- "}
        {connection.fileName}
      </Text>
    );
    switch (kind) {
      case ConnectionKind.UploadConflict: {
        const [d1, t1] = connection.lastUploaded.slice(0, 19).split("T");
        const [d2, t2] = item.last_modified.slice(0, 19).split("T");
        return (
          <Col>
            {top}
            <Text>
              You last uploaded at
              <br />
              <Text italic={true}>
                {d1} {t1}
              </Text>
            </Text>
            <Text>
              The cloud version was modified at
              <br />
              <Text italic={true}>
                {d2} {t2}
              </Text>
            </Text>
            <Text>How would you like to proceed?</Text>
          </Col>
        );
      }
      case ConnectionKind.UnknownUpload: {
        const [d, t] = connection.lastUploaded.slice(0, 19).split("T");
        return (
          <Col>
            {top}
            <Text>
              You last uploaded at
              <br />
              <Text italic={true}>
                {d} {t}
              </Text>
            </Text>
            <Text>The cloud version has been deleted or moved</Text>
            <Text>How would you like to proceed?</Text>
          </Col>
        );
      }
      case ConnectionKind.UnknownDownload: {
        const [d, t] = connection.lastDownloaded.slice(0, 19).split("T");
        return (
          <Col>
            {top}
            <Text>
              You last downloaded at
              <br />
              <Text italic={true}>
                {d} {t}
              </Text>
            </Text>
            <Text>The cloud version has been deleted or moved</Text>
            <Text>How would you like to proceed?</Text>
          </Col>
        );
      }
      default:
        return "Unknown conflict";
    }
  }

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      <DialogSurface className="custom-dialog-surface">
        <DialogBody>
          <DialogTitle style={{ fontSize: 14 }}>
            <Warning20Regular /> Resolve Conflict
          </DialogTitle>
          <DialogContent>{getMessage()}</DialogContent>
          <DialogActions>
            <Col>
              {kind === ConnectionKind.UploadConflict && (
                <DialogTrigger disableButtonEnhancement>
                  <Button onClick={() => callback(ConflictChoice.OverwriteCloud)}>Overwrite Cloud Version</Button>
                </DialogTrigger>
              )}
              {kind === ConnectionKind.UnknownUpload && (
                <DialogTrigger disableButtonEnhancement>
                  <Button onClick={() => callback(ConflictChoice.ReuploadToCloud)}>Re-upload to Cloud</Button>
                </DialogTrigger>
              )}
              {(kind === ConnectionKind.UploadConflict || kind === ConnectionKind.UnknownUpload) && (
                <DialogTrigger disableButtonEnhancement>
                  <Button onClick={() => callback(ConflictChoice.UploadNewFilename)}>Upload with New Filename</Button>
                </DialogTrigger>
              )}
              {kind === ConnectionKind.UploadConflict && (
                <DialogTrigger disableButtonEnhancement>
                  <Button onClick={() => callback(ConflictChoice.OverwriteWorkbook)}>Overwrite Data in Excel</Button>
                </DialogTrigger>
              )}
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
            </Col>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
