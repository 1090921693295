import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface PairSetup extends BaseSetup {
  kind: ValueTracker<string>;
  diagKind: ValueTracker<string>;
  xVars: string[];
  yVars: string[];
  vars: string[];
  isCollapsed: boolean;
}

export interface PairDesign extends BaseDesign {
  // TODO: specifics
  palette: ValueTracker<string>;
  // Can be single or mulitple
  markers: string[];
  markerSize: ValueTracker<number>;
  lineStyle: ValueTracker<string>;
  lineWidth: ValueTracker<number>;
  height: ValueTracker<number>;
  aspect: ValueTracker<number>;
  bin: ValueTracker<number>;
  element: ValueTracker<string>;
  stat: string;
  isCollapsed: boolean;
  legendPosition: string;
}

export const pairwiseSetup: PairSetup = {
  headers: [],
  xVars: [],
  yVars: [],
  vars: [],
  dropna: new ValueTracker(false),
  colorBy: "",
  kind: new ValueTracker("Scatter"),
  diagKind: new ValueTracker("Auto"),
  isCollapsed: true,
};

export const pairwiseDesign: PairDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  palette: new ValueTracker("Accent"),
  markers: [],
  // I don't think matplotlib sets a default marker size
  markerSize: new ValueTracker(6),
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  height: new ValueTracker(2.5),
  aspect: new ValueTracker(1),
  bin: new ValueTracker(10),
  element: new ValueTracker("Bars"),
  stat: "",
  isCollapsed: true,
  legendPosition: "",
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
