import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import {
  Axes,
  Chart,
  IntervalValueMap,
  LineStyle,
  Markers,
  RegEstimators,
  RegFunction,
  ValueTracker,
} from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  CheckBoxAttr,
  SpinnerAttr,
  LineKwargs,
  regressionErrorBar,
  AxisDropdownAttr,
  GridlinesAttr,
  SingleChartBorders,
  LegendAttr,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import {
  dependencyEqualsValue,
  dependencyInValues,
  dependencyNotEqualsValue,
  fetchHeaders,
} from "../MVCShared/PlotGeneratorUtils";
import { RegressionSetup, RegressionDesign as IRegressionDesign } from "../../../../taskpane/hooks/plots/useRegression";

const RegressionContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    RegressionSetup,
    IRegressionDesign
  >(PlotType.regression);
  useEffect(() => {
    // If input data exists, and x, y, or both axes are selected, enable the Create/Apply button
    if (setup && common?.inputData) {
      const hasAxesSelected: boolean = setup.xAxis !== "" && setup.yAxis !== "";
      if (!hasAxesSelected) {
        const errors: Set<string> = new Set([
          ...(common.validationErrors || []),
          "Select X and Y axis in the setup tab to preview chart.",
        ]);
        updateCommon({ validationErrors: errors });
      } else {
        updateCommon({ validationErrors: new Set() });
      }
    }
  }, [setup?.xAxis, setup?.yAxis, common?.inputData]);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...regressionPlot.baseAttrs, ...regressionPlot.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const MarkerAttr = DropdownAttr({
    label: "Marker",
    value: design.marker,
    options: ["Point", "Circle", "Plus", "Star", "Diamond", "X"],
    onChange: (_, e) => updateDesign({ marker: e.optionValue }),
    codeKey: "marker",
    codeValueMap: Markers,
    codeRequiresInteraction: true,
  });
  MarkerAttr.getCode = (code: CodeBuilder) => {
    // Explicitly set marker color to allow for modification
    if (design.marker.isUpdated) {
      code.plotAttrs.push(`marker="${Markers[design.marker.value]}", scatter_kws={ 'color': '${design.color.value}' }`);
    }
  };

  const errorBar = DropdownAttr({
    value: setup.errorBar,
    label: "Error Bar",
    onChange: (_, data) => updateSetup({ errorBar: data.optionValue }),
    options: ["No error bars", "Confidence Interval", "Standard Deviation"],
    codeKey: "x_ci",
    codeRequiresInteraction: false,
    codeValueMap: IntervalValueMap,
    dataTestID: "error-bar",
  });

  const interval = SpinnerAttr({
    label: "Interval",
    value: setup.interval,
    step: 1,
    min: 0,
    max: 100,
    suffix: "%",
    onChange: (data: number) => updateSetup({ interval: data }),
    visibleDependencies: [dependencyInValues(errorBar, ["Confidence Interval"])],
    enabledDependencies: [dependencyInValues(errorBar, ["Confidence Interval", "Standard Deviation"])],
    codeRequiresInteraction: true,
    codeKey: "ci",
    dataTestID: "ci",
  });

  const regFunction = DropdownAttr({
    label: "Function",
    value: setup.function,
    onChange: (_, data) => updateSetup({ function: data.optionValue }),
    options: ["Linear", "Polynomial", "Logx", "Lowess", "Robust", "Logistic"],
    codeRequiresInteraction: true,
    dataTestID: "function",
  });

  regFunction.getCode = (code: CodeBuilder) => {
    if (setup.function instanceof ValueTracker && setup.function.isUpdated) {
      RegFunction[setup.function.value] ? code.plotAttrs.push(`${RegFunction[setup.function.value]}=True`) : "";
    }
  };

  const estimator = DropdownAttr({
    value: setup.estimator,
    onChange: (_, data) => updateSetup({ estimator: data.optionValue }),
    label: "Estimator",
    options: ["Mean", "Median", "Max", "Min"],
    placeholder: "Mean",
  });

  estimator.getCode = (code: CodeBuilder) => {
    const estimator = RegEstimators[setup.estimator];
    estimator ? code.plotAttrs.push(`x_estimator=${estimator}`) : "";
  };

  const xPartial = AxisDropdownAttr({
    value: setup.xPartial,
    onChange: (_, data) => updateSetup({ xPartial: data.optionValue }, "--Select--"),
    label: "X-Partial",
    options: ["--Select--", ...setup.headers.filter((header) => header !== setup.xAxis && header !== setup.yAxis)],
    codeKey: "x_partial",
    hasHeaders: common.hasHeaders,
  });

  xPartial.getCode = (code: CodeBuilder) => {
    setup.xPartial ? code.plotAttrs.push(`x_partial=df["${setup.xPartial}"]`) : "";
  };
  const yPartial = AxisDropdownAttr({
    value: setup.yPartial,
    onChange: (_, data) => updateSetup({ yPartial: data.optionValue }, "--Select--"),
    label: "Y-Partial",
    options: ["--Select--", ...setup.headers.filter((header) => header !== setup.xAxis && header !== setup.yAxis)],
    codeKey: "y_partial",
    hasHeaders: common.hasHeaders,
  });

  yPartial.getCode = (code: CodeBuilder) => {
    setup.yPartial ? code.plotAttrs.push(`y_partial=df["${setup.yPartial}"]`) : "";
  };

  const lineWidthAndStyle = LineKwargs({
    styleLabel: "Line Style",
    options: ["Solid", "Dashed"],
    dropdownValue: design.lineStyle,
    onChangeStyle: (_, data) => updateDesign({ lineStyle: data.optionValue }),
    label: "Line Width",
    value: design.lineWidth,
    step: 1,
    suffix: "px",
    onChange: (data: number) => updateDesign({ lineWidth: data }),
    codeValueMap: LineStyle,
    codeRequiresInteraction: true,
  });

  let lineLabel = LabelAttr({
    label: "Line Label",
    value: design.lineLabel,
    placeholder: "Label",
    posKey: "label",
    onChange: (event) => updateDesign({ lineLabel: event.currentTarget.value }),
  });
  lineLabel.getCode = (code: CodeBuilder) => {
    if (design.lineLabel) {
      code.plotAttrs.push(`label="${design.lineLabel}"`);
      code.plotCalls.push(`\nplt.legend()`);
    }
  };

  const legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
    visibleDependencies: [dependencyNotEqualsValue(lineLabel, "")],
  });

  const RegressionErrorBar = regressionErrorBar({
    value: design.errorBarColor,
    spinnerValue: design.errorBarWidth,
    decimalValue: design.errorBarCapWidth,
    label: "Error Bar Color",
    suffixLabel: "Error Bar Thickness",
    decimalLabel: "Error Bar Cap Width",
    onChange: (hexColor) => updateDesign({ errorBarColor: `#${hexColor}` }),
    onValueChange: (data) => updateDesign({ errorBarWidth: data }),
    onDecimalChange: (data) => updateDesign({ errorBarCapWidth: data }),
    suffix: "px",
    suffixStep: 1,
    decimalStep: 0.1,
    yAxis: setup.yAxis,
    xAxis: setup.xAxis,
    enabledDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
    codeRequiresInteraction: true,
  });

  const regressionPlot: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      AxisDropdownAttr({
        value: setup.xAxis,
        onChange: (_, data) => {
          updateSetup({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        label: "X-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "x",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      estimator,
      DividerAttr(),
      HeadingAttr({ title: "Regression", tooltip: "Select regression function type and bounding" }),
      regFunction,
      SpinnerAttr({
        label: "Order",
        value: setup.order,
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        onChange: (data: number) => updateSetup({ order: data }),
        codeKey: "order",
        visibleDependencies: [dependencyEqualsValue(regFunction, "Polynomial")],
        dataTestID: "order",
      }),
      CheckBoxAttr({
        label: "Truncate",
        onChange: (_, data) => updateSetup({ truncate: data.checked }),
        value: setup.truncate,
        codeRequiresInteraction: true,
        codeKey: "truncate",
        dataTestID: "truncate",
      }),
      DividerAttr(),
      CollapsibleAttr({
        label: "Error",
        tooltip: "Measure error in data",
        collapsed: setup.isCollapsed,
        toggle: () => updateSetup({ isCollapsed: !setup.isCollapsed }),
        children: [
          errorBar,
          interval,
          SpinnerAttr({
            label: "Bootstrap Samples",
            value: setup.bootstrapSamples,
            step: 100,
            onChange: (data: number) => updateSetup({ bootstrapSamples: data }),
            codeKey: "n_boot",
            codeRequiresInteraction: true,
            visibleDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
          }),
          SpinnerAttr({
            label: "Seed",
            value: setup.seed,
            step: 1,
            onChange: (data: number) => updateSetup({ seed: data }),
            codeKey: "seed",
            codeRequiresInteraction: true,
            visibleDependencies: [dependencyNotEqualsValue(errorBar, "No error bars")],
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: setup.newXAxisScale,
        onChange: (_, data) => updateSetup({ newXAxisScale: data.optionValue }),
        label: "X-Axis Scale",
        options: ["Linear", "Log"],
        callKey: "xscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
      }),
      DropdownAttr({
        value: setup.newYAxisScale,
        onChange: (_, data) => updateSetup({ newYAxisScale: data.optionValue }),
        label: "Y-Axis Scale",
        options: ["Linear", "Log"],
        callKey: "yscale",
        codeValueMap: Axes,
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Partials", tooltip: "Select confounding variables to regress out of x and y variables" }),
      xPartial,
      yPartial,
      DividerAttr(),
      HeadingAttr({ title: "Jitter", tooltip: "Add uniform random noise to either the x or y variables" }),
      SpinnerAttr({
        label: "X-Jitter",
        value: setup.xJitter,
        step: 0.1,
        onChange: (data: number) => updateSetup({ xJitter: data }),
        codeKey: "x_jitter",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Jitter",
        value: setup.yJitter,
        step: 0.1,
        onChange: (data: number) => updateSetup({ yJitter: data }),
        codeKey: "y_jitter",
        codeRequiresInteraction: true,
      }),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Markers" }),
      MarkerAttr,
      DividerAttr(),
      lineWidthAndStyle,
      lineLabel,
      legend,
      DividerAttr(),
      CollapsibleAttr({
        label: "Error",
        collapsed: design.isDesignCollapsed,
        toggle: () => updateDesign({ isDesignCollapsed: !design.isDesignCollapsed }),
        children: [RegressionErrorBar],
      }),
    ],
  };
  return regressionPlot;
};

const RegressionForm = () => {
  const regressionPlot = RegressionContext();
  return buildReactFromAttrs(regressionPlot.baseAttrs, 140);
};

export const RegressionDesign = () => {
  const regressionDesign = RegressionContext();
  return buildReactFromAttrs(regressionDesign.designAttrs, 140);
};

export default RegressionForm;
