import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { ChevronUp16Filled, ChevronDown16Filled } from "@fluentui/react-icons";
import { Row } from "../../../../taskpane/components/Layout/Space";
import "../Viz.scss";
import "./SpinButton.scss";

interface NumericSpinnerProps {
  min?: number;
  max?: number;
  step: number;
  value: number;
  units?: string;
  isDisabled?: boolean;
  dataTestID?: string;
  precision?: number;
  onChange: (newValue: number) => void;
}

/**
 * Example usage:
 * 
    <EditableSpinner
      min={-5}
      max={100}
      step={5}
      value={0}
      units="%"
      onChange={(newValue: number) => {
        console.log(newValue);
      }}
    />
 */
export const EditableSpinner: React.FC<NumericSpinnerProps> = ({
  min,
  max,
  step,
  value,
  units = "",
  precision = 2,
  onChange,
  dataTestID,
}) => {
  const formatValue = (value: number) => `${value}${units}`;
  const [internalValue, setInternalValue] = useState<string>(formatValue(value));
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInternalValue(newValue);

    const parsedValue = parseFloat(newValue);
    if (!isNaN(parsedValue) && parsedValue >= min && parsedValue <= max) {
      const precise = +parsedValue.toFixed(precision);
      onChange(precise);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    let newValue = parseFloat(internalValue);
    if (isNaN(newValue)) newValue = 0;

    if (e.key === "ArrowUp") {
      increment();
    } else if (e.key === "ArrowDown") {
      decrement();
    }
  };

  const increment = () => {
    let newValue = parseFloat(internalValue);
    if (isNaN(newValue)) newValue = 0;
    const incrementedValue = Math.min(newValue + step, max);
    const precise = +incrementedValue.toFixed(precision);
    setInternalValue(formatValue(precise));
    onChange(precise);
  };

  const decrement = () => {
    let newValue = parseFloat(internalValue);
    if (isNaN(newValue)) newValue = 0;
    const decrementedValue = Math.max(newValue - step, min);
    const precise = +decrementedValue.toFixed(precision);
    setInternalValue(formatValue(precise));
    onChange(precise);
  };

  const roundToNearestStep = (value: number) => {
    const roundedValue = Math.round((value - min) / step) * step + min;
    const nearestStep = Math.max(min, Math.min(roundedValue, max));
    const precise = +nearestStep.toFixed(precision);
    return precise;
  };

  const handleBlur = () => {
    const parsedValue = parseFloat(internalValue);
    if (isNaN(parsedValue)) {
      const precise = +value.toFixed(precision);
      setInternalValue(formatValue(precise));
    } else {
      const roundedValue = roundToNearestStep(parsedValue);
      const precise = +roundedValue.toFixed(precision);
      setInternalValue(formatValue(precise));
      onChange(precise);
    }
  };

  return (
    <div className="editable-spinner">
      {/* Using a text type allows for more control of formatting and styling */}
      <input
        type="text"
        value={internalValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        data-testid={dataTestID}
      />
      <div
        className="column"
        style={{
          position: "absolute",
          top: 0,
          right: 2,
        }}
      >
        <button onClick={increment} disabled={parseFloat(internalValue) >= max}>
          <ChevronUp16Filled />
        </button>
        <button onClick={decrement} disabled={parseFloat(internalValue) <= min}>
          <ChevronDown16Filled />
        </button>
      </div>
    </div>
  );
};

interface FormattedSpinner extends NumericSpinnerProps {
  label: string;
  labelWidth: number;
}

export const Spinner = (spinnerArgs: FormattedSpinner) => {
  const { label, labelWidth } = spinnerArgs;
  return (
    <div style={{ padding: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <EditableSpinner {...spinnerArgs} />
      </Row>
    </div>
  );
};
