import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner, Tab, TabList, Tooltip } from "@fluentui/react-components";
import { ArrowResetFilled, Play16Filled } from "@fluentui/react-icons";
import { ActionButtonRow, Row } from "../../components/Layout/Space";
import { useBlocks } from "../../hooks/useBlocks";
import "./Edublocks.scss";

import {
  WorkbookStructure,
  readWorkbookStructure,
  writeToCell,
  RangeSelection,
  getFullAddressFromRangeSelection,
} from "xlcommon/src/excel/excel-grid-utils";
import AddressChooser from "../../../excel/components/AddressChooser";
import { getGlobal } from "xlcommon/src/global";
const g = getGlobal();

function Edublocks() {
  // Maintain Edublocks state in application context
  const { blocks, setBlocks, writeAddress, setAddress } = useBlocks();

  // Managing window
  const ref = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const container = ref?.current?.contentWindow; // ref.contentWindow

  // Integration reference
  // https://github.com/anaconda/excel-add-in/pull/26/files#diff-eb7d60f4924ab1b4c169c2d0a9cd15c7b91835147f5fee02ec4f45cf84037b0eR2
  useEffect(() => {
    if (container) {
      g.addEventListener("message", syncExcel);
    }
    return () => g.removeEventListener("message", syncExcel);
  }, [container]);

  const addressRef = useRef<RangeSelection>();
  addressRef.current = writeAddress;

  async function syncExcel(event: MessageEvent) {
    let writeAddress = addressRef.current;
    if (event.source == container) {
      switch (event.data.kind) {
        case "request_tables":
          findExcelTables();
          break;
        case "request_blocks":
          container.postMessage(
            {
              kind: "blocks",
              data: blocks,
            },
            "*"
          );
          break;
        case "code": {
          /*
           * Sync to a chosen address which was selected in the UI, not the currently selected Excel cell
           * (This prevents the user from overriding unintended cells, by intentionally chosing the address)
           */
          const code = event.data.data.code;

          await writeToCell(await getFullAddressFromRangeSelection(writeAddress), code);

          heap.track("excel-edublocks-click-run");

          event.data.data.blocks.forEach((block: string) => {
            heap.track("excel-edublocks-use-block", {
              block_type: block,
            });
          });

          break;
        }
        case "update_blocks":
          setBlocks(event.data.data);
          break;
      }
    }
  }

  function findExcelTables() {
    readWorkbookStructure().then((wbstruct: WorkbookStructure) => {
      console.log({ TablesFound: wbstruct.tables });
      if (wbstruct.tables.length > 0) {
        container.postMessage(
          {
            kind: "tables",
            data: {
              tables: wbstruct.tables,
            },
          },
          "*"
        );
      }
    });
  }

  function requestCode() {
    container.postMessage(
      {
        kind: "request_code",
      },
      "*"
    );
  }

  function switchView(view: unknown) {
    container.postMessage(
      {
        kind: "switch_view",
        data: {
          view,
        },
      },
      "*"
    );
  }

  return (
    <>
      <div className="edublocks">
        {loading && <Spinner />}
        {!loading && (
          <>
            <Row justifyContent="space-between">
              <TabList defaultSelectedValue="visual" onTabSelect={(_, selectedTab) => switchView(selectedTab.value)}>
                <Tab value="visual">Visual Editor</Tab>
              </TabList>

              <Tooltip content="Refresh tables" relationship="label" hideDelay={1}>
                <Button onClick={findExcelTables} icon={<ArrowResetFilled />}></Button>
              </Tooltip>
            </Row>

            <br />

            <AddressChooser selection={writeAddress} onSelection={setAddress} />

            <br />
          </>
        )}

        <iframe
          ref={ref}
          style={{ height: "calc(100% - 205px)", width: "100%" }}
          id="edublocks"
          title="Edublocks code builder"
          frameBorder={0}
          src="https://app.edublocks.org/project/new?mode=excel"
          onLoad={() => setLoading(false)}
        />

        {!loading && (
          <>
            <ActionButtonRow alignItems="flex-end">
              <Tooltip content="Please select an output cell" relationship="label" hideDelay={1}>
                <Button
                  className="action-button primary"
                  style={{ padding: "8px", opacity: !writeAddress ? 0.7 : 1 }}
                  onClick={requestCode}
                  disabled={!writeAddress}
                  appearance="primary"
                  icon={<Play16Filled />}
                  iconPosition="before"
                >
                  Run
                </Button>
              </Tooltip>
            </ActionButtonRow>
          </>
        )}
      </div>
    </>
  );
}

export default Edublocks;
