// Replace any item in list from replacementList based on key
export function replaceObjectsMatchKey(list: object[], replacementList: object[], key: string) {
  // Populate replacementMap with items from replacementList
  const replacementMap = new Map();
  replacementList.forEach((item) => replacementMap.set(item[key], item));

  const newList = list.map((item) => {
    const replacementItem = replacementMap.get(item[key]);
    // We found a matching item based on the key
    if (replacementItem) {
      // Remove the item from replacementMap
      replacementMap.delete(item[key]);
      // Return the new replacement item
      return { ...replacementItem };
    } else {
      return item; // Return original item if no replacement found
    }
  });

  // Add remaining items from replacementMap to newList
  replacementMap.forEach((item) => newList.push({ ...item }));
  return newList;
}

// Ensure filename ends in suffix
export function ensureFileSuffix(filename: string, suffix: string): string {
  if (suffix !== suffix.toLowerCase()) throw Error(`Suffix must be lowercase, received ${suffix}`);

  if (!suffix.startsWith(".")) suffix = "." + suffix;

  if (filename.toLowerCase().endsWith(suffix)) {
    // File has the suffix, but it might not be a lowercase suffix, so replace it
    return `${filename.slice(0, -suffix.length)}${suffix}`;
  } else {
    // File does not have the suffix, so add it
    return `${filename}${suffix}`;
  }
}

/**
 * Capitalize the first letter of a word
 */
export function capitalize(word) {
  if (!word) return word;
  if (word.length === 1) return word.toUpperCase();
  return word.charAt(0).toUpperCase() + word.substring(1, word.length);
}

/**
 * Open a URL in a new window
 * @param toWindow URL to open in a new window
 * @returns void
 */
export function navigate(toWindow: string): void {
  return window.open(toWindow, "_blank")?.focus();
}
