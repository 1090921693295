import React from "react";
import ProjectsExplorer from "../Projects/ProjectsExplorer";
import { SnippetItem } from "./SnippetItem";

function Snippets() {
  return (
    <div className="code-snippets">
      <div className="tab-content">
        <ProjectsExplorer tag="code_snippet" Item={SnippetItem} />
      </div>
    </div>
  );
}

export default Snippets;
