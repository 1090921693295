import React from "react";
import { Label } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Col } from "./Layout/Space";
import "./CustomDatePicker.scss";

const CustomDatePicker = ({
  label,
  selectedDate,
  onSelectDate,
}: {
  label: string;
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
}) => {
  return (
    <Col gap={0}>
      <Label style={{ fontSize: 12, fontWeight: 400, paddingBottom: 2 }}>{label}</Label>
      <DatePicker
        placeholder="Select a date"
        formatDate={(date: Date) => date.toLocaleDateString()}
        onSelectDate={onSelectDate}
        showMonthPickerAsOverlay={true}
        showGoToToday={false}
        style={{ width: 157 }}
        value={selectedDate}
        disableAutoFocus
      />
    </Col>
  );
};

type DateArgs = {
  selectedFrom: Date;
  selectedTo: Date;
  setSelectedFrom: (date: Date) => void;
  setSelectedTo: (date: Date) => void;
};

const DateModified = ({ selectedFrom, selectedTo, setSelectedFrom, setSelectedTo }: DateArgs) => {
  return (
    <div style={{ paddingTop: 5 }}>
      <Label style={{ fontSize: 13 }}>Date Modified</Label>
      <div className="date-picker-container-row">
        <CustomDatePicker label="From" selectedDate={selectedFrom} onSelectDate={setSelectedFrom} />
        <CustomDatePicker label="To" selectedDate={selectedTo} onSelectDate={setSelectedTo} />
      </div>
    </div>
  );
};

export default DateModified;
