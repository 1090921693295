import { CHANNEL_NAME } from "xlcommon/src/broadcast/interface";
import Env from "xlcommon/src/environ";

const DEBUG = false;

const callbacks = {
  isLoggedIn: [],
  pyscriptSettings: [],
};

let channel = null;
try {
  channel = new BroadcastChannel(CHANNEL_NAME);

  channel.onmessage = async (ev: MessageEvent) => {
    if (DEBUG && ev?.data) console.log(`Toolbox BroadcastChannel received ${ev.data.action}`);
    switch (ev?.data?.action) {
      case "requestIsLoggedIn":
        if (Env.signedIn) {
          await broadcastLogin();
        }
        break;
      case "login":
        Env.signedIn = true;
        while (callbacks.isLoggedIn.length > 0) {
          const cb = callbacks.isLoggedIn.pop();
          cb();
        }
        break;
      case "logout":
        Env.signedIn = false;
        window.location.href = "/";
        break;
      case "responsePyScriptSettings":
        while (callbacks.pyscriptSettings.length > 0) {
          const cb = callbacks.pyscriptSettings.pop();
          cb(ev.data.settings);
        }
        break;
      default:
        console.log(`Invalid message: ${JSON.stringify(ev)}`);
    }
  };

  channel.onmessageerror = (err: MessageEvent) => {
    console.error(err.data);
  };
} catch (err) {
  console.error("UNABLE TO CREATE BROADCAST CHANNEL!");
}

export function pingForLogin(cb: () => void) {
  if (DEBUG) console.log(`Toolbox BroadcastChannel pingForLogin()`);
  callbacks.isLoggedIn.push(cb);
  channel?.postMessage({ action: "requestIsLoggedIn" });
}

export function broadcastLogout() {
  if (DEBUG) console.log(`Toolbox BroadcastChannel broadcastLogout()`);
  channel?.postMessage({ action: "logout" });
}

export async function broadcastLogin() {
  if (DEBUG) console.log(`Toolbox BroadcastChannel broadcastSignedIn()`);
  channel?.postMessage({ action: "login" });
}

export function requestPyScriptSettings(cb: (settings: object) => void) {
  callbacks.pyscriptSettings.push(cb);
  channel?.postMessage({ action: "requestPyScriptSettings" });
}
