import { AsyncDuckDB, ConsoleLogger, DuckDBBundles, selectBundle } from "@duckdb/duckdb-wasm";
import { RangeType, displayRangeSelection, overwriteSheet, overwriteTable } from "xlcommon/src/excel/excel-grid-utils";
import { convertEpochToExcel, convertDateToExcel, EXCEL_BASE } from "xlcommon/src/excel/excel-date-utils";
import { Type as ArrowType, DateUnit as ArrowDateUnit, TimeUnit as ArrowTimeUnit } from "apache-arrow";
import ProjectsService from "./projects/projects-api";
import { CloudProjectFileDefinition } from "./models";

const MAX_FILESIZE = 100_000_000;
const MAX_FILES = 20;

async function cleanupDuckDBFilesystem(db: AsyncDuckDB) {
  const files = await db.globFiles("*");
  // Sort in reverse order by fileId
  files.sort((a, b) => (a.fileId < b.fileId ? 1 : -1));
  while (files.length > MAX_FILES || files.reduce((size, file) => size + file.fileSize, 0) > MAX_FILESIZE) {
    const lastFile = files.pop();
    await db.dropFile(lastFile.fileName);
  }
}

type PreviewConfiguration = {
  overwrite?: boolean;
  previewRowCount?: number;
  convert?: boolean;
};
export async function downloadFile(
  projectId: string,
  projectFileDefinition: CloudProjectFileDefinition,
  { overwrite = true, previewRowCount = 0, convert = true }: PreviewConfiguration = {}
): Promise<{ content: any[]; rowCount?: number; columnCount?: number }> {
  try {
    const db = await prepDatabase();

    // TODO: Save file type as metadata when PUT-ing file
    // Fetch file metadata to obtain latest signed URL
    const fileResponse = await ProjectsService.getFileMetadata(projectId, projectFileDefinition.fileName);
    const file = fileResponse?.items?.[0];
    const suffix = file.name.split(".").pop().toLowerCase();
    const URL = file.signed_url ?? file.url;
    const uniqueName = `File${file.file_version_id.replaceAll("-", "")}.${suffix}`;

    // If file is already downloaded, don't download again
    const filesFound = await db.globFiles(uniqueName);
    if (filesFound.length == 0) {
      console.log(`Downloading ${file.name}`);
      const response = await fetch(URL);
      const data = await response.text();
      await db.registerFileText(uniqueName, data);
      await cleanupDuckDBFilesystem(db);
    }

    let query = projectFileDefinition.query ?? "SELECT * FROM data";
    const fullFileCountQuery: string = `SELECT COUNT(*) FROM data`;
    // Limit the results if we are downloading the file for preview
    if (previewRowCount) {
      const match = query.match(/ LIMIT \d+/i);
      if (match) {
        const limit = parseInt(match[0].slice(7));
        query = query.replace(/ LIMIT \d+/i, ` LIMIT ${Math.min(previewRowCount, limit)}`);
      } else {
        query = `${query} LIMIT ${previewRowCount}`;
      }
    }
    // Build replacement based on suffix type
    let replacement = uniqueName;
    if (suffix === "csv") {
      replacement = `read_csv_auto(${replacement}, header=True, all_varchar=${convert ? "False" : "True"})`;
    } else if (suffix === "parquet") {
      replacement = `read_parquet(${replacement})`;
    }
    // Rename complex table name "AS data" for nicer error messages, unless already renamed
    if (!query.toLocaleLowerCase().includes("from data as ")) {
      replacement = `${replacement} AS data`;
    }

    // Query the table
    const conn = await db.connect();
    let resp = await conn.query(query.replaceAll(/\bdata\b/g, replacement));
    let fullRowColumnCount = await conn.query(fullFileCountQuery.replaceAll(/\bdata\b/g, replacement));
    const rowCount = fullRowColumnCount.toArray()[0]["count_star()"];
    if (overwrite) console.log(`${projectFileDefinition.fileName} size = ${resp.numRows} x ${resp.numCols}`);
    let content = [];
    // Header
    content.push(resp.schema.names);
    // Types (github.com/apache/arrow/js/src/type.ts)
    const typesFound = []; // used for debug printing
    const typeCast = [];
    const numberFormat = [];
    resp.schema.fields.forEach((f) => {
      switch (f.typeId) {
        case ArrowType.Date:
          if (f.type.unit === ArrowDateUnit.DAY) {
            typesFound.push(`[${f.name}] as Date.DAY`);
            typeCast.push((x: Date) => convertDateToExcel(x));
            numberFormat.push("yyyy-mm-dd");
          } else if (f.type.unit === ArrowDateUnit.MILLISECOND) {
            typesFound.push(`[${f.name}] as Date.MILLISECOND`);
            typeCast.push((x: number) => convertEpochToExcel(x));
            numberFormat.push("yyyy-mm-dd");
          }
          break;
        case ArrowType.Timestamp:
          typesFound.push(`[${f.name}] as Timestamp`);
          typeCast.push((x: number) => convertEpochToExcel(x));
          numberFormat.push("yyyy-mm-dd hh:mm:ss");
          break;
        case ArrowType.Time:
          if (f.type.unit === ArrowTimeUnit.NANOSECOND) {
            typesFound.push(`[${f.name}] as Time.NANOSECOND`);
            typeCast.push((x: bigint) => convertEpochToExcel(Number(x / 1000000n)) - EXCEL_BASE);
          } else if (f.type.unit === ArrowTimeUnit.MICROSECOND) {
            typesFound.push(`[${f.name}] as Time.MICROSECOND`);
            typeCast.push((x: bigint) => convertEpochToExcel(Number(x / 1000n)) - EXCEL_BASE);
          } else if (f.type.unit === ArrowTimeUnit.MILLISECOND) {
            typesFound.push(`[${f.name}] as Time.MILLISECOND`);
            typeCast.push((x: number) => convertEpochToExcel(x) - EXCEL_BASE);
          } else if (f.type.unit === ArrowTimeUnit.SECOND) {
            typesFound.push(`[${f.name}] as Time.SECOND`);
            typeCast.push((x: number) => convertEpochToExcel(x * 1000) - EXCEL_BASE);
          }
          numberFormat.push("hh:mm:ss");
          break;
        case ArrowType.Int:
          typesFound.push(`[${f.name}] as Int`);
          typeCast.push(null);
          numberFormat.push(null);
          break;
        case ArrowType.Float:
          typesFound.push(`[${f.name}] as Float`);
          typeCast.push(null);
          numberFormat.push(null);
          break;
        case ArrowType.Utf8:
          typesFound.push(`[${f.name}] as String.UTF8`);
          typeCast.push(null);
          numberFormat.push(null);
          break;
        case ArrowType.Bool:
          typesFound.push(`[${f.name}] as Bool`);
          typeCast.push(null);
          numberFormat.push(null);
          break;
        default:
          typesFound.push(`[${f.name}] as <Unhandled arrow type: ${f.type.toString()}>`);
          typeCast.push(null);
          numberFormat.push(null);
          break;
      }
    });
    // Console log the types found
    if (overwrite) console.log(`${projectFileDefinition.fileName} columns = ${typesFound}`);

    // Build list of lists from Data (including date+time conversions for Excel)
    resp.toArray().forEach((row) => {
      content.push(
        // eslint-disable-next-line
        [...row].map(([_, value], i: number) => {
          const caster = typeCast[i];
          if (caster) return caster(value);
          return value;
        })
      );
    });

    if (overwrite) {
      await putContentInSpreadsheet(projectFileDefinition, content, numberFormat);
    }

    // Clean up
    conn.close();

    return { content, rowCount, columnCount: resp.numCols };
  } catch (e) {
    // Explicitly handle wrong table name
    if (e?.message?.startsWith("Catalog Error: Table with name")) {
      throw new Error('Catalog Error: The SQL table must be named "data" (i.e. SELECT * FROM data)');
    } else {
      throw new Error(e);
    }
  }
}

async function putContentInSpreadsheet(
  definition: CloudProjectFileDefinition,
  content: any[][],
  numberFormat?: string[]
) {
  switch (definition.sourceSelection.rangeType) {
    case RangeType.Sheet:
      await overwriteSheet(definition.sourceSelection.identifier, content, numberFormat);
      break;
    case RangeType.Table:
      await overwriteTable(definition.sourceSelection.identifier, content, numberFormat);
      break;
    default:
      throw Error(
        `Invalid destination: ${displayRangeSelection(definition.sourceSelection)}\nOnly Sheet and Table allowed`
      );
  }
}

const DUCKDB_MANUAL_BUNDLES: DuckDBBundles = {
  mvp: {
    mainModule: new URL("@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm", import.meta.url).toString(),
    mainWorker: new URL("@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js", import.meta.url).toString(),
  },
  eh: {
    mainModule: new URL("@duckdb/duckdb-wasm/dist/duckdb-eh.wasm", import.meta.url).toString(),
    mainWorker: new URL("@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js", import.meta.url).toString(),
  },
};

let _db: AsyncDuckDB;
let _dbLoadingFlag = false;
async function prepDatabase(): Promise<AsyncDuckDB> {
  if (_db === undefined) {
    if (!_dbLoadingFlag) {
      // Grab the flag
      _dbLoadingFlag = true;
    } else {
      // Wait for 0.25 seconds and try again
      await new Promise((resolve) => setTimeout(resolve, 250));
      return await prepDatabase();
    }
    // Select a bundle based on browser checks
    const duckBundle = await selectBundle(DUCKDB_MANUAL_BUNDLES);
    // Instantiate the asynchronous version of DuckDB-wasm
    const duckDbWorker = new Worker(duckBundle.mainWorker!);
    const duckDbLogger = new ConsoleLogger();
    const _dbTemp = new AsyncDuckDB(duckDbLogger, duckDbWorker);
    console.log("Initializing DuckDB");
    await _dbTemp.instantiate(duckBundle.mainModule, duckBundle.pthreadWorker);

    // Monkey patch BIGINT so tables can be JSON-serialized
    BigInt.prototype["toJSON"] = function () {
      return this.toString();
    };

    _db = _dbTemp;
    _dbLoadingFlag = false;
  }
  return _db;
}
