import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Row } from "./Layout/Space";
import styles from "../styles.module.scss";
import "./Initial.scss";

const DEFAULT_FONT_SIZE = 24;
const DEFAULT_SIZE = 44;

export const InitialCircle: React.FC<{ initial: string; size?: number; color?: string }> = ({
  initial,
  size = 44,
  color = styles.primary,
}) => (
  <div
    className="circle"
    style={{ height: size, width: size, fontSize: size * (DEFAULT_FONT_SIZE / DEFAULT_SIZE), backgroundColor: color }}
  >
    {initial}
  </div>
);

export const InitialsList: React.FC<{ initials: string[] }> = ({ initials }) => {
  return (
    <div className="initials-list">
      <Row gap={0}>
        {initials.map((initial) => (
          <InitialCircle key={`${initial}-${uuidv4()}`} initial={initial} size={18} />
        ))}
      </Row>
    </div>
  );
};
