import React from "react";
import { TabProps, Tooltip, Tab } from "@fluentui/react-components";
import "./TooltipTab.scss";

export interface TooltipTabProps extends TabProps {
  tooltipContent: string;
}

export const TooltipTab: React.FC<TooltipTabProps> = ({ tooltipContent, ...props }) => {
  return (
    <Tooltip content={tooltipContent} relationship="label" withArrow showDelay={1000}>
      <Tab className="tab" {...props}>
        {props.children}
      </Tab>
    </Tooltip>
  );
};
