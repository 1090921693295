import { UseMutationResult, useMutation, useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../auth/auth-api";
import { authenticatedRequest } from "../request";
import { UserSettingType, UserSettingDefaults, UserSettings } from "./types";
import { queryClient } from "../../taskpane";
import { getGlobal } from "xlcommon/src/global";

const STALE_TIME_MINUTES = 3;
const STALE_TIME_MS = STALE_TIME_MINUTES * 60 * 1000;
const URL = `${BASE_URL}/api/user_settings`;

const g = getGlobal();

export function useAddSettingToDatabase(usersetting: string) {
  const defs: UserSettingType = UserSettingDefaults[usersetting];
  if (defs === undefined) throw Error(`Invalid UserSetting: ${usersetting}`);

  const mutation: UseMutationResult = useMutation({
    mutationKey: [`setting-${defs.category}`],
    mutationFn: (settingValue: UserSettingType["value"]) =>
      authenticatedRequest({
        path: `/`,
        url: URL,
        method: "POST",
        body: JSON.stringify({ category: defs.category, setting_name: defs.settingName, value: settingValue }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`usersettings-${defs.category}-${defs.settingName}`],
        refetchType: "all",
        exact: true,
      });
    },
  });
  return mutation;
}

export function useUserSetting(usersetting: string) {
  const defs: UserSettingType = UserSettingDefaults[usersetting];
  if (defs === undefined) throw Error(`Invalid UserSetting: ${usersetting}`);

  const userSettingsQuery = useQuery<UserSettingType>({
    queryKey: [`usersettings-${defs.category}-${defs.settingName}`],
    queryFn: () => authenticatedRequest({ path: `/${defs.category}/${defs.settingName}`, url: URL, method: "GET" }),
    staleTime: STALE_TIME_MS,
    retry: true,
  });

  return {
    ...userSettingsQuery,
    // If setting was not in database (userSettingsQuery.data.settingName == null), return the default value
    data:
      userSettingsQuery?.data?.[defs.settingName] !== null ? userSettingsQuery?.data?.[defs.settingName] : defs.value,
  };
}

/**********************************************************
 * Utilities used for Testing
 **********************************************************/

async function testUtilGetUserSettings(category: string) {
  // Check for accidental request for a single setting
  if (category.indexOf(".") !== -1) return await testUtilGetUserSetting(category);

  // Verify category is known
  if (UserSettings[category] === undefined) throw Error(`Invalid category: ${category}`);

  return await authenticatedRequest({ path: `/${category}`, url: URL, method: "GET" });
}

async function testUtilGetUserSetting(usersetting: string) {
  // Check for accidental request for a category of settings
  if (usersetting.indexOf(".") === -1) return await testUtilGetUserSettings(usersetting);

  // Verify usersetting is known
  const defs: UserSettingType = UserSettingDefaults[usersetting];
  if (defs === undefined) throw Error(`Invalid UserSetting: ${usersetting}`);

  const data = await authenticatedRequest({ path: `/${defs.category}/${defs.settingName}`, url: URL, method: "GET" });
  if (data?.[defs.settingName] === null) {
    data["defaultValue"] = defs.value;
  }
  return data;
}

async function testUtilDelUserSetting(usersetting: string) {
  const defs: UserSettingType = UserSettingDefaults[usersetting];
  if (defs === undefined) throw Error(`Invalid UserSetting: ${usersetting}`);

  await authenticatedRequest({ path: `/${defs.category}/${defs.settingName}`, url: URL, method: "DELETE" });
  return `Successfully deleted ${defs.category}.${defs.settingName}`;
}

async function testUtilSetUserSetting(usersetting: string, value: UserSettingType["value"]) {
  const defs: UserSettingType = UserSettingDefaults[usersetting];
  if (defs === undefined) throw Error(`Invalid UserSetting: ${usersetting}`);

  const data = await authenticatedRequest({
    path: `/`,
    url: URL,
    method: "POST",
    body: JSON.stringify({ category: defs.category, setting_name: defs.settingName, value }),
  });
  return {
    category: defs.category,
    [defs.settingName]: data[defs.settingName],
  };
}
(g as any).testUtilGetUserSettings = testUtilGetUserSettings;
(g as any).testUtilGetUserSetting = testUtilGetUserSetting;
(g as any).testUtilDelUserSetting = testUtilDelUserSetting;
(g as any).testUtilSetUserSetting = testUtilSetUserSetting;
