import Env from "xlcommon/src/environ";
import { authenticatedFetch } from "./fetch";
import { Account, AuthSessionInit, OrganizationUser, OrganizationUsers, Organizations } from "./models";
import { snakeEyesRecord, snakeEyesNote } from "../analytics/snake-eyes";
import { broadcastLogout, broadcastLogin } from "./broadcast";

export const BASE_URL = Env.BASE_URL;

export function isLoggedIn() {
  return Env.signedIn;
}

// Flag to avoid duplicate refresh calls in attemptSilentLogin
let isAttemptingSilentLogin = false;

export async function attemptSilentLogin() {
  if (!isAttemptingSilentLogin) {
    isAttemptingSilentLogin = true;

    try {
      const res = await fetch(`${BASE_URL}/api/auth/check-token`, { method: "GET" });
      if (res.status === 200) {
        Env.signedIn = true;
        broadcastLogin();
        snakeEyesLogin(true, false); // automatic, no refresh
      } else if (res.status === 403) {
        // No cookie is set or cookie has expired or session has been removed
        // Require full login
        console.log(`Silent login failed. Auth cookie not set or is expired.`);
      } else if (res.status === 401) {
        // Auth token found, but is invalid or has expired; Attempt to refresh
        const refreshResponse = await fetch(`${Env.BASE_URL}/api/auth/refresh`, { method: "GET" });
        if (refreshResponse.status === 200) {
          Env.signedIn = true;
          broadcastLogin();
          snakeEyesLogin(true, true); // automatic, required refresh
        }
      } else {
        console.log(`Silent login failed!`);
      }
    } finally {
      isAttemptingSilentLogin = false;
    }
  } else {
    // Wait for other attempt to finish, then return
    while (isAttemptingSilentLogin) await new Promise((resolve) => setTimeout(resolve, 500));
  }
}

let dialogRef: Office.Dialog;
export async function login() {
  // Log in if needed
  if (!Env.signedIn) {
    try {
      const sessionInit: AuthSessionInit = await new Promise((resolve, reject) => {
        promptUserLogin((data) => {
          if (data === null || !("session_id" in data)) {
            console.log(`promptUserLogin returned data=${JSON.stringify(data)}`);
            reject();
          } else {
            resolve(data);
          }
        });
      });
      // Verify session id
      const vResp = await fetch(`${BASE_URL}/api/auth/validate-session`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(sessionInit),
      });
      if (vResp.ok) {
        Env.signedIn = true;
        // Broadcast logged in state
        broadcastLogin();
        snakeEyesLogin(false, false);
      } else {
        throw new Error(`validate-session returned ${vResp.status}`);
      }
    } catch (e) {
      console.error(`Error during login: ${e}`);
      await snakeEyesNote({
        event: "login-failure",
        eventParams: {
          automatic: false,
          platform: Office.context.platform,
          app: "toolbox",
        },
      });
    }
  }
}

async function snakeEyesLogin(automatic: boolean, refreshed: boolean) {
  // Get domain and tier from anaconda account
  const account = await getAccount();
  const anaorg = account.user?.email?.split("@")[1] || "";
  const tier = account.metadata?.is_pro_tier ? "pro" : account.metadata?.is_starter_tier ? "starter" : "free";
  // Send login event to snake eyes
  await snakeEyesRecord({
    event: "login",
    eventParams: {
      automatic: automatic,
      refreshed: refreshed,
      platform: Office.context.platform,
      cloud_user_domain: anaorg,
      tier: tier,
      app: "toolbox",
    },
  });
}

/**
 * Double Jump Dialog Approach
 *
 * 1. Use the normal Office.context.ui.displayDialogAsync call to display the first page.
 * 2. The first page establishes a BroadcastChannel and listens for messages.
 * 3. The first page calls window.open to open the second page.
 * 4. The second page does the Ory dance -- a bunch of cross-origin redirects and finally redirects to our OIDC handler.
 * 5. The second page has the auth code served from our domain.
 * 6. The second page establishes a BroadcastChannel and posts the auth code back to the first page.
 * 7. The first page was opened by the add-in, so it is able to talk with the add-in via Office.context.ui.messageParent callbacks. It sends the auth code to the add-in. It also closes the second dialog by posting a close() broadcast message.
 * 8. The add-in receives the auth code and closes the dialog.
 *
 * This seems super complicated, but it's the only thing that works to get the auth code to the
 * add-in-within-an-iframe for Excel Online. It also gives us a standard location for the "Remember Me" checkbox.
 */
function promptUserLogin(callback: (data: AuthSessionInit | null) => void) {
  const loginUrl: URL = new URL("api/auth/login-redirect", BASE_URL);
  let dialog: Office.Dialog;
  Office.context.ui.displayDialogAsync(
    loginUrl.href,
    { height: 25, width: 20, promptBeforeOpen: false }, // We can avoid the prompt because user clicks a button already

    function (asyncResult: Office.AsyncResult<Office.Dialog>): void {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Successful dialog prompt");
        // Force it to wait until the dialog value is available, since we can't await
        // Not my favorite solution but open to other ideas - we can't re-prompt without a reference
        // eslint-disable-next-line
        while (asyncResult.value == ({} as Office.Dialog)) {}
        dialogRef = asyncResult.value;

        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
          dialog.close();
          callback(JSON.parse(arg["message"]));
        });
      } else if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        // https://learn.microsoft.com/en-us/office/dev/add-ins/develop/dialog-best-practices#open-another-dialog-immediately-after-closing-one
        const HAS_ACTIVE_DIALOG_ERROR = 12007;
        if (asyncResult.error.code === HAS_ACTIVE_DIALOG_ERROR) {
          console.log({ dialogRef });
          dialogRef.close();
          setTimeout(() => {
            promptUserLogin(callback); // Recursive call suggested by MSFT until closed
          }, 1000);
        } else {
          console.log(`Dialog returned an error: ${asyncResult.error.code}`);
          console.error(asyncResult.error);
        }
      } else {
        console.log("Error occurred when attempting to prompt: ");
        const err = asyncResult.error;
        console.log(err.name + ": " + err.message);
      }
    }
  );
}

export async function logout() {
  snakeEyesRecord({ event: "logout" }); // must happen before user is actually logged out
  await fetch(`${BASE_URL}/api/auth/logout`, { method: "POST" });
  Env.signedIn = false;
  broadcastLogout();
}

export function addSubscriptionMetadata(data: Account): Account {
  const PRO_TIER_SUBSCRIPTIONS = ["commercial_subscription", "security_subscription", "enterprise_subscription"];
  const STARTER_TIER_SUBSCRIPTION = "starter_subscription";

  let isStarterTier = false;
  let isProTier = false;

  const subscription = data.subscriptions;

  if (subscription?.length) {
    const productCodes = subscription.map((sub) => sub.product_code);
    isStarterTier = productCodes.some((code) => code === STARTER_TIER_SUBSCRIPTION);
    isProTier = productCodes.some((code) => PRO_TIER_SUBSCRIPTIONS.includes(code));
  }

  data = {
    ...data,
    metadata: {
      is_starter_tier: isStarterTier,
      is_pro_tier: isProTier,
    },
  };
  return data;
}

export async function getAccount(): Promise<Account> {
  let userUrl = `${BASE_URL}/api/cloud/account`;
  let res = await authenticatedFetch(userUrl, {
    method: "GET",
  });
  let userInfo = await res.json();
  return addSubscriptionMetadata(userInfo);
}

export async function getOrganizations(): Promise<Organizations> {
  let orgUrl = `${BASE_URL}/api/cloud/organizations/my`;
  let res = await authenticatedFetch(orgUrl, {
    method: "GET",
  });
  let data = await res.json();
  return data;
}

export async function getOrganizationUsers(orgName: string): Promise<OrganizationUsers> {
  let orgUrl = `${BASE_URL}/api/cloud/organizations/${orgName}/users`;
  let res = await authenticatedFetch(orgUrl, {
    method: "GET",
  });
  let data = await res.json();
  return data;
}

/*
* TODO/NOTE: 
  This is a hacky way to get all user objects with id, name, and email, since the API does not support
  returning permissions with user data other than the user IDs. Without this data, we cannot manage permissions in the UI.

  For now, we are aggregating users per organization, and we anticipate each user to be in a small amount of organizations.
  We are anticipating the Projects API functionality to be expanded in the future.
*/
export async function getAllOrgUsers(organizationNameList: string[]): Promise<OrganizationUser[]> {
  let userIdToMaxRole: Record<string, string> = {};
  let userObjects: OrganizationUser[] = [];

  let promises = organizationNameList.map(async (organization) => {
    const users: OrganizationUsers = await getOrganizationUsers(organization);
    users?.items.forEach((user) => {
      if (!userIdToMaxRole[user.id]) {
        // Only add the user if they aren't already in users list
        userIdToMaxRole[user.id] = user.role;
        userObjects.push({ ...user });
      } else if (userIdToMaxRole[user.id] === "member") {
        // Reset rendered privlege if same user found to potentially higher privlege
        userIdToMaxRole[user.id] = user.role;
        userObjects.map((userObj) => {
          if (userObj.id === user.id) {
            return {
              ...userObj,
              role: user.role,
            };
          }
        });
      }
    });
    return users;
  });
  await Promise.all(promises);

  return userObjects;
}
