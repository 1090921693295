import { QueryObserverOptions, useQuery } from "@tanstack/react-query";
import { authenticatedRequest } from "../request";
import { Account, Organizations } from "../../auth/models";
import { addSubscriptionMetadata } from "../../auth/auth-api";

const STALE_TIME_MINUTES = 3;
const STALE_TIME_MS = STALE_TIME_MINUTES * 60 * 1000;

export const useAccount = (options?: { enabled?: QueryObserverOptions["enabled"] }) => {
  const accountQuery = useQuery<Account>({
    queryKey: ["account"],
    queryFn: () => authenticatedRequest({ path: "/account" }),
    staleTime: STALE_TIME_MS,
    enabled: options?.enabled ?? true, // Enable / disable automatic refetching when the query mounts or changes query keys
    select: addSubscriptionMetadata,
  });
  return accountQuery;
};

// TODO: Replace getOrganizations in auth-api
export const useOrganizations = (options?: { enabled?: QueryObserverOptions["enabled"] }) => {
  const organizationsQuery = useQuery<Organizations>({
    queryKey: ["organizations"],
    queryFn: () => authenticatedRequest({ path: "/organizations/my" }),
    staleTime: STALE_TIME_MS,
    enabled: options?.enabled ?? true, // Enable / disable automatic refetching when the query mounts or changes query keys
  });
  return organizationsQuery;
};
