import Env from "xlcommon/src/environ";
import { authenticatedFetch } from "../auth/fetch";

const BASE_URL = Env.BASE_URL;
const CLOUD_API_URL = `${BASE_URL}/api/cloud`;

export async function authenticatedRequest<T>({
  path,
  method = "GET",
  body,
  url = CLOUD_API_URL,
}: {
  path: string;
  method?: string; // GET, POST, PUT, PATCH, DELETE
  body?: BodyInit; // Must convert to FormData, JSON.stringify(), etc.
  url?: string;
}): Promise<T> {
  let options = {
    method,
    headers: {
      "Content-type": "application/json",
    },
  };
  if (body) {
    options["body"] = body;
  }
  const response = await authenticatedFetch(`${url}${path}`, options);
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
  return await response.json();
}
