import {
  ChatPanelFooter,
  ChatRequestOptions,
  ChatStarter,
  HomeScreen,
  OnCodeComplete,
  getActiveNotebook,
  useStore,
} from "@anaconda/assistant-components";
import { AssistantSuggestionType, Python } from "@anaconda/assistant-sdk";
import { throttle } from "lodash";
import React, { useCallback, useRef } from "react";
import { ChatMessages } from "./ChatMessages";
import { TOKEN_LIMIT } from "./config";
import { getAssistantSdk } from "./utils/assistantSdk";

type Props = {
  tracker?: any;
  addCodeToContainer?: OnCodeComplete;
  getActiveCodeCell?: () => string;
  activateTableChooser?: (chatStarter: ChatStarter) => void;
};

export function ChatPanel({ tracker, addCodeToContainer, getActiveCodeCell, activateTableChooser }: Props) {
  const activeChatKey = useStore((state) => getActiveNotebook(state)?.activeChatKey);
  const messageEndRef = useRef<HTMLDivElement>(null);

  const [
    activeContainer,
    selectChatStarter,
    makeChatRequest,
    enableDataCollection,
    suggestionDataFrame,
    dataFrames,
    isProTier,
    updateSuggestions,
    resetActiveChat,
    setSuggestionsDataframe,
  ] = useStore((state) => {
    const activeContainer = getActiveNotebook(state);
    return [
      activeContainer,
      state.selectChatStarter,
      state.makeChatRequest,
      state.settings.enableDataCollection,
      activeContainer?.suggestionState.dataframe,
      activeContainer?.notebookState.dataframes,
      state.account?.metadata?.is_pro_tier,
      state.updateSuggestions,
      state.resetActiveChat,
      state.setSuggestionsDataframe,
    ];
  });

  const chatStarters = activeContainer?.homeScreen?.chatStarters ?? null;
  const selectedChatStarter = activeContainer?.homeScreen?.selectedChatStarter ?? null;
  const suggestionState = activeContainer?.suggestionState;

  function scrollToBottom() {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const throttledScroll = useCallback(throttle(scrollToBottom, 500), []);

  const handleUpdateSuggestions = (
    activeCell: string,
    type: AssistantSuggestionType,
    dataframe: Python.DataFrame | null
  ) => {
    updateSuggestions(activeCell, type, dataframe, getAssistantSdk());
  };

  const handleSelectChatStarter = (chatStarter: ChatStarter) => {
    // To handle chat starters that require a table or range selection
    // declare the name of the chat starter with "excel-" prefix
    // and set contextSelectionRequired to true
    if (chatStarter.name.includes("excel-") && chatStarter.contextSelectionRequired) {
      activateTableChooser(chatStarter);
    } else {
      selectChatStarter(chatStarter);
    }
  };

  const handleMakeChatRequest = (request: ChatRequestOptions) => {
    // Intercepting the chat request to handle the case where context type is set by default
    if (request.context?.type === "default-python") {
      makeChatRequest({ context: { type: "excel-default-python", variables: {} }, instruction: request.instruction });
    } else {
      makeChatRequest(request);
    }
  };

  return (
    <>
      <div className="as-chat-main-section">
        {!activeChatKey ? (
          <>
            <HomeScreen
              chatStarters={chatStarters}
              selectedChatStarter={selectedChatStarter}
              selectChatStarter={handleSelectChatStarter}
              makeChatRequest={handleMakeChatRequest}
              getActiveCodeCell={getActiveCodeCell}
              dataFrames={dataFrames}
              enableDataCollection={enableDataCollection}
              activeNotebook={true}
              suggestionState={suggestionState}
              updateSuggestions={handleUpdateSuggestions}
              setSuggestionsDataframe={setSuggestionsDataframe}
              isProTier={isProTier}
              resetActiveChat={resetActiveChat}
              showLogoutButton={false}
              logout={() => {}}
              suggestionDataFrame={suggestionDataFrame}
            />
          </>
        ) : (
          <ChatMessages tracker={tracker} addCodeToContainer={addCodeToContainer} scrollFn={throttledScroll} />
        )}
        <div style={{ float: "left", clear: "both" }} ref={messageEndRef} />
      </div>
      <ChatPanelFooter scrollFn={throttledScroll} tokenLimit={TOKEN_LIMIT} />
    </>
  );
}
