import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface CountSetup extends BaseSetup {
  // TODO: specifics
}

export interface CountDesign extends BaseDesign {
  // TODO: specifics
  marker: string; // This should show the options for the marker "some1" | "some2"
  width: ValueTracker<number>;
  saturation: ValueTracker<number>;
  colorOrder: string;
  dodge: ValueTracker<boolean>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  legendPosition: string;
  isCollapsed: boolean;
  alpha: ValueTracker<number>;
}

export const countSetup: CountSetup = {
  orientation: new ValueTracker("Vertical"),
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
};

export const countDesign: CountDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker(true),
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Accent"),
  marker: "",
  width: new ValueTracker(0.8),
  saturation: new ValueTracker(0.75),
  colorOrder: "",
  dodge: new ValueTracker(false),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  legendPosition: "",
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
  alpha: new ValueTracker(1),
};
