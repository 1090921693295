import React, { CSSProperties, useRef, useState } from "react";
import {
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Button,
  Spinner,
  OpenPopoverEvents,
  OnOpenChangeData,
  Tooltip,
  ButtonProps,
} from "@fluentui/react-components";
import { MoreVertical20Filled } from "@fluentui/react-icons";
import ProjectsService from "../../data/projects/projects-api";
import { ConfirmationDialog } from "./Dialogs/ConfirmationDialog";
import styles from "../styles.module.scss";

type MenuAction = {
  actionName: string;
  onClickAction: (actionName: string) => void | Promise<Response | void>;
  icon?: ButtonProps["icon"];
};

export const PopoverMenuButton = ({
  id,
  actions,
  buttonStyle,
  kind,
}: {
  id: string;
  actions: MenuAction[];
  buttonStyle?: CSSProperties;
  kind: "folder" | "file" | "definition";
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const actionRef = useRef<{ onClickAction: () => void }>({ onClickAction: null });

  const [disabled, setDisabled] = useState<boolean>(false);

  async function checkHasAccess() {
    // Workbook definitions operate on local workbook data
    if (kind === "definition") return true;

    const access = await ProjectsService.getMyProjectAccessLevels(id);
    try {
      if (kind === "folder") {
        return access?.share && access?.delete;
      } else if (kind === "file") {
        return access?.modify;
      }
    } catch (e) {
      console.log(e);
      setDisabled(true);
      return false;
    }
  }

  async function handleActionClick(action: MenuAction) {
    const { actionName, onClickAction } = action;
    setIsLoading(true);

    // Show confirmation modal when we have critical actions
    if (actionName.includes("Delete")) {
      setOpenConfirmation(true);
      actionRef.current.onClickAction = async () => {
        await onClickAction(actionName);
        setOpenConfirmation(false);
      };
    } else {
      await onClickAction(actionName);
    }
    setIsLoading(false);
    setIsPopoverOpen(false);
  }

  async function onOpenChange(e: OpenPopoverEvents, data: OnOpenChangeData) {
    if (data.open) {
      const hasAccess = await checkHasAccess();
      if (!hasAccess) {
        setDisabled(true);
      }
    }
    setIsPopoverOpen(data.open);
  }

  return (
    <>
      <Popover
        positioning="before"
        closeOnScroll={true}
        open={isPopoverOpen}
        onOpenChange={(e, data) => onOpenChange(e, data)}
      >
        <PopoverTrigger>
          {disabled ? (
            <Tooltip
              content={
                kind === "folder"
                  ? "Only project owners and admins can perform project actions"
                  : "Requires write permissions to modify"
              }
              relationship="label"
              withArrow
            >
              <Button
                style={{ marginLeft: 4, marginRight: 4, ...buttonStyle }}
                size="small"
                appearance="subtle"
                icon={<MoreVertical20Filled color={styles.gray400} />}
                data-testid="vertical-export-menu"
                iconPosition="before"
                disabled={disabled}
              />
            </Tooltip>
          ) : (
            <Button
              style={{ marginLeft: 4, marginRight: 4, ...buttonStyle }}
              size="small"
              appearance="subtle"
              icon={<MoreVertical20Filled color={styles.gray400} />}
              data-testid="vertical-export-menu"
              iconPosition="before"
            />
          )}
        </PopoverTrigger>
        <PopoverSurface style={{ padding: 4, width: 112 }}>
          {actions.map((action) => (
            <Button
              key={action.actionName}
              style={{ fontWeight: "normal", justifyContent: "flex-start" }}
              appearance="transparent"
              disabled={disabled}
              onClick={() => handleActionClick(action)}
              iconPosition="before"
              icon={action.icon}
            >
              {isLoading ? <Spinner size="extra-tiny" /> : action.actionName}
            </Button>
          ))}
        </PopoverSurface>
      </Popover>
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        deleteCallback={async () => await actionRef.current?.onClickAction()}
      />
    </>
  );
};
