import React from "react";
import styles from "../styles.module.scss";
import "./Icon.scss";

export const Sparkles: React.FC<{ className?: string; fill?: string }> = ({
  className = "",
  fill = styles.primary,
}) => {
  return (
    <svg
      className={`custom-icon ${className}`}
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3906 3.65625C11.2852 3.58594 11.25 3.48047 11.25 3.375C11.25 3.26953 11.2852 3.19922 11.3906 3.12891L13.5 2.25L14.3438 0.175781C14.4141 0.0703125 14.5195 0 14.625 0C14.7305 0 14.8008 0.0703125 14.8711 0.175781L15.75 2.25L17.8242 3.12891C17.9297 3.19922 18 3.26953 18 3.375C18 3.48047 17.9297 3.58594 17.8242 3.65625L15.75 4.5L14.8711 6.60938C14.8008 6.71484 14.7305 6.75 14.625 6.75C14.5195 6.75 14.4141 6.71484 14.3438 6.60938L13.5 4.5L11.3906 3.65625ZM17.8242 14.3789C17.9297 14.4492 18 14.5195 18 14.625C18 14.7305 17.9297 14.8359 17.8242 14.9062L15.75 15.75L14.8711 17.8594C14.8008 17.9648 14.7305 18 14.625 18C14.5195 18 14.4141 17.9648 14.3438 17.8594L13.5 15.75L11.3906 14.9062C11.2852 14.8359 11.25 14.7305 11.25 14.625C11.25 14.5195 11.2852 14.4492 11.3906 14.3789L13.5 13.5L14.3438 11.4258C14.4141 11.3203 14.5195 11.25 14.625 11.25C14.7305 11.25 14.8008 11.3203 14.8711 11.4258L15.75 13.5L17.8242 14.3789ZM13.5 9C13.5 9.21094 13.3594 9.42188 13.1836 9.49219L9.21094 11.4961L7.24219 15.4688C7.13672 15.6445 6.92578 15.75 6.75 15.75C6.50391 15.75 6.32812 15.6445 6.22266 15.4688L4.25391 11.4961L0.28125 9.49219C0.105469 9.42188 0 9.21094 0 9C0 8.78906 0.105469 8.57812 0.28125 8.50781L4.25391 6.50391L6.22266 2.56641C6.43359 2.17969 7.03125 2.17969 7.24219 2.56641L9.21094 6.50391L13.1836 8.50781C13.3594 8.57812 13.5 8.78906 13.5 9Z"
        fill={fill}
      />
    </svg>
  );
};
