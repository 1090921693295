import React, { useEffect, useState } from "react";
import { Divider, Field, Radio, RadioGroup } from "@fluentui/react-components";
import { PythonExecutionLocation } from "../../excel/types";
import { getPythonLocation, savePythonSettings } from "../../excel/grid-utils";
import { usePython } from "../hooks/usePython";
import "./Settings.scss";

export default function Settings() {
  const { hasPython } = usePython();
  const [executionLocation, setExecutionLocation] = useState<PythonExecutionLocation>(PythonExecutionLocation.Azure);

  useEffect(() => {
    (async () => {
      const location: PythonExecutionLocation = await getPythonLocation();
      setExecutionLocation(location);
    })();
  }, []);

  async function updateExecutionLocation(newLocation: PythonExecutionLocation) {
    setExecutionLocation(newLocation);
    await savePythonSettings({
      executionLocation: newLocation,
    });
  }

  return (
    <div className="settings">
      <section style={{ marginBottom: 10 }}>
        <h2>Excel</h2>
        <Field label="Generate Toolbox code as:">
          <RadioGroup
            layout="vertical"
            aria-label="toolbox generated code"
            value={executionLocation}
            disabled={!hasPython}
            onChange={(_, data) => updateExecutionLocation(data.value as PythonExecutionLocation)}
          >
            <Radio label="Python in Excel (=PY)" value={PythonExecutionLocation.Azure.toString()} />
            <Radio label="Anaconda Code (=ANACONDA.CODE)" value={PythonExecutionLocation.Local.toString()} />
          </RadioGroup>
        </Field>
      </section>
      <Divider />
      {/**
       * This is only available in Anaconda Code right now as <EditorThemeSwitch />
        <section>
            <h2>Toolbox</h2>
            <Field label="Code Editor Theme">Switch here</Field>
        </section>
       */}
    </div>
  );
}
