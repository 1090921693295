import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Chart, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  AxisDropdownAttr,
  LegendAttr,
  CollapsibleAttr,
  GridlinesAttr,
  SingleChartBorders,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode, CodeBuilder } from "../MVCShared/CodeBuilder";
import { BoxSetup, BoxDesign as IBoxDesign } from "../../../../taskpane/hooks/plots/useBox";

const BoxContext = (): Chart => {
  const { setup, design, common, updateSetup, updateDesign, updateCommon, setCodeFragments } = useChart<
    BoxSetup,
    IBoxDesign
  >(PlotType.box);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...boxPlot.baseAttrs, ...boxPlot.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const orientationDropdown = DropdownAttr({
    value: setup.orientation,
    onChange: (_, data) => {
      const prevX: string = setup.xAxis;
      const prevY: string = setup.yAxis;
      updateSetup({
        orientation: data.optionValue,
        xAxis: prevY,
        yAxis: prevX,
      });
      const prevXAxisLabel: string = design.xAxisLabel;
      const prevYAxisLabel: string = design.yAxisLabel;
      updateDesign({
        xAxisLabel: prevYAxisLabel,
        yAxisLabel: prevXAxisLabel,
      });
    },
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
    dataTestID: "orientation",
  });

  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation",
    value: design.saturation,
    step: 0.01,
    max: 1,
    min: 0,
    onChange: (data: number) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
    dataTestID: "saturation",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "Best"),
  });

  let dodge = CheckBoxAttr({
    value: design.dodge,
    onChange: (_, e) => updateDesign({ dodge: e.checked }),
    label: "Overlapping groups",
    codeKey: "dodge",
    codeRequiresInteraction: true,
    dataTestID: "dodge",
  });
  dodge.getCode = (code: CodeBuilder) => {
    if (design.dodge.isUpdated) {
      // Invert the value since the checkbox is for overlapping groups
      const dodge = design.dodge.value === true ? "False" : "True";
      code.plotAttrs.push(`dodge=${dodge}`);
    }
  };

  let alpha = SpinnerAttr({
    label: "Opacity",
    value: design.alpha,
    step: 0.1,
    min: 0,
    max: 1,
    onChange: (data: number) => updateDesign({ alpha: data }),
    dataTestID: "alpha",
    codeRequiresInteraction: true,
  });
  alpha.getCode = (code: CodeBuilder) => {
    if (design.alpha.isUpdated) {
      code.plotAttrs.push(`boxprops=dict(alpha=${design.alpha.value})`);
    }
  };

  const boxPlot: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      AxisDropdownAttr({
        value: setup.xAxis,
        onChange: (_, data) => {
          updateSetup({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "x-axis",
        label: "X-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "x",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "y-axis",
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      ColorBy,
      DividerAttr(),
      HeadingAttr({
        title: "Whiskers",
        tooltip: "Compute length of the plot whiskers as proportion of the interquartile range",
      }),
      SpinnerAttr({
        step: 0.1,
        min: 0,
        max: 2 ** 32 - 1,
        label: "Multiple of IQR",
        value: setup.whiskersRange,
        onChange: (data: number) => updateSetup({ whiskersRange: data }),
        codeKey: "whis",
        codeRequiresInteraction: true,
        dataTestID: "whiskers",
      }),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ xticks: data }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (data: number) => updateDesign({ yticks: data }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
        dataTestID: "fill",
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      DividerAttr(),
      HeadingAttr({
        title: "Box Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Width",
        value: design.width,
        step: 0.1,
        min: 0,
        max: 2 ** 32 - 1,
        onChange: (data: number) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
        dataTestID: "width",
      }),
      SpinnerAttr({
        label: "Line Width",
        value: design.lineWidth,
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        suffix: "px",
        onChange: (data: number) => updateDesign({ lineWidth: data }),
        codeKey: "linewidth",
        codeRequiresInteraction: true,
        dataTestID: "line-width",
      }),
      dodge,
      alpha,
      DividerAttr(),
      HeadingAttr({
        title: "Outlier Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Marker Size",
        value: design.markerSize,
        step: 1,
        min: 0,
        max: 2 ** 32 - 1,
        suffix: " px",
        onChange: (data: number) => updateDesign({ markerSize: data }),
        codeKey: "fliersize",
        codeRequiresInteraction: true,
        dataTestID: "marker-size",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend", tooltip: "Legend only visible for some combinations of inputs" }),
      Legend,
    ],
  };
  return boxPlot;
};

const BoxForm = () => {
  const boxPlot = BoxContext();
  return buildReactFromAttrs(boxPlot.baseAttrs, 115);
};

export const BoxDesign = () => {
  const boxDesign = BoxContext();
  return buildReactFromAttrs(boxDesign.designAttrs, 120);
};

export default BoxForm;
