import React, { useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import "../CatalogCard.scss";
import "../Notification.scss";

export const ConfirmationDialog = ({
  navTo,
  navigationOptions,
  deleteCallback,
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  navTo?: string;
  navigationOptions?: NavigateOptions;
  deleteCallback: () => void | Promise<void>;
}) => {
  const navigate = useNavigate();
  const options: NavigateOptions = {
    state: {
      ...navigationOptions,
    },
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  async function confirmCallback() {
    setIsLoading(true);
    try {
      await deleteCallback();
      setIsLoading(false);
      setError("");
    } catch (e) {
      // TODO: Decide how to handle this
      console.log(e);
      setIsLoading(false);
      setError("An error occurred when deleting your project. Please try again.");
    }
    navTo ?? navigate(navTo, options);
  }

  return (
    <Dialog open={open} onOpenChange={(_, data) => setOpen(data.open)}>
      {/* <DialogTrigger disableButtonEnhancement>
          <Button appearance="transparent" aria-label="More options" />
        </DialogTrigger> */}
      <DialogSurface className="custom-dialog-surface">
        <DialogBody>
          <DialogTitle style={{ fontSize: 14 }}>Delete Confirmation</DialogTitle>
          <DialogContent>Are you sure you want to proceed? This action will take effect immediately.</DialogContent>
          <DialogActions>
            <Button appearance="primary" className="warning" onClick={async () => await confirmCallback()}>
              {isLoading ? <Spinner size="tiny" /> : "Delete"}
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
        {error && <p className="error-message">{error}</p>}
      </DialogSurface>
    </Dialog>
  );
};
