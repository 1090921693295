import {
  ActiveMessage,
  CompletedMessage,
  ErrorMessage,
  OnCodeComplete,
  Sentiment,
  UserMessage,
  attachmentMessagePrefix,
  cleanMessageWithAttachmentPrefix,
  formatContextVariables,
  getActiveChat,
  getActiveNotebook,
  getInProgressResponse,
  useStore,
} from "@anaconda/assistant-components";
import { AssistantContexts } from "@anaconda/assistant-sdk";
import React from "react";
import { BackButton } from "./BackButton";

type Props = {
  tracker: any;
  addCodeToContainer: OnCodeComplete;
  scrollFn: () => unknown;
};

export function ChatMessages({ addCodeToContainer, scrollFn }: Props) {
  const [
    messages,
    chatContext,
    inProgressResponse,
    inProgressType,
    activeChatSession,
    retryError,
    selectedText,
    deleteMessagesAfterId,
    submitFeedback,
    response,
    isProTier,
    resetActiveChat,
  ] = useStore((state) => {
    const activeChat = getActiveChat(state);
    return [
      activeChat?.messages,
      activeChat?.context,
      activeChat?.inProgress,
      activeChat?.inProgress?.type,
      activeChat?.session,
      state.retryError,
      getActiveNotebook(state)?.notebookState.selectedText?.text,
      state.deleteMessagesStartingWith,
      state.submitFeedback,
      getInProgressResponse(state),
      state.account?.metadata?.is_pro_tier,
      state.resetActiveChat,
    ];
  });

  if (!messages || !chatContext) return null;

  const initialMessage = messages[0];

  function handleFeedback(messageId: string, sentiment: Sentiment, text: string) {
    submitFeedback({ messageId, sentiment, text });
  }

  return (
    <>
      <BackButton />
      {initialMessage?.role === "user" && (
        <UserMessage
          isInitialMessage
          deleteMessagesAfterId={useStore((state) => state.deleteMessagesStartingWith)}
          exportMessage={() => {}}
          message={{
            ...initialMessage,
            content: determineFirstMessageText(chatContext, initialMessage.content),
          }}
        />
      )}
      {messages.slice(1).map((message) => {
        switch (message.role) {
          case "user":
            return (
              <UserMessage
                key={message.messageId}
                deleteMessagesAfterId={deleteMessagesAfterId}
                exportMessage={() => {}}
                message={{
                  ...message,
                  content: cleanMessageWithAttachmentPrefix(message.content),
                }}
              />
            );
          case "assistant":
            return (
              <CompletedMessage
                key={message.messageId}
                runCode={addCodeToContainer}
                assistantMessage={message}
                selectedText={selectedText}
                activeChatSession={activeChatSession}
                handleFeedback={handleFeedback}
                handleExportMessage={() => {}}
                handleDeleteMessageAfterId={deleteMessagesAfterId}
              />
            );
        }
      })}
      {inProgressType === "active" && (
        <ActiveMessage
          response={response}
          selectedText={selectedText}
          activeChatSession={activeChatSession}
          scrollFn={scrollFn}
        />
      )}
      {inProgressType === "error" && inProgressResponse && (
        <ErrorMessage
          errorMessage={"message" in inProgressResponse ? inProgressResponse.message : "unknown error"}
          retryError={retryError}
          showLogoutButton={false}
          isProTier={isProTier}
          resetChat={resetActiveChat}
        />
      )}
    </>
  );
}

function determineFirstMessageText(context: AssistantContexts.ChatAny, content: string): string {
  const suffix = formatContextVariables(context, content);
  switch (context.type) {
    case "graph-dataframe":
      return `Generate graph for ${suffix}`;
    case "describe-dataframe":
      return `Explain data in ${suffix}`;
    case "explain-code":
      return `Explain ${suffix}`;
    case "comment-code":
      return `Comment ${suffix}`;
    case "improve-code":
      return `Refactor ${suffix}`;
    case "debug-error":
      return `Debug error using ${suffix}`;
    case "suggestions-chat":
    case "default-python":
      if (content.startsWith(attachmentMessagePrefix)) {
        return cleanMessageWithAttachmentPrefix(content);
      } else {
        return `${content} (using ${suffix})`;
      }
    default:
      // Unknown context type
      return content;
  }
}
