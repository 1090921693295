import { ChatStarters } from "@anaconda/assistant-components";

export const getChatStarters = (): ChatStarters => {
  const chatStarters: ChatStarters = {
    categories: [
      {
        id: "tutorial",
        label: "Get started",
        starters: [
          {
            name: "excel-default-python",
            label: "Help with coding tasks",
            instruction: "Help with coding tasks",
            contextType: "excel-default-python",
          },
          {
            name: "plot-fibonacci",
            label: "Plot the first 100 Fibonacci numbers",
            instruction: "Plot the first 100 fibonacci numbers",
            contextType: "excel-default-python",
          },
          {
            name: "mask-emails",
            label: "Create a function to mask emails",
            instruction: `Create a function to mask email addresses, show examples`,
            contextType: "excel-default-python",
          },
        ],
      },
      {
        id: "dataframes",
        label: "Work with tables and ranges",
        starters: [
          {
            name: "excel-graph-dataframe",
            label: "Generate graphs from a table or range",
            contextSelectionRequired: true,
            contextType: "excel-graph-dataframe",
          },
          {
            name: "excel-aggregate-dataframe",
            label: "Aggregate or summarize a table or range",
            contextSelectionRequired: true,
            contextType: "excel-aggregate-dataframe",
          },
        ],
      },
    ],
  };
  return chatStarters;
};
