import React, { useState } from "react";
import { IconButton } from "@fluentui/react/lib/Button";
import {
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  ITooltipHostStyles,
  FocusTrapZone,
  Layer,
  Overlay,
  Popup,
} from "@fluentui/react";
import { Dialog, DialogSurface, DialogTitle, DialogBody, DialogContent } from "@fluentui/react-components";
import { useId } from "@fluentui/react-hooks";
import "./Viz.scss";

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

interface ToolTipProps {
  info: string;
  img: string;
  title: string;
  description?: string;
}

const TooltipContent: React.FunctionComponent<{ info: string }> = ({ info }) => <p className="tooltip-plots">{info}</p>;

export const InfoToolTip: React.FC<ToolTipProps> = ({ info, img, title, description }) => {
  const tooltipId = useId("tooltip");
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <>
      <TooltipHost
        delay={TooltipDelay.zero}
        id={tooltipId}
        directionalHint={DirectionalHint.bottomCenter}
        styles={hostStyles}
        tooltipProps={{
          calloutProps: {
            styles: {
              beak: { background: "black" },
              beakCurtain: { background: "black" },
              calloutMain: { background: "black" },
            },
          },
          onRenderContent: () => <TooltipContent info={info} />,
        }}
      >
        <IconButton
          aria-describedby={tooltipId}
          iconProps={{ iconName: "Info" }}
          styles={{ root: { color: "#8C94A6" } }}
          onClick={() => setIsDialogVisible(true)}
        />
      </TooltipHost>
      {isDialogVisible && (
        <Layer>
          <Overlay className="action-root" onClick={() => setIsDialogVisible(false)} />
          <FocusTrapZone>
            <Popup className="action-root" onDismiss={() => setIsDialogVisible(false)} aria-label="closeButton">
              <Dialog modalType="non-modal" open={isDialogVisible} onOpenChange={() => setIsDialogVisible(false)}>
                <DialogSurface style={{ width: "calc(100% - 20px)", maxWidth: "400px" }}>
                  <DialogBody>
                    <DialogTitle style={{ fontSize: "16px", color: "#2C303A" }}>{title}</DialogTitle>
                    <DialogContent>{description}</DialogContent>
                  </DialogBody>
                  <img style={{ width: "100%" }} src={img} alt={`Plot image`} />
                </DialogSurface>
              </Dialog>
            </Popup>
          </FocusTrapZone>
        </Layer>
      )}
    </>
  );
};
