import { AnacondaAssistant, AnacondaAssistantOptions } from "@anaconda/assistant-sdk";

let assistantSdk: AnacondaAssistant | undefined;

export function getAssistantSdk(): AnacondaAssistant {
  if (!assistantSdk) {
    throw new Error("Assistant not initialized");
  }
  return assistantSdk;
}

export async function initAssistantSdk(options: AnacondaAssistantOptions): Promise<AnacondaAssistant> {
  assistantSdk = new AnacondaAssistant(options);
  try {
    const accessToken = "dummy"; //  access token is handled with cookies
    if (!accessToken) {
      throw new Error("No access token");
    }
    // TODO: Setting expiresAt to 1000 day from now, as the accessToken is a placeholder
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1000);

    assistantSdk.accessToken.setAccessToken({ accessToken, expiresAt: expiresAt.getTime() });
  } catch (e) {
    console.log("Error fetching access: ", e);
  }
  return assistantSdk;
}
