import { snakeEyesRecord } from "../analytics/snake-eyes";
import Env from "xlcommon/src/environ";
import { broadcastLogin } from "./broadcast";

/*
 * Flag to let authenticated calls know that another
 * call is already attempting to refresh the token,
 * avoiding a potential swarm of refresh calls when
 * the current auth token expires.
 */
let isRefreshing = false;

/*
 * Standard `fetch` API with automatic handling of
 * authentication tokens.
 */
export async function authenticatedFetch(resource: RequestInfo, options?: RequestInit, retry = true) {
  const opts: RequestInit = options ? { ...options } : { method: "GET" };

  if (isRefreshing) {
    // Wait until other caller finishes the token refresh
    while (isRefreshing) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    // Retry call with new auth token (without retry)
    return await authenticatedFetch(resource, options, false);
  } else {
    const resp = await fetch(resource, opts);

    if (resp.status >= 400 && resp.status < 500 && retry) {
      if (!isRefreshing) {
        // Claim the refresh flag
        isRefreshing = true;

        try {
          // Check if auth token is expired
          const sessionCheckResponse = await fetch(`${Env.BASE_URL}/api/auth/check-token`, { method: "GET" });
          if (sessionCheckResponse.status === 401) {
            // Auth token is expired; attempt to refresh
            const refreshResponse = await fetch(`${Env.BASE_URL}/api/auth/refresh`, { method: "GET" });
            if (refreshResponse.status === 200) {
              // TODO: do we need these two lines?
              Env.signedIn = true;
              broadcastLogin();
              snakeEyesRecord({ event: "tokenRefresh" });
            } else {
              console.log(`Refresh failed! code=${refreshResponse.status}`);
              Env.signedIn = false;
              throw Error("AuthRequiredException");
            }
          } else if (sessionCheckResponse.status === 403) {
            // Auth cookie is missing or expired
            console.log(`Refresh failed! Missing auth cookie.`);
            Env.signedIn = false;
            throw Error("AuthRequiredException");
          }
        } finally {
          isRefreshing = false;
        }
      }

      return await authenticatedFetch(resource, options, false);
    }

    return resp;
  }
}
