import React, { CSSProperties, ReactNode } from "react";
import { Body1Stronger, Text } from "@fluentui/react-components";
import { ChevronLeft16Filled } from "@fluentui/react-icons";
import "./Typography.scss";

export const Introduction: React.FC<{ children: ReactNode; size?: number }> = ({ children, size = 12 }) => (
  <div className="introduction" style={{ fontSize: size }}>
    {children}
  </div>
);

export const IconHeader = ({
  Icon,
  text,
  className = "",
  style,
  onClick,
}: {
  Icon: React.ReactElement;
  text: string | React.ReactElement;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => (
  <div className={`icon-header ${className}`} style={style} onClick={onClick}>
    {Icon}
    <Body1Stronger>{text}</Body1Stronger>
  </div>
);

export const Breadcrumb = ({
  Icon,
  text,
  style,
  onClick,
  dataTestId,
}: {
  Icon?: React.ReactElement;
  text: string;
  style?: CSSProperties;
  onClick?: () => void;
  dataTestId?: string;
}) => (
  <div className="breadcrumb" style={style} onClick={onClick}>
    {Icon ?? <ChevronLeft16Filled />}
    <Text data-testid={dataTestId} style={{ fontSize: style?.fontSize || 12 }}>
      {text}
    </Text>
  </div>
);

export default IconHeader;
