import React, { createContext, ReactNode, useContext, useState } from "react";
import { RangeSelection, RangeType } from "xlcommon/src/excel/excel-grid-utils";

// TODO: Determine type expected by Edublocks
type Blocks = unknown;

export type BlocksContextType = {
  blocks: Blocks;
  writeAddress: RangeSelection;
  setBlocks: (blocks: Blocks) => void;
  setAddress: (writeAddress: RangeSelection) => void;
};

const BlocksContext = createContext<BlocksContextType>({
  blocks: {},
  writeAddress: {} as RangeSelection,
  setBlocks: () => Promise<void>,
  setAddress: () => Promise<void>,
});

const useBlocks = () => {
  const context = useContext<BlocksContextType>(BlocksContext);
  if (context === undefined) throw new Error("useBlocks must be used within BlocksProvider");
  return context;
};

const BlocksProvider = ({ children }: { children: ReactNode }) => {
  // Context values passed to consumer
  const [blocks, setBlocks] = useState<Blocks>({});
  const [writeAddress, setAddress] = useState<RangeSelection>({
    rangeType: RangeType.CellBinding,
    identifier: "",
    bindingAutoDelete: true,
  } as RangeSelection);

  //   TODO: Consider using deep compare depending on data type for Blocks
  //   useDeepCompareEffect(() => {
  //   }, [blocks]);

  return (
    <BlocksContext.Provider value={{ blocks, writeAddress, setBlocks, setAddress }}>{children}</BlocksContext.Provider>
  );
};

const BlocksConsumer = ({ children }: { children: (context: BlocksContextType) => ReactNode }) => {
  return (
    <BlocksContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error("BlocksConsumer must be used within BlocksProvider");
        }
        return children(context);
      }}
    </BlocksContext.Consumer>
  );
};

export { BlocksProvider, BlocksConsumer, useBlocks };
