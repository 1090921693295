import React, { useEffect, useState, ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { Add16Filled } from "@fluentui/react-icons";
import { Button, Tooltip } from "@fluentui/react-components";
import { ProjectGroups } from "./ProjectGroups";
import { Col, Row } from "../../components/Layout/Space";
import { Conditional } from "../../components/Conditional";
import { useProjects } from "../../../queryclient/projects/projects";
import { useDataFilters } from "../../hooks/useDataFilters";
import { FilterButton } from "./FilterButton";
import { applyProjectFilters } from "./Filter";
import { Project, ProjectFile, SupportedTags } from "../../../data/projects/models";
import { tagToTitle, tagToRoutePrefix, tagToUserSettings } from "../../../data/projects/utils";
import ProjectsService from "../../../data/projects/projects-api";
import GettingStartedDialog from "./GettingStartedDialog";
import { useUserSetting } from "../../../queryclient/settings/settings";
import { ProjectItemProps } from "./ProjectFolder";
import styles from "../../styles.module.scss";
import "./Projects.scss";

export interface ProjectsExplorerProps {
  tag: SupportedTags;
  Item: ComponentType<ProjectItemProps>;
  startExpanded?: boolean; // This forces all project groups and project folders to be expanded
  allowNewProject?: boolean;
  additionalProjectFilter?: (project: Project) => Project;
  refreshItems?: () => Promise<void>;
}

function ProjectsExplorer({
  tag,
  Item,
  startExpanded = false,
  allowNewProject = true,
  additionalProjectFilter = null,
  refreshItems = null,
}: ProjectsExplorerProps) {
  const navigate = useNavigate();
  const { data: landingSeen = undefined } = useUserSetting(tagToUserSettings(tag).LandingSeen);

  const {
    isLoading: projectsLoading,
    data: projects = [],
    refetch: fetchProjects,
  } = useProjects({
    tag,
    filter: additionalProjectFilter,
  });
  const { dateModifiedFrom, dateModifiedTo, projectFilter, fileFilter } = useDataFilters();

  async function refreshProjects() {
    await fetchProjects();
    setNeedsFilesMap(true);
    if (refreshItems) await refreshItems();
  }

  useEffect(() => {
    // Reset the filter if projects changed
    // TODO: Project reference should not be used here - filter the existing project or refetch all projects
    setFilteredProjects([...projects]);
  }, [projects.length]);

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([...projects]);
  const [filesMap, setFilesMap] = useState<Record<string, ProjectFile[]>>();
  const [filesMapLoading, setFilesMapLoading] = useState<boolean>(false);
  const [needsFilesMap, setNeedsFilesMap] = useState<boolean>(false);

  async function getFiles(projects: Project[]): Promise<Record<string, ProjectFile[]>> {
    setFilesMapLoading(true);
    try {
      let filesMap = {};
      const promises = projects.map(async (project) => {
        try {
          const filesObject = await ProjectsService.getProjectFiles(project.id);
          filesMap[project.id] = filesObject?.items;
          return filesMap[project.id];
        } catch (e) {
          if (e.message.includes("Unable to find latest version")) {
            filesMap[project.id] = [];
          }
        }
      });
      await Promise.all(Object.values(promises));
      setFilesMapLoading(false);
      return filesMap;
    } catch (err) {
      console.log(err);
      setFilesMapLoading(false);
      return {};
    }
  }

  useEffect(() => {
    if (needsFilesMap && projects.length > 0 && (dateModifiedFrom || dateModifiedTo || fileFilter)) {
      try {
        (async () => {
          const filesMap = await getFiles(projects);
          setFilesMap({ ...filesMap });
        })();
      } catch (err) {
        console.log(err);
      }
    }
  }, [needsFilesMap]);

  useEffect(() => {
    (async () => {
      // Only trigger fetching of all files if at least one file-level filter is defined
      if ((fileFilter || dateModifiedFrom || dateModifiedTo) && !needsFilesMap) {
        setNeedsFilesMap(true);
      }
      const fltProj = applyProjectFilters(
        filesMap,
        projects,
        dateModifiedFrom,
        dateModifiedTo,
        projectFilter,
        fileFilter
      );
      setFilteredProjects(fltProj);
    })();
  }, [dateModifiedFrom, dateModifiedTo, projectFilter, fileFilter, projectsLoading, filesMap]);

  return (
    <div className="projects-explorer">
      {/* undefined (initial state) = don't show, true = don't show, false = show */}
      <GettingStartedDialog tag={tag} open={landingSeen === false} />
      <Col gap={10}>
        <Conditional condition={allowNewProject}>
          <Row justifyContent="flex-end">
            <Button
              style={{ padding: 8, height: 36, fontWeight: 400, color: styles.secondary }}
              appearance="subtle"
              icon={<Add16Filled />}
              iconPosition="before"
              onClick={() => navigate(`${tagToRoutePrefix(tag)}/new-project`)}
              data-testid="new-project"
            >
              New Project
            </Button>
            <Tooltip
              visible={projects?.length == 0 && !projectsLoading}
              content="Add a Project first in order to add."
              relationship="label"
              withArrow
            >
              <Button
                style={{ padding: 8, height: 36 }}
                appearance="primary"
                icon={<Add16Filled />}
                iconPosition="before"
                onClick={() => navigate(`${tagToRoutePrefix(tag)}/export`)}
                data-testid="upload-dataset"
                disabled={projects?.length == 0}
              >
                {`New ${tagToTitle(tag)}`}
              </Button>
            </Tooltip>
          </Row>
        </Conditional>
        <FilterButton tag={tag} projects={projects} />
        <div style={{ paddingBottom: 12 }} className="bottom-border">
          <Row justifyContent="space-between">
            <label style={{ marginLeft: 12 }}>Name</label>
            <label style={{ marginRight: 12 }}>Actions</label>
          </Row>
        </div>
        <ProjectGroups
          tag={tag}
          projects={filteredProjects ?? projects}
          projectsLoading={projectsLoading || filesMapLoading}
          Item={Item}
          refreshItems={refreshProjects}
          startExpanded={startExpanded || projectFilter?.length > 0}
        />
      </Col>
    </div>
  );
}

export default ProjectsExplorer;
