import Env from "xlcommon/src/environ";
import { authenticatedFetch } from "../auth/fetch";

const DEBUG = false;

const BASE_URL = Env.BASE_URL;
const RECORD_URL = `${BASE_URL}/api/cloud/snake-eyes/record`;
const NOTE_URL = `${BASE_URL}/api/cloud/snake-eyes/note`;

export interface SnakeEyesParameters {
  event: string;
  eventParams?: object;
}

// Record event associated with the user
export async function snakeEyesRecord<T>({ event, eventParams }: SnakeEyesParameters) {
  if (DEBUG) console.log(`[SnakeEyesRecord] event=${event} params=${JSON.stringify(eventParams)}`);
  await snakeEyesRequest<T>({ event, eventParams, url: RECORD_URL, needsAuth: true });
}

// Record anonymous event NOT associated with the user
export async function snakeEyesNote<T>({ event, eventParams }: SnakeEyesParameters) {
  if (DEBUG) console.log(`[SnakeEyesNote] event=${event} params=${JSON.stringify(eventParams)}`);
  await snakeEyesRequest<T>({ event, eventParams, url: NOTE_URL, needsAuth: false });
}

async function snakeEyesRequest<T>({
  event,
  eventParams,
  url,
  needsAuth,
}: SnakeEyesParameters & { url: string; needsAuth: boolean }): Promise<T> {
  try {
    const body = {
      service_id: "XLTB",
      event,
    };
    if (eventParams) {
      body["event_params"] = eventParams;
    }
    let options = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    };
    let response: Response;
    if (needsAuth) {
      response = await authenticatedFetch(url, options);
    } else {
      response = await fetch(url, options);
    }
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    return await response.json();
  } catch (e) {
    if (DEBUG) console.error(`SnakeEyes error: ${e}`);
  }
}
