import React from "react";
import { ErrorMessage } from "@anaconda/assistant-components";

type Props = {
  error: Error;
  resetErrorBoundary: () => unknown;
};

export function ErrorScreen({ error, resetErrorBoundary }: Props) {
  return (
    <div className="as-error-screen">
      <ErrorMessage showLogoutButton={false} errorMessage={error.message} retryError={resetErrorBoundary} />
    </div>
  );
}
