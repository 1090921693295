import React from "react";
import { RangeSelection, getAliasFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { UIAttr, areDependenciesSatisfied, Dependency } from "./types";
import { BaseCommon } from "../../../hooks/plots/PlotTypes";
import { PlotType, PlotLibrary } from "../../../../data/plot-types";
import { getPythonLocation } from "../../../../excel/grid-utils";
import { PythonExecutionLocation } from "../../../../excel/types";

export interface CodeBuilder {
  chartType: PlotType;
  extraImports: string[];
  source: RangeSelection;
  plotAttrs: string[]; // comma-separated attributes inside the plot call, i.e. `hue='Species'`
  plotCalls: string[]; // items like `plt.ylabel('Year')` which come after the initial plot call
  hasHeaders?: boolean;
}

const SeabornChartTypeMap: Record<PlotType, string> = {
  [PlotType.bar]: "barplot",
  [PlotType.line]: "lineplot",
  [PlotType.box]: "boxplot",
  [PlotType.regression]: "regplot",
  [PlotType.scatter]: "scatterplot",
  [PlotType.violin]: "violinplot",
  [PlotType.count]: "countplot",
  [PlotType.pairwise]: "pairplot",
  [PlotType.distribution]: "displot",
};

const PlotLibraryMap: Record<PlotType, PlotLibrary> = {
  [PlotType.bar]: PlotLibrary.seaborn,
  [PlotType.line]: PlotLibrary.seaborn,
  [PlotType.box]: PlotLibrary.seaborn,
  [PlotType.regression]: PlotLibrary.seaborn,
  [PlotType.scatter]: PlotLibrary.seaborn,
  [PlotType.violin]: PlotLibrary.seaborn,
  [PlotType.count]: PlotLibrary.seaborn,
  [PlotType.pairwise]: PlotLibrary.seaborn,
  [PlotType.distribution]: PlotLibrary.seaborn,
};

export function buildReactFromAttrs(attrs: UIAttr[], labelWidth: number) {
  return (
    <>
      <div>
        {attrs.map((attr: UIAttr, index: number) => {
          if (areDependenciesSatisfied(attr.visibleDependencies)) {
            return <div key={index}>{attr.getReact(labelWidth)}</div>;
          }
        })}
      </div>
    </>
  );
}

export function buildCodeFromAttrs(attrs: UIAttr[], cb: CodeBuilder) {
  attrs.forEach((attr) => {
    if (!attr.getCode) return;
    if (!areDependenciesSatisfied(attr.visibleDependencies)) return;
    if ("enabledDependencies" in attr && !areDependenciesSatisfied(attr.enabledDependencies as Dependency[])) return;
    // All checks pass; include the attr in the code
    attr.getCode(cb);
  });
}

export async function buildCode(common: BaseCommon, attrs: UIAttr[]): Promise<CodeBuilder> {
  const cb: CodeBuilder = {
    chartType: common.plotType,
    extraImports: [],
    source: common.inputData,
    plotAttrs: [],
    plotCalls: [],
    hasHeaders: common.hasHeaders,
  };

  // TODO: move these once multiple plot libraries are in use
  if (PlotLibraryMap[common.plotType] === PlotLibrary.seaborn) {
    if (common.plotType === PlotType.regression) {
      cb.extraImports.push("import numpy as np");
      cb.extraImports.push("import statsmodels");
    }
  }

  buildCodeFromAttrs(attrs, cb);

  return cb;
}

export async function buildPythonInExcelCode(cb: CodeBuilder): Promise<string> {
  const library = PlotLibraryMap[cb.chartType];
  if (library === PlotLibrary.seaborn) return buildSeabornPythonInExcelCode(cb);
  throw Error(`Unsupported library: ${library}`);
}

function handleHeaders(executionLocation: PythonExecutionLocation, code: string[], cb: CodeBuilder, dfAlias: string) {
  if (executionLocation === PythonExecutionLocation.Local) {
    if (cb.hasHeaders) {
      code.push(`data = REF("${dfAlias}")\ndf = pd.DataFrame(data[1:], columns=data[0])\n\n`);
    } else {
      code.push(`data = REF("${dfAlias}")\ndf = pd.DataFrame(data)\n\n`);
    }
  } else {
    let headers = cb.hasHeaders.toString().charAt(0).toUpperCase() + cb.hasHeaders.toString().slice(1);
    code.push(`df = xl("${dfAlias}", headers=${headers})\n\n`);
  }
}

async function buildSeabornPythonInExcelCode(cb: CodeBuilder): Promise<string> {
  let code = [];
  // Extra imports
  cb.extraImports.forEach((imp: string) => {
    code.push(`${imp}\n`);
  });
  // Standard imports
  code.push("import seaborn as sns\nimport matplotlib.pyplot as plt\n\n");
  // Data source
  const dfAlias = await getAliasFromRangeSelection(cb.source);

  handleHeaders(await getPythonLocation(), code, cb, dfAlias);
  // Start of plot object
  code.push(`ax = sns.${SeabornChartTypeMap[cb.chartType]}(\n    data=df,`);
  // Add plot attrs
  cb.plotAttrs.forEach((pa: string) => {
    code.push(`\n    ${pa},`);
  });
  code.push("\n)\n");
  // Add plot calls
  cb.plotCalls.forEach((pc: string) => {
    code.push(`\n${pc}`);
  });

  if (cb.chartType !== PlotType.pairwise) {
    code.push("\nplt.tight_layout()");
  }
  code.push("\n\nplt.show()");

  return code.join("");
}
