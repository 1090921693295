import React from "react";
import { Dropdown, Option } from "@fluentui/react-components";
import { InitialCircle } from "../../components/Initial";
import { Col, Row } from "../../components/Layout/Space";
import { ProjectPermission } from "../../../data/projects/models";
import styles from "../../styles.module.scss";

export const RoleSelector = ({
  id,
  name,
  email,
  onRoleSelection,
  onRemove,
  role,
}: {
  id: string;
  name: string;
  email: string;
  onRoleSelection: (userId: string, role: ProjectPermission["relation"]) => void;
  onRemove: (userId: string) => void;
  role?: ProjectPermission["relation"];
}) => {
  return (
    <div key={id}>
      <Row justifyContent="space-between" gap={4}>
        <Col gap={2}>
          <Row alignItems="center" gap={4}>
            <InitialCircle initial={name ? name.charAt(0) : email.charAt(0)} size={15} />
            <span>{name ? name : email}</span>
          </Row>
          <span className="subtitle">{email}</span>
        </Col>
        <Col gap={0} alignItems="flex-end">
          <Dropdown
            style={{ minWidth: 0 }}
            placeholder="Select role"
            appearance="filled-lighter"
            size="small"
            // TODO: Update how we manage the role selection to closely match the API
            defaultValue={role === "reader" || role === "member" || role === "finder" ? "can read" : "can write"}
            onOptionSelect={(_, data) => onRoleSelection(id, data.optionValue as ProjectPermission["relation"])}
          >
            <Option value="reader">can read</Option>
            <Option value="writer">can write</Option>
          </Dropdown>
          <span
            style={{ textDecoration: "underline", fontSize: 11, color: styles.gray400, cursor: "pointer" }}
            onClick={() => onRemove(id)}
          >
            remove
          </span>
        </Col>
      </Row>
    </div>
  );
};
