import React, { useState, useEffect } from "react";
import { Folder20Regular } from "@fluentui/react-icons";
import { Field, Spinner } from "@fluentui/react-components";
import { SearchDropdown } from "../../taskpane/components/SearchDropdown";
import { useProjects } from "../../queryclient/projects/projects";
import { useAccount } from "../../queryclient/account/account";
import { Project, SupportedTags, AccessLevels } from "../projects/models";
import { selectMyProjects, selectUserProjects, selectOrgProjects } from "../projects/utils";
import ProjectsService from "../projects/projects-api";
import { SelectSearchOption } from "react-select-search";

export interface ProjectChooserProperties {
  tag: SupportedTags;
  selection: Project;
  onSelection: (newSelection: Project, isWriteable: boolean) => void; // called when the selection updates
  label?: string;
  placeholder?: string; // text to display if initialValue is blank
  dataTestId?: string;
  disabled?: boolean;
  disabledPlaceholder?: string;
}

const ProjectChooser = (props: ProjectChooserProperties) => {
  const {
    tag,
    selection,
    onSelection,
    label = "",
    placeholder = "Select Project...",
    dataTestId,
    disabled = false,
    disabledPlaceholder = "Initializing...",
  } = props;

  const { isLoading: projectsLoading, data: existingProjects = [] } = useProjects({ tag });
  const { data: account, isLoading: accountLoading } = useAccount();

  const [validationMsg, setValidationMsg] = useState<string>("");
  const [validationState, setValidationState] = useState<"success" | "error">();

  function searchKeyFromProject(proj: Project): SelectSearchOption {
    return { key: proj.id, name: proj.title, value: proj.id };
  }

  const [myProjects, setMyProjects] = useState<SelectSearchOption[]>([]);
  const [userProjects, setUserProjects] = useState<SelectSearchOption[]>([]);
  const [orgProjects, setOrgProjects] = useState<SelectSearchOption[]>([]);
  useEffect(() => {
    setMyProjects(selectMyProjects(existingProjects, account).map(searchKeyFromProject));
    setUserProjects(selectUserProjects(existingProjects, account).map(searchKeyFromProject));
    setOrgProjects(selectOrgProjects(existingProjects).map(searchKeyFromProject));
  }, [projectsLoading, accountLoading]);

  useEffect(() => {
    if (projectsLoading || !selection || selection.name === "") return;
    checkProjectWriteable(selection);
  }, [projectsLoading]);

  async function checkProjectWriteable(project: Project) {
    // Check if project is writeable
    try {
      setValidationState(null);
      setValidationMsg("Checking permissions...");
      const permissions: AccessLevels = await ProjectsService.getMyProjectAccessLevels(project.id);
      if (permissions.own || permissions.modify) {
        setValidationState("success");
        setValidationMsg("Permission to write");
        onSelection(project, true);
      } else {
        setValidationState("error");
        setValidationMsg("Missing write permission");
      }
    } catch (e) {
      console.error(e);
      setValidationState("error");
      setValidationMsg("Error reading project permissions");
    }
  }

  async function selectionChanged(newSelection: string) {
    // Ignore already selected entry
    if (newSelection === selection.name) {
      return;
    }

    // Find existing project with selected title
    const matchingProjects = existingProjects.filter((proj) => proj.id === newSelection);
    if (matchingProjects.length <= 0) throw Error(`No projects found with id: ${newSelection}`);
    const newProject = matchingProjects[0];

    // Update component while checking write permissions
    onSelection(newProject, false);

    await checkProjectWriteable(newProject);
  }

  if (projectsLoading || accountLoading) {
    return (
      <Spinner
        style={{ justifySelf: "flex-start", marginLeft: 4, gap: 2 }}
        size="extra-tiny"
        label="Projects loading..."
      />
    );
  }

  return (
    <Field
      className="project-chooser"
      label={label}
      validationMessage={validationMsg}
      validationState={validationState}
    >
      <SearchDropdown
        Icon={<Folder20Regular />}
        placeholder={disabled ? disabledPlaceholder : placeholder}
        value={selection.id}
        options={[
          {
            type: "group",
            name: "My Projects",
            items: myProjects,
          },
          {
            type: "group",
            name: "User Owned Projects",
            items: userProjects,
          },
          {
            type: "group",
            name: "Org Owned Projects",
            items: orgProjects,
          },
        ]}
        onChange={(selectedValue: string) => selectionChanged(selectedValue)}
        disabled={disabled}
        data-testid={dataTestId}
      />
    </Field>
  );
};
ProjectChooser.displayName = "ProjectChooser";

export default ProjectChooser;
