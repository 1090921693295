import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TagGroup, TagGroupProps, Tag } from "@fluentui/react-components";
import { Filter16Filled } from "@fluentui/react-icons";
import { ValueCircle } from "../../components/ValueCircle";
import { Row } from "../../components/Layout/Space";
import { useDataFilters } from "../../hooks/useDataFilters";
import { Project, SupportedTags } from "../../../data/projects/models";
import { tagToRoutePrefix } from "../../../data/projects/utils";
import styles from "../../styles.module.scss";
import "./Filter.scss";

export const FilterButton = ({ tag, projects }: { tag: SupportedTags; projects: Project[] }) => {
  const navigate = useNavigate();
  const {
    dateModifiedFrom,
    setDateFrom,
    dateModifiedTo,
    setDateTo,
    projectFilter,
    setProjectFilter,
    fileFilter,
    setFileFilter,
  } = useDataFilters();

  const setItemsLookup = {
    dateModifiedFrom: () => setDateFrom(null),
    dateModifiedTo: () => setDateTo(null),
    projectFilter: () => setProjectFilter(""),
    fileFilter: () => setFileFilter(""),
  };

  const filterItems = {
    dateModifiedFrom,
    dateModifiedTo,
    projectFilter,
    fileFilter,
  };

  function displayDate(date: Date) {
    return date.toISOString().slice(0, 10);
  }

  function displayProjectName(id: string) {
    if (!projects) return "";
    const matches = projects?.filter((project) => project.id === id);
    if (matches.length === 0) return "All";
    return matches[0].title;
  }

  const filterDisplay = {
    dateModifiedFrom: displayDate,
    dateModifiedTo: displayDate,
    projectFilter: displayProjectName,
    fileFilter: (x: string) => x,
  };

  const totalCount = Object.values(filterItems).filter((i) => i).length;

  const removeItem: TagGroupProps["onDismiss"] = (_e, { value }) => {
    setItemsLookup[value]();
  };

  return (
    <>
      <Row justifyContent="flex-end">
        <Button
          data-testid="flt-btn"
          disabled={projects?.length == 0}
          icon={<Filter16Filled color={styles.secondary} />}
          className="secondary"
          style={{
            marginRight: 10,
            marginBottom: 10,
            minWidth: 28,
            height: 28,
            backgroundColor: "transparent",
            borderColor: styles.secondary,
          }}
          onClick={() => navigate(`${tagToRoutePrefix(tag)}/filter`)}
        >
          {totalCount > 0 && <ValueCircle value={totalCount} label="Filters" />}
        </Button>
      </Row>
      {totalCount > 0 && (
        <div>
          <TagGroup style={{ flexWrap: "wrap" }} onDismiss={removeItem}>
            {Object.entries(filterItems).map(
              ([id, filt]) =>
                filt && (
                  <Tag data-testid={filterDisplay[id](filt)} dismissible key={id} value={id}>
                    {filterDisplay[id](filt)}
                  </Tag>
                )
            )}
          </TagGroup>
          <Row justifyContent="space-between">
            <label data-testid="item-count" style={{ marginLeft: 12, fontWeight: 400 }}>
              {totalCount === 1 ? `${totalCount} item` : `${totalCount} items`}
            </label>
            <label
              className="filter-label"
              data-testid="flt-label"
              onClick={() => {
                setDateFrom(null);
                setDateTo(null);
                setProjectFilter("");
                setFileFilter("");
              }}
            >
              reset filters
            </label>
          </Row>
        </div>
      )}
    </>
  );
};
