import { createDarkTheme, createLightTheme } from "@fluentui/react-components";
import { createTheme } from "@uiw/codemirror-themes";
import { tags as t } from "@lezer/highlight";

import type { BrandVariants, Theme } from "@fluentui/react-components";

export const anaconda: BrandVariants = {
  10: "#020402",
  20: "#121B10",
  30: "#1A2E19",
  40: "#1F3C1E",
  50: "#244A23",
  60: "#295829",
  70: "#2E672E",
  80: "#327634",
  90: "#37863A",
  100: "#3C963F",
  110: "#40A645",
  120: "#51B553",
  130: "#71C16F",
  140: "#8ECE8A",
  150: "#AADAA5",
  160: "#C5E6C1",
};

const lightTheme: Theme = {
  ...createLightTheme(anaconda),
  fontFamilyBase: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  colorBrandBackground: "#0CCA4A",
  colorBrandForeground1: anaconda[130],
  colorBrandForeground2: anaconda[110],
  colorNeutralBackground1Hover: "rgb(0,0,0,0.05)",
  colorNeutralForeground2BrandSelected: "#0CCA4A",
  colorNeutralForeground1: "#2C303A",
  colorNeutralForeground2: "#6A718A",
  colorNeutralForegroundOnBrand: "#2C303A",
  colorNeutralStroke1: "#E6E6E6",
  colorCompoundBrandForeground1: "#0CCA4A",
  colorCompoundBrandForeground1Hover: "#0CCA4A",
  colorCompoundBrandStroke: "#0CCA4A",
  colorCompoundBrandStrokeHover: "#0CCA4A",
};

const darkTheme: Theme = {
  ...createDarkTheme(anaconda),
};

darkTheme.colorBrandForeground1 = anaconda[110];

const codeDarkTheme = createTheme({
  theme: "dark",
  settings: {
    background: "#1e1e1e",
    foreground: "#9cdcfe",
    caret: "#c6c6c6",
    selection: "#6199ff2f",
    selectionMatch: "#72a1ff59",
    lineHighlight: "#ffffff0f",
    gutterBackground: "#1e1e1e",
    gutterForeground: "#838383",
    gutterActiveForeground: "#fff",
    fontFamily: 'Menlo, Monaco, Consolas, "Andale Mono", "Ubuntu Mono", "Courier New", monospace',
  },
  styles: [
    {
      // def, class, as, None, True
      tag: [t.keyword, t.bool],
      fontWeight: "bold",
      color: "#569cd6",
    },
    {
      // if, else, return, import, try, except
      tag: [t.controlKeyword, t.moduleKeyword],
      color: "#c586c0",
    },
    {
      // function params, decorators, variables
      tag: [t.name, t.variableName],
      color: "#69faf7",
    },
    {
      // class name
      tag: [t.className],
      fontWeight: "550",
      color: "#c42fb8",
    },
    {
      // calling a function
      tag: [t.function(t.variableName)],
      color: "#3dae2b",
    },
    {
      // defining a function
      tag: [t.definition(t.variableName)],
      color: "#dcdcaa",
    },
    {
      // number
      tag: [t.number],
      color: "#b5cea8",
    },
    {
      // operators, comma, dot, escaped characters
      tag: [t.operator, t.punctuation, t.separator, t.escape],
      color: "#d4d4d4",
    },
    {
      // string
      tag: [t.special(t.string), t.string],
      color: "#ce9178",
    },
    {
      // brackets
      tag: [t.squareBracket, t.paren, t.brace],
      color: "#e0e0e0",
    },
    {
      // comment
      tag: [t.comment],
      fontStyle: "italic",
      color: "#656e62",
    },
  ],
});

export { darkTheme, lightTheme, codeDarkTheme };
