import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CloudArrowUp20Regular, Add16Filled } from "@fluentui/react-icons";
import { Button, Text, Dropdown, Field, Input, Label, Option, Spinner, Tooltip } from "@fluentui/react-components";
import { ActionButtonRow, Col, Row } from "../../components/Layout/Space";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import { StreamLanguage } from "@codemirror/language";
import { mathematica } from "@codemirror/legacy-modes/mode/mathematica";
import { editorSetup } from "xlcommon/src/pyscript/editor";
import { codeDarkTheme } from "../../theme";
import ProjectChooser from "../../../data/components/ProjectChooser";
import { ExpandNavigation, Project } from "../../../data/projects/models";
import ProjectsService from "../../../data/projects/projects-api";
import { LANGUAGE_SUFFIXES } from "./SnippetItem";
import { capitalize, ensureFileSuffix } from "../../utils";
import {
  getAliasFromRangeSelection,
  getFormulaFromGrid,
  RangeSelection,
  RangeType,
} from "xlcommon/src/excel/excel-grid-utils";
import { parseFormula, FormulaLanguage } from "xlcommon/src/excel/excel-grid-utils";
import { Mode } from "../../types";
import styles from "../../styles.module.scss";
import "./ExportSnippet.scss";

export type ExportSnippetProps = {
  code?: string;
  project?: Project;
  fileName?: string;
};

const ExportSnippet = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const {
    code: initialCode = "",
    project: initialProject = { name: "" },
    fileName: initialFileName = "",
  }: ExportSnippetProps = location?.state || {};

  const [project, setProject] = useState<Project>(initialProject);
  const [isWriteable, setWriteable] = useState<boolean>(false);
  const [suffix, setSuffix] = useState<string>(LANGUAGE_SUFFIXES[0].suffix);
  const [language, setLanguage] = useState<string>(LANGUAGE_SUFFIXES[0].language);
  const [fileName, setFileName] = useState<string>(initialFileName);
  const [snippet, setCodeSnippet] = useState<string>(initialCode);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (initialFileName !== "") {
      // Determine language type based on file suffix
      const pieces = initialFileName.split(".");
      const initialSuffix = "." + pieces[pieces.length - 1];
      // find suffix in LANGUAGE_SUFFIXES
      LANGUAGE_SUFFIXES.forEach(({ suffix, language }) => {
        if (suffix === initialSuffix) {
          updateLanguage(suffix, language);
        }
      });
    }
  }, []);

  async function handleUpload() {
    setIsUploading(true);
    const uploadName = ensureFileSuffix(fileName, suffix);
    try {
      await ProjectsService.uploadSnippet(project.id, uploadName, snippet, language);
      navigate("/snippets", { state: { fromPath: "export", projectId: project.id } as ExpandNavigation });
    } catch (err) {
      console.error(err);
    }
    setIsUploading(false);
  }

  async function onProjectSelection(value: Project, isWriteable: boolean) {
    setProject({ ...value });
    setWriteable(isWriteable);
  }

  function updateLanguage(suffix: string, language: string) {
    setSuffix(suffix);
    setLanguage(language);
  }

  function isReadyForUpload() {
    if (project.title === "" || snippet === "" || fileName === "") return false;
    if (!isWriteable) return false;
    return true;
  }

  const [selection] = useState<RangeSelection | null>(null);

  async function getFormulasFromGrid() {
    const options = { promptText: "Click to select" };
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
  }

  async function promptCallback(result) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }

    if (selection) {
      let alias = await getAliasFromRangeSelection(selection);
      if (alias === undefined && selection.displayName) {
        alias = selection.displayName;
      }
    }

    const targetAddress = await getAliasFromRangeSelection({
      rangeType: RangeType.CellBinding,
      identifier: result.value.id,
    });

    const rawFormula = await getFormulaFromGrid(targetAddress);
    const { language, formula } = parseFormula(rawFormula);
    setCodeSnippet(formula);
    switch (language) {
      case FormulaLanguage.python:
        updateLanguage(".py", "Python");
        break;
      case FormulaLanguage.r:
        updateLanguage(".r", "R");
        break;
      case FormulaLanguage.excel:
      default:
        updateLanguage(".xl", "Excel Formula");
        break;
    }
  }

  return (
    <div className="tab-content" style={{ overflowY: "scroll" }}>
      <Col gap={16}>
        <Text weight="bold" size={400}>{`${
          capitalize(params.mode as Mode) ?? capitalize(Mode.New)
        } Code Snippet`}</Text>
        <Col gap={8}>
          <ProjectChooser
            tag="code_snippet"
            label="Project"
            selection={project}
            onSelection={onProjectSelection}
            dataTestId="dataUploadProjectChooser"
          />
          <Field label="Snippet Title">
            <Input
              style={{ width: "100%", height: 38 }}
              className="upload-input"
              onChange={(_, data) => setFileName(data.value)}
              value={fileName}
              placeholder="Enter title..."
            />
          </Field>
        </Col>
        <Col gap={5}>
          <Label>Language</Label>
          <Row justifyContent="space-between">
            <Dropdown
              value={language}
              appearance="underline"
              style={{ minWidth: 80 }}
              onOptionSelect={(_, data) => updateLanguage(data.optionValue, data.optionText)}
            >
              {LANGUAGE_SUFFIXES.map(({ suffix, language }) => (
                <Option key={suffix} value={suffix}>
                  {language}
                </Option>
              ))}
            </Dropdown>
            <Tooltip content="Insert code from a cell" relationship="label" withArrow>
              <Button
                appearance="transparent"
                style={{ color: styles.secondary, fontWeight: 400 }}
                onClick={async () => await getFormulasFromGrid()}
                icon={<Add16Filled />}
              >
                Add From Grid
              </Button>
            </Tooltip>
          </Row>
        </Col>
        <CodeMirror
          className="code-snippet-editor"
          height="100%"
          editable={true}
          value={snippet}
          basicSetup={editorSetup}
          theme={codeDarkTheme}
          extensions={[language === "Excel Formula" ? StreamLanguage.define(mathematica) : python()]}
          onChange={(code: string) => setCodeSnippet(code)}
          data-testid="code-snippet-editor"
        />
        <ActionButtonRow justifyContent="flex-end">
          <Button
            className={`action-button ${isUploading ? "saving" : "primary"}`}
            style={{ width: "100%" }}
            onClick={handleUpload}
            appearance="primary"
            disabled={!isReadyForUpload()}
            data-testid="saveCodeSnippet"
            iconPosition="after"
            icon={!isUploading ? <CloudArrowUp20Regular /> : null}
          >
            {isUploading ? <Spinner size="tiny" /> : "Save to Cloud"}
          </Button>
        </ActionButtonRow>
      </Col>
    </div>
  );
};

export default ExportSnippet;
