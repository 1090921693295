import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Spinner,
} from "@fluentui/react-components";
import "./PreviewTable.scss";
import styles from "../styles.module.scss";

export type Column = {
  columnKey: string;
  label: string;
};

/**
 * Usage:
 * 
    const columns: Column[] = [
    { columnKey: "ColumnA", label: "ColumnA" },
    { columnKey: "ColumnB", label: "ColumnB" },
    { columnKey: "ColumnC", label: "ColumnC" },
    ];

    <PreviewTable columns={columns} items={[
            {
                "ColumnA": 1,
                "ColumnB": 2,
                "ColumnC": 3
            },
            {
                "ColumnA": 4,
                "ColumnB": 5,
                "ColumnC": 6
            }
        ]} 
    />
 */

/**
 *
 * @param columns : Column[]
 * @param items : Array of object types to be rendered and cast to string;
 *                The columnKey must match the items key. i.e.
 * [
 *   {
 *     "ColumnA": 32,
 *     "ColumnB": 45
 *   },
 *   ...
 * ]
 * @returns Fluent UI Table preview of Excel cells
 */
export function PreviewTable<T>({
  columns,
  items,
}: {
  columns: Column[];
  items: Array<Record<string, T>>;
}): JSX.Element {
  return (
    <div style={{ margin: 8 }}>
      <label htmlFor="preview-table" className="table-label">
        Preview
      </label>
      <Table id="preview-table" size="small" aria-label="Preview table">
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                key={`${column.columnKey}-${uuidv4()}`}
                style={{ fontWeight: 700, fontSize: 12, overflow: "hidden" }}
                className="column-header"
              >
                {column.label}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`item-${uuidv4()}`}
              className="table-row"
              style={{
                border: 0,
                height: 24,
                fontSize: 12,
                backgroundColor: index % 2 === 0 ? styles.baseBg : "transparent",
              }}
            >
              <TableCell>{item[columns[0].columnKey] as string}</TableCell>
              <TableCell>{item[columns[1].columnKey] as string}</TableCell>
              <TableCell>{item[columns[2].columnKey] as string}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

/**
 * Usage:
 * 
 *    <PrimitivePreviewTable
        columns={["country", "beer_servings", "spirit_servings", "wine_servings", "total_litres_of_pure_alcohol"]}
        items={[
          ["Afghanistan", 0, 0, 0, 0],
          ["Albania", 89, 132, 54, 4.9],
          ["Algeria", 25, 0, 14, 0.7],
          ["Andorra", 245, 138, 312, 12.4],
          ["Angola", 217, 57, 45, 5.9],
        ]}
      />
 */

/**
 * @param param0 columns: string array of column titles
 * @param param1 items: matrix of primitive typed values of each row, which may contain a mix of numbers and strings
 * @returns Table JSX Element
 */
export function PrimitivePreviewTable<T>({
  title,
  columns,
  items,
}: {
  title?: string;
  columns: string[];
  items: T[][]; // Assumes primitive type
}): JSX.Element {
  if (!items) {
    return <Spinner size="tiny" label="Loading data preview..." />;
  }
  return (
    <div className="table-scroller">
      <label data-testid="preview-header" htmlFor="preview-table" className="table-label">
        {`Preview ${title ? `of "${title}"` : ""}`}
      </label>
      <Table
        data-testid="preview-table"
        id="preview-table"
        className="auto-table-layout"
        size="small"
        aria-label="Preview table"
      >
        <TableHeader>
          <TableRow>
            {columns?.map((column) => (
              <TableHeaderCell
                key={`${column as string}-${uuidv4()}`}
                style={{ fontWeight: 700, fontSize: 12, overflow: "hidden" }}
                className="column-header"
              >
                {column as string}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {/* The first row of data is the column data, so ignore the first row */}
          {items?.slice(1).map((row, index) => {
            return (
              <TableRow
                key={`${columns[index]}-${row[index] as string}-${uuidv4()}`}
                className="table-row"
                style={{
                  borderTop: "1px solid #CED2D9",
                  height: 24,
                  fontSize: 12,
                }}
              >
                {/* Only use the index for key if this table data is read-only */}
                {row?.map((item, index) => (
                  <TableCell key={`${columns[index]}-${item as string}-${uuidv4()}`} style={{ wordWrap: "break-word" }}>
                    {item?.toString()}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
