import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./Layout/TopBar";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <TopBar />
      <div className="content">
        {/* https://reactrouter.com/en/main/components/outlet */}
        <Outlet />
      </div>
    </div>
  );
}

export default App;
