import React from "react";
import { AssistantState } from "./types";
import { humaneDate } from "./humaneDates";

// eslint-disable-next-line react/display-name
export const divWithClassName =
  (className: string): ((props: React.PropsWithChildren) => React.ReactElement) =>
  // eslint-disable-next-line react/display-name
  (props: React.PropsWithChildren): React.ReactElement => {
    return (
      <div className={className} {...props}>
        {props.children}
      </div>
    );
  };

// eslint-disable-next-line react/display-name
export const buttonWithClassName =
  (className: string): ((props: React.ButtonHTMLAttributes<HTMLButtonElement>) => React.ReactElement) =>
  // eslint-disable-next-line react/display-name
  (props: React.ButtonHTMLAttributes<HTMLButtonElement>): React.ReactElement => {
    return (
      <button className={className} {...props}>
        {props.children}
      </button>
    );
  };

export function getLocalStorageValue<T>(key: string, defaultValue: T): T {
  try {
    // eslint-disable-next-line no-undef
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  } catch (e) {
    // eslint-disable-next-line no-undef
    console.error("Failed to get local storage value", e);
    return defaultValue;
  }
}

export function useLocalState<T>(key: string, defaultValue: T): readonly [T, React.Dispatch<React.SetStateAction<T>>] {
  const savedState = getLocalStorageValue<T>(key, defaultValue);
  const [state, setState] = React.useState<T>(savedState);
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    // console.log("Saving state", key, state);
    // eslint-disable-next-line no-undef
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);
  return [state, setState] as const;
}

export function calculateContextPercentage(contextLength: number, contextMaxLength = 4096): number {
  return Math.ceil((contextLength / contextMaxLength) * 100);
}

export type GetChatTitleOptions = {
  contextType?: AssistantState["contextType"];
  updatedAt?: AssistantState["updatedAt"];
};

export function getChatTitle({ contextType, updatedAt }: GetChatTitleOptions): string {
  let title = "";
  switch (contextType) {
    case "excel-default-python":
      title = "Working with Python code";
      break;
    case "excel-graph-dataframe":
      title = "Generating graphs from a table or range";
      break;
    case "excel-aggregate-dataframe":
      title = "Aggregate or summarize a table or range";
      break;
    default:
      title = "New chat";
  }
  const date = updatedAt ? ` (${humaneDate(new Date(updatedAt))})` : "";
  return `${title}${date}`;
}

export function safeJsonParse<T>(json: string): T | null {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
}

export function resetTermsAndConditions(): void {
  localStorage.setItem("assistant-accept-terms", JSON.stringify({ accepted: false, version: "" }));
  localStorage.setItem("assistant-enable-data-collection", "false");
  localStorage.setItem("assistant-older-than-13", "false");
}
