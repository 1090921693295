import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@fluentui/react-components";
import { ChevronRight16Regular } from "@fluentui/react-icons";
import "./Lists.scss";

export const ListItem: React.FC<{
  selected?: boolean;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
  disabledText?: string;
  iconColor?: string;
}> = ({ selected, onClick, children, disabled, disabledText, iconColor = "#212121" }) =>
  disabled && disabledText ? (
    <Tooltip content={disabledText} relationship="description" withArrow showDelay={750}>
      <div className="list-item disabled" style={{ marginBottom: 0 }}>
        {children}
        <ChevronRight16Regular color={iconColor} />
      </div>
    </Tooltip>
  ) : disabled ? (
    <div className="list-item disabled" style={{ marginBottom: 0 }}>
      {children}
      <ChevronRight16Regular color={iconColor} />
    </div>
  ) : (
    <div className={`list-item ${selected ? "selected" : ""}`} style={{ marginBottom: 0 }} onClick={onClick}>
      {children}
      <ChevronRight16Regular color={iconColor} />
    </div>
  );

export const NavigationList: React.FC<{
  testID?: string;
  items: {
    text: string;
    to: string;
    selected?: boolean;
    disabled?: boolean;
    disabledText?: string;
  }[];
}> = ({ items, testID }) => {
  const navigate = useNavigate();

  function onClick(path: string) {
    if (path.startsWith("http://") || path.startsWith("https://")) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  }

  return (
    <div className="list" data-testid={testID}>
      {items.map((item) => (
        <ListItem
          key={`listitem-${item.text}-${item.to}`}
          onClick={() => onClick(item.to)}
          selected={item.selected}
          disabled={item.disabled}
          disabledText={item.disabledText}
        >
          {item.text}
        </ListItem>
      ))}
    </div>
  );
};
