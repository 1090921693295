import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Field, Button, Spinner } from "@fluentui/react-components";
import { CloudArrowDown20Regular, TableFreezeRow20Regular, Warning20Regular } from "@fluentui/react-icons";
import IconHeader from "../../../components/Typography";
import { ActionButtonRow } from "../../../components/Layout/Space";
import TableChooser from "../../../../excel/components/TableChooser";
import { CustomDialogWithTrigger } from "../../../components/Dialogs/CustomDialog";
import { PrimitivePreviewTable } from "../../../components/PreviewTable";
import {
  EXCEL_ROW_MAX,
  EXCEL_COLUMN_MAX,
  RangeSelection,
  RangeType,
  getFullAddressFromRangeSelection,
  splitFullAddress,
  readFromRange,
  displayRangeSelection,
} from "xlcommon/src/excel/excel-grid-utils";
import { updateProjectDefinitions } from "../../../../data/data-api";
import { downloadFile } from "../../../../data/data-api-download";
import { CloudProjectDefinition, CloudProjectFileDefinition, Direction } from "../../../../data/models";
import WarningMessageBlock from "./MessageBlock";
import ImportPyObjectCheckbox from "./ImportPythonObject";
import AddressChooser from "../../../../excel/components/AddressChooser";
import { writePyScriptCloudDataCard } from "xlcommon/src/pyscript/local-data";
import { snakeEyesRecord } from "../../../../analytics/snake-eyes";
import styles from "../../../styles.module.scss";
import "./ConnectToWorkbook.scss";
import { getPythonLocation } from "../../../../excel/grid-utils";
import { PythonExecutionLocation } from "../../../../excel/types";
import { ExpandNavigation } from "../../../../data/projects/models";

// For creating / after import source selection
export type DataImportProps = {
  projectId?: CloudProjectDefinition["id"];
  selectedImportFileName: CloudProjectFileDefinition["fileName"];
} & EditDataImportProps;

export enum ImportResolution {
  Download = "download",
  NewVersion = "newVersion",
  ReDownload = "reDownload",
  OverwriteWorkbook = "overwriteWorkbook",
}

function getButtonText(resolution: ImportResolution): string {
  switch (resolution) {
    case ImportResolution.Download:
      return "Download to Workbook";
    case ImportResolution.NewVersion:
      return "Download New Version";
    case ImportResolution.ReDownload:
      return "Re-download Data";
    case ImportResolution.OverwriteWorkbook:
      return "Overwrite Data in Excel";
  }
}

export type EditDataImportProps = {
  id?: CloudProjectDefinition["id"];
  query?: CloudProjectFileDefinition["query"];
  destSelection?: CloudProjectFileDefinition["sourceSelection"];
  resolution?: ImportResolution;
};

const PREVIEW_ROW_COUNT = 6;

const ConnectToWorkbook: React.FC = () => {
  const navigate = useNavigate();

  //  Data source selection (SelectSource) may update the navigation state with the selected import source
  const location = useLocation();

  const {
    selectedImportFileName,
    projectId,
    query: editQuery = "SELECT * FROM data",
    destSelection: editDestSelection,
    resolution = ImportResolution.Download,
  }: DataImportProps = location?.state || {};

  const [sqlQuery, setSQLQuery] = useState<string>(editQuery);
  const [sqlQueryDebounced, setSqlQueryDebounced] = useState<string>(editQuery);
  const debounceTimer = useRef<NodeJS.Timeout>();
  const debounceQueryChange = (value: string) => {
    setSQLQuery(value);
    if (debounceTimer?.current) {
      clearTimeout(debounceTimer?.current);
    }
    debounceTimer.current = setTimeout(() => setSqlQueryDebounced(value), 700);
  };

  const [dataPlacement, setDataPlacement] = useState<RangeSelection>(editDestSelection);
  const [cardViewCell, setCardViewCell] = useState<RangeSelection>(editDestSelection);
  const [importAsLocalObject, setImportAsLocalObject] = useState<boolean>(
    editDestSelection?.rangeType == RangeType.CellBinding
  );

  const [executionLocation, setExecutionLocation] = useState<PythonExecutionLocation>(PythonExecutionLocation.Azure);

  useEffect(() => {
    (async () => {
      const execLoc = await getPythonLocation();
      setExecutionLocation(execLoc);
      // TODO: handle re-download case where local Python connection is made and later execution location is changed to Azure
    })();
  }, []);

  const [canSkipOverwriteWarning, setCanSkip] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      let skippable = false;
      // If sheet is blank, it's okay to skip
      if (dataPlacement?.rangeType === RangeType.Sheet) {
        const fullAddress = await getFullAddressFromRangeSelection(dataPlacement);
        const [, addr] = splitFullAddress(fullAddress);
        if (addr === "A1") {
          const data = await readFromRange(fullAddress);
          if (data[0][0] === "") {
            skippable = true;
          }
        }
      }
      setCanSkip(skippable);
    })();
  }, [dataPlacement?.identifier]); // This detects range selection change

  /**************************
   * Preview state
   **************************/
  const [preview, setPreview] = useState<Array<any>>();
  const [rowCount, setRowCount] = useState<number>();
  const [columnCount, setColumnCount] = useState<number>();
  const [previewError, setPreviewError] = useState<string>();

  useEffect(() => {
    (async () => {
      const newDefinition = buildNewDataDefinition();
      if (newDefinition) {
        try {
          // Download contents to preview, but don't overwrite workbook
          const contentPreview = await downloadFile(
            projectId,
            { ...newDefinition },
            { overwrite: false, previewRowCount: PREVIEW_ROW_COUNT, convert: false }
          );

          if (contentPreview) {
            setPreview([...contentPreview.content]);
            setRowCount(contentPreview.rowCount);
            setColumnCount(contentPreview.columnCount);
            setPreviewError("");
          }
        } catch (err) {
          setPreviewError("There was an error downloading or parsing the file for preview.");
          if (err?.message) {
            console.log("Error in Data Preview ", err.message);
            if (err?.message.includes(" Error:")) {
              // Parser, Binder, Catalog errors
              const hint = err.message.split(" Error: ")[1];
              const capitalized = hint.charAt(0).toUpperCase() + hint.substring(1, hint.length);
              const errorMessage = capitalized?.replace(` LIMIT ${PREVIEW_ROW_COUNT}`, "");
              setPreviewError(errorMessage);
            }
          }
          setPreview([]);
        }
      }
    })();
  }, [sqlQueryDebounced]);

  /*********************************
   * Download state
   *********************************/
  const [isImporting, setIsImporting] = useState<boolean>();
  const [importError, setImportError] = useState<string>("");
  const [placementErrorHint, setPlacementErrorHint] = useState<string>("");
  const [sqlErrorHint, setSqlErrorHint] = useState<string>("");
  useEffect(() => {
    if (isImporting === undefined) {
      return;
    }
    /*
     *  We are done saving or importing, and there are no SQL errors,
     *   so navigate back to the import data page
     */
    if (!isImporting && !importError && placementErrorHint.length === 0 && sqlErrorHint.length === 0) {
      navigate("/data", { state: { fromPath: "import", projectId } as ExpandNavigation });
    }
  }, [isImporting, importError, placementErrorHint, sqlErrorHint]);

  /********************************
   * Import Definition Functions
   ********************************/
  function buildNewDataDefinition(): CloudProjectFileDefinition {
    if (!projectId) return undefined;
    return {
      fileName: selectedImportFileName,
      sourceSelection: importAsLocalObject
        ? cardViewCell // From AddressChooser
        : dataPlacement, // From TableChooser
      query: sqlQuery,
      direction: Direction.Download,
      lastDownloaded: new Date().toISOString(),
    };
  }

  async function importToGrid() {
    setIsImporting(true);
    try {
      const newDefinition = buildNewDataDefinition();
      if (newDefinition) {
        // This is probably not the right place, but I didn't know where else to log for direction.download
        snakeEyesRecord({
          event: "data_connectors/download-connection",
        });
        try {
          if (resolution === ImportResolution.OverwriteWorkbook) {
            // Special handling for conflicted upload
            // Keep the direction as an upload, swap the timestamp, and remove the SQL query
            newDefinition.direction = Direction.Upload;
            newDefinition.lastUploaded = newDefinition.lastDownloaded;
            delete newDefinition.lastDownloaded;
            delete newDefinition.query;
          }
          await updateProjectDefinitions(projectId, newDefinition);
          await downloadFile(projectId, { ...newDefinition });
          setPlacementErrorHint("");
          setSqlErrorHint("");
        } catch (error) {
          if (error?.message) {
            setImportError("There was an error importing the file.");
            console.log("Error in Data Import ", error.message);
            if (
              error.message.startsWith("Error: OverwriteTableError: ") ||
              error.message.startsWith("Error: OverwriteSheetError: ")
            ) {
              // Placement errors
              const hint = error.message.slice(7).split("Error: ")[1];
              setPlacementErrorHint(hint);
            } else if (error?.message.includes(" Error:")) {
              // Parser, Binder, Catalog errors
              const hint = error.message.split(" Error: ")[1];

              const capitalized = hint.charAt(0).toUpperCase() + hint.substring(1, hint.length);
              const errorMessage = capitalized?.replace(` LIMIT ${PREVIEW_ROW_COUNT}`, "");
              setSqlErrorHint(errorMessage);
            }
          }
        }
      }
    } finally {
      setIsImporting(false);
    }
  }

  async function importCardPlaceholder() {
    setIsImporting(true);
    try {
      const newDefinition = buildNewDataDefinition();
      if (newDefinition) {
        try {
          const { fileName, sourceSelection, query } = newDefinition;
          await writePyScriptCloudDataCard(projectId, fileName, query, sourceSelection);
          await updateProjectDefinitions(projectId, newDefinition);
          setPlacementErrorHint("");
          setSqlErrorHint("");
        } catch (error) {
          console.error(error);
        }
      }
    } finally {
      setIsImporting(false);
    }
  }

  return (
    <div className="tab-content connections">
      <IconHeader
        style={{ marginTop: 0 }}
        className="source-header"
        text={selectedImportFileName || "Private"}
        Icon={<TableFreezeRow20Regular />}
      />
      {(rowCount >= EXCEL_ROW_MAX || columnCount >= EXCEL_COLUMN_MAX) && !importAsLocalObject && (
        <WarningMessageBlock settingsLink={executionLocation != PythonExecutionLocation.Local} />
      )}
      <Field label="Workbook Placement" validationMessage={placementErrorHint}>
        {importAsLocalObject ? (
          <AddressChooser
            label={null}
            selection={cardViewCell}
            onSelection={(newSelection: RangeSelection) => setCardViewCell(newSelection)}
          />
        ) : (
          <TableChooser
            selection={dataPlacement}
            onSelection={(newSelection) => setDataPlacement({ ...newSelection })}
            safeForWriting={true}
          />
        )}
        {resolution !== ImportResolution.OverwriteWorkbook && (
          <ImportPyObjectCheckbox
            isDisabled={executionLocation === PythonExecutionLocation.Azure}
            checked={importAsLocalObject}
            onChecked={() => setImportAsLocalObject(!importAsLocalObject)}
          />
        )}
      </Field>
      <Field label="Filter with SQL" validationMessage={sqlErrorHint}>
        <textarea
          style={{ borderColor: styles.gray400 }}
          placeholder="SELECT * FROM data // Hint: refer to the dataset as 'data'"
          // eslint-disable-next-line
          onChange={(e) => debounceQueryChange(e.target.value)}
          value={sqlQuery}
          data-testid="customSQLField"
        />
      </Field>
      <Field validationMessage={previewError}>
        <PrimitivePreviewTable columns={preview?.[0]} items={preview} />
      </Field>
      <ActionButtonRow justifyContent="center">
        {importAsLocalObject || canSkipOverwriteWarning ? (
          <Button
            className={`action-button ${isImporting ? "saving" : "primary"}`}
            style={{ width: "100%" }}
            appearance="primary"
            disabled={importAsLocalObject ? cardViewCell?.displayName === "" : !dataPlacement}
            iconPosition="after"
            icon={<CloudArrowDown20Regular />}
            onClick={importAsLocalObject ? importCardPlaceholder : importToGrid}
            data-testid="dataImportWithoutWarningButton"
          >
            {isImporting ? <Spinner size="extra-small" /> : getButtonText(resolution)}
          </Button>
        ) : (
          <CustomDialogWithTrigger
            title="Warning"
            icon={<Warning20Regular />}
            content={`
              This will overwrite any existing content in ${displayRangeSelection(dataPlacement, false)}.
            `}
            onContinue={importToGrid}
          >
            <Button
              className={`action-button ${isImporting ? "saving" : "primary"}`}
              style={{ width: "100%" }}
              appearance="primary"
              disabled={!(selectedImportFileName && dataPlacement)}
              iconPosition="after"
              icon={<CloudArrowDown20Regular />}
              data-testid="dataImportWithWarningButton"
            >
              {isImporting ? <Spinner size="extra-small" /> : getButtonText(resolution)}
            </Button>
          </CustomDialogWithTrigger>
        )}
      </ActionButtonRow>
    </div>
  );
};

export default ConnectToWorkbook;
