import React, { useEffect, useState, ComponentType, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-components";
import { Folder20Regular, EditRegular, DeleteRegular } from "@fluentui/react-icons";
import { Text } from "@fluentui/react-components";
import { PopoverMenuButton } from "../../components/PopoverMenuButton";
import { PlaceholderItem } from "../../components/PlaceholderItem";
// import { InitialsList } from "../../../components/Initial";
import ProjectsService from "../../../data/projects/projects-api";
import { useProjectFiles } from "../../../queryclient/projects/projects";
import { useDataFilters } from "../../hooks/useDataFilters";
import { ExpandNavigation, Project, ProjectFile, SupportedTags } from "../../../data/projects/models";
import { tagToRoutePrefix } from "../../../data/projects/utils";
import { applyFileFilters } from "./Filter";
import styles from "../../styles.module.scss";

export interface ProjectItemProps {
  project: Project;
  item: ProjectFile;
  refreshItems?: () => Promise<void>;
}

export function ProjectFolder({
  tag,
  project,
  Item,
  refreshItems,
  startExpanded,
}: {
  tag: SupportedTags;
  project: Project;
  Item: ComponentType<ProjectItemProps>;
  refreshItems?: () => Promise<void>;
  startExpanded?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId: highlightProjectId } = (location?.state as ExpandNavigation) || {};
  const folderRef = useRef(null);

  const { data: items, isLoading: itemsLoading } = useProjectFiles(project.id);
  const { dateModifiedFrom, dateModifiedTo, fileFilter } = useDataFilters();
  const [filteredFiles, setFilteredFiles] = useState<ProjectFile[]>();

  useEffect(() => {
    // Scroll to recently used project
    if (project?.id === highlightProjectId) {
      const topY = folderRef.current?.getBoundingClientRect().top + window.scrollY - window.innerHeight / 2;
      folderRef.current?.scrollIntoView({ top: topY, block: "center", inline: "center", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const files = applyFileFilters(items, dateModifiedFrom, dateModifiedTo, fileFilter);
    setFilteredFiles(files);
  }, [dateModifiedFrom, dateModifiedTo, fileFilter, items]);

  async function deleteProject(id: string): Promise<void> {
    try {
      // Soft Delete in API and remove definition
      const res = await ProjectsService.getProjectById(id);

      if (res.ok) {
        const result = await res.json();
        result.metadata.hidden = true;
        await ProjectsService.updateProjectMetadata(id, result);
        // const newDefinitions = { ...projectDefs };
        // delete newDefinitions[id];
        // saveProjectDefinitions({
        //   projects: newDefinitions,
        //   version: VERSION,
        // });
      }
      await refreshItems();
    } catch (e) {
      // TODO: Error logging
      console.log(e);
    }
  }

  return (
    <Accordion ref={folderRef} key="workbook" collapsible defaultOpenItems={"expandMe"}>
      <AccordionItem
        value={startExpanded || project?.id === highlightProjectId ? "expandMe" : ""}
        style={{ position: "relative" }}
      >
        <AccordionHeader
          size="medium"
          expandIconPosition="start"
          icon={<Folder20Regular />}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: project?.id === highlightProjectId ? `2px solid ${styles.primary}` : "unset",
            marginBottom: 4,
          }}
        >
          <Text
            style={{
              marginRight: 2,
              width: "calc(100vw - 130px)",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {project.title}
          </Text>
          {/* TODO: Use initials list to display permissions */}
          {/* <InitialsList initials={["A", "B", "C", "D"]} /> */}
        </AccordionHeader>
        {/* Cannot nest button elements, so must absolutely position menu button */}
        {/* If we are importing / using the cloud, use this menu */}
        <PopoverMenuButton
          kind="folder"
          id={project.id}
          buttonStyle={{ position: "absolute", top: 8, right: -15 }}
          actions={[
            {
              actionName: "Edit",
              onClickAction: () =>
                navigate(`${tagToRoutePrefix(tag)}/new-project`, { state: { projectId: project.id } }),
              icon: <EditRegular />,
            },
            {
              actionName: "Delete",
              onClickAction: async () => await deleteProject(project.id),
              icon: <DeleteRegular />,
            },
          ]}
        />
        <AccordionPanel style={{ margin: 0 }}>
          {filteredFiles &&
            !itemsLoading &&
            filteredFiles.map((item) => (
              <Item key={item.name} project={project} item={item} refreshItems={refreshItems} />
            ))}
          {(itemsLoading || !filteredFiles) && <PlaceholderItem />}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
