import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabList } from "@fluentui/react-components";
import Code from "./Code";
import BaseDesign from "./BaseDesign";
import BaseSetup from "./BaseSetup";
import { preloadPyWorker } from "./viz-preview";
import Preview from "./Preview";
import { plotsLookup } from "../../../data/plot-types";
import { useChart, useChartManager } from "../../hooks/plots/useCentralViz";
import { VizTabType, BaseDesign as IBaseDesign, BaseSetup as IBaseSetup } from "../../hooks/plots/PlotTypes";
import { refreshRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import "./Viz.scss";

function CoreViz() {
  const navigate = useNavigate();
  const { plotType, tab, setTab } = useChartManager();
  // `common` might be undefined here on auto-refresh
  const { common } = useChart<IBaseSetup, IBaseDesign>(plotType);
  const [outputCellStr, setOutputCellStr] = useState<string>("");

  useEffect(() => {
    preloadPyWorker();

    // This should only happen on auto-refresh during development
    if (!plotType) navigate("/viz");
  }, []);

  useEffect(() => {
    if (!common || !common.outputCell || !common.outputCell.identifier) return;

    (async () => {
      const outputCell = await refreshRangeSelection(common.outputCell);
      setOutputCellStr(outputCell.displayName);
    })();
  }, [common?.outputCell]);

  function buildTitle() {
    const chartType = plotsLookup[plotType]?.name || "Unknown";
    if (common?.outputCell?.identifier) {
      return `Edit ${chartType} Chart at ${outputCellStr}`;
    } else {
      return `Create New ${chartType} Chart`;
    }
  }

  return (
    <div className="core-viz" style={{ height: tab === "code" ? "calc(100% - 65px)" : "calc(100% - 8px)" }}>
      <h2>{buildTitle()}</h2>
      <TabList selectedValue={tab} onTabSelect={(_, selectedTab) => setTab(selectedTab.value as VizTabType)}>
        <Tab value="setup">Setup</Tab>
        <Tab value="design">Design</Tab>
        <Tab value="code">Code</Tab>
        <Tab value="preview">Preview</Tab>
      </TabList>
      <div className="tab-content">
        {tab === "setup" && <BaseSetup />}
        {tab === "design" && <BaseDesign />}
        {tab === "code" && <Code />}
        {tab === "preview" && <Preview />}
      </div>
    </div>
  );
}

export default CoreViz;
