import { PyScriptMode, PyScriptOutput, PyScriptLinkage, PyScriptInterpreter } from "./types";
import Env from "../environ";

const VALID_INTERPRETERS = ["py"];

type RegExpMatchArrayWithIndices = RegExpMatchArray & { indices: Array<[number, number]> };

// Matches
// - initial whitespace
// - opening single quote, followed by
// - any characters, followed by
// - `'!` but not `''!`, followed by
// - any characters
const endOfSheetRef = /\s*'.*(?<!')'!.+/;

// Matches
// - `REF(` -- avoiding `XLREF(` or `obj.REF(`, followed by
// - any Python string delimiters: ', ", ''', """ followed by
// - any number of characters, followed by
// - the same string delimiters to close the string, followed by
// - any number of spaces, followed by
// - a closing `)`
// /dg means capture indices of each group and allow multiple matches
/* eslint-disable no-empty-character-class */
const pattXLRef = /(?<![\w.])REF\(\s*('''|"""|'(?!')|"(?!"))(.*?)(?<!\\)(\1)\s*?\)/dg;

// Example:
// x = REF("Sheet1!A1:B27") + 1
//     ^   ^             ^^
// address: Sheet1!A1:B27
// start: 4
// addressStart: 8
// addressEnd: 23
// end: 24
export interface XLRef {
  address: string;
  start: number; // first character
  addressStart: number; // first character of the initial string delimiter
  addressEnd: number; // last character + 1 of the trailing string delimiter
  end: number; // last character + 1
}

/**
 * Parses code looking for `REF("A1")` statements
 *
 * @param code
 * @returns array of XLRef
 */
export function parseCodeForXLRefs(code: string): XLRef[] {
  let refs = [];
  for (let match of code.matchAll(pattXLRef)) {
    const m = match as RegExpMatchArrayWithIndices;

    // Backslash is not allowed in sheet names, so it must be a character escape
    // Remove it to make the reference correct for Excel
    const address = m[2].replaceAll("\\", "");

    refs.push({
      address: address,
      start: m.indices[0][0],
      addressStart: m.indices[1][0],
      addressEnd: m.indices[3][1],
      end: m.indices[0][1],
    });
  }
  return refs;
}

/**
 * Converts Python code that a user would type into
 * Excel grid code using `=ANACONDA.CODE()` custom function
 * which will run the same code, but has dependencies
 * pulled out as references so that Excel correctly
 * handles when to call the custom function.
 *
 * References like REF("A2") are converted into $P2$-style
 * format and the reference A2 is moved to an input
 * argument, isolating the call from inserted/deleted
 * columns and rows.
 *
 * @param interpreter string
 * @param code string
 * @param mode number
 * @param extra string[]
 * @returns Excel formula code
 */
export function convertUserCodeToGridCode(interpreter: string, code: string, mode: number, extra: string[]): string {
  // Check for valid interpreter
  if (VALID_INTERPRETERS.indexOf(interpreter) === -1) {
    throw Error(`Invalid interpreter: ${interpreter}`);
  }
  let newCode = [];
  // Add DO NOT EDIT warning
  newCode.push("### Do Not Edit this formula directly. Use Anaconda Code to modify.\n");
  const refs: XLRef[] = parseCodeForXLRefs(code);
  // Replace all XLRef addresses (including delimiters) with %P2%, %P3%, etc
  const refAddrs: string[] = [];
  let idx = 0;
  refs.forEach((ref) => {
    newCode.push(code.slice(idx, ref.addressStart));
    // If ref has already been seen, reuse it
    const refIdx = refAddrs.indexOf(ref.address);
    if (refIdx === -1) {
      newCode.push(`$P${3 + refAddrs.length}$`); // first three parameters are interpreter + code + mode, so params start at 3
      refAddrs.push(ref.address);
    } else {
      newCode.push(`$P${3 + refIdx}$`);
    }
    idx = ref.addressEnd;
  });
  newCode.push(code.slice(idx));
  // Escape double-quotes for use in a formula string
  let mergedCode = newCode.join("").replace(/"/g, '""');
  // Split code into <255 chunks to avoid Excel formula string size limitation
  if (mergedCode.length > 255) {
    const chunks = [];
    let allChunks = 0;
    // eslint-disable-next-line
    while (true) {
      // When remaining code is small enough, add it as the last chunk
      if (mergedCode.length - allChunks < 255) {
        chunks.push(mergedCode.slice(allChunks));
        break;
      }
      // Otherwise find a chunk that is as large as possible without ending in `"`
      for (let i = 255; i > 0; i--) {
        if (mergedCode[allChunks + i - 1] !== '"') {
          chunks.push(mergedCode.slice(allChunks, allChunks + i));
          allChunks += i;
          break;
        }
      }
    }
    mergedCode = chunks.join('"&"');
  }
  // Build the custom function call with all the pieces
  let r = [`=ANACONDA${Env.CODE_NAMESPACE_SUFFIX}.CODE("${interpreter}", "${mergedCode}", ${mode}`];
  refAddrs.forEach((refAddr) => {
    r.push(`, ${refAddr}`);
  });
  extra.forEach((x) => {
    r.push(`, ${x}`);
  });
  r.push(")");
  return r.join("");
}

// Matches string concatenation characters: "&", but not ""&""
const pattCat255 = /(?<!")"&"/g;

// Matches two-double-quotes or any other non-double-quote character
// followed by `", `
const pattGridCode = /([^"]|"")*", /;

/**
 * Performs the inverse of `convertUserCodeToGridCode`.
 * The Excel formula string beginning `=ANACONDA.CODE(` is converted
 * into normal Python code that a user would type.
 *
 * Excel references which were collapsed into $P2$-style are
 * expanded.
 *
 * @param code string
 * @returns [interpreter, Python code, mode, extras]
 */
export function convertGridCodeToUserCode(code: string): [PyScriptInterpreter, string, number, string[]] {
  // Collapse CAT255 strings into single string
  // This is required because Excel limits string arguments in functions to max 255 characters
  // To work around this limitation, we split the code string into <220 character chunks
  // and combine them with `&`
  code = code.replaceAll(pattCat255, "");
  // Strip off `=ANACONDA{CODE_NAMESPACE_SUFFIX}.CODE("` and trailing `)`
  code = code.slice(Env.CODE_NAMESPACE_SUFFIX.length + 16, -1);
  // Extract interpreter
  const interpEnd = code.indexOf('", "');
  const interp = code.slice(0, interpEnd) as PyScriptInterpreter;
  code = code.slice(interpEnd + 4);
  // Trim off header comments
  code = extractEmbeddedCodeHeaders(code);
  // Extract python code
  const match = code.match(pattGridCode);
  if (!match) throw Error(`Not valid grid code: ${code}`);
  let pyCode = match[0].slice(0, -3); // strip off trailing `", `
  pyCode = pyCode.replaceAll('""', '"');
  // Split mode and reference addresses
  let [mode, ...addresses] = code.slice(match[0].length).split(",");
  // We might have split a sheet name containing a "," -- if so, combine it back together
  for (let i = 0; i < addresses.length - 1; i++) {
    if (addresses[i].trimStart().startsWith("'")) {
      const endOfSheetMatch = addresses[i].match(endOfSheetRef);
      if (!endOfSheetMatch) {
        addresses.splice(i, 2, `${addresses[i]},${addresses[i + 1]}`);
      }
    }
  }
  // Replace $P3$ notation with addresses
  let extras: string[] = [];
  for (let i = 0; i < addresses.length; i++) {
    const addr = addresses[i].trim();
    if (extras.length > 0 || addr === '"link"') {
      extras.push(addr);
    } else {
      pyCode = pyCode.replaceAll(`$P${i + 3}$`, `"${addr.replaceAll('"', '\\"')}"`);
    }
  }

  return [interp, pyCode, parseInt(mode), extras];
}

/**
 * Extracts and removes initial headers in the Python code.
 *
 * Removes the DO NOT EDIT comment
 *
 * @param code string
 * @returns trimmed code
 */
export function extractEmbeddedCodeHeaders(code: string): string {
  // Strip off DO NOT EDIT comment
  if (code.startsWith("###") && code.includes("\n")) {
    code = code.slice(code.indexOf("\n") + 1);
  }
  return code;
}

export function splitMode(mode: number): PyScriptMode {
  // mode is a bitpattern containing both return mode (bit 0) and isolation mode (bit 1)
  return {
    output: mode & (0b01 as PyScriptOutput),
    linkage: mode & (0b10 as PyScriptLinkage),
  };
}

export function combineMode(mode: PyScriptMode): number {
  // Convert modes back into single bitpattern
  return mode.output + mode.linkage;
}

// 5 -> 0.005
// 1500 -> 1.500
// 62123 -> 1:02.123
export function formatMilliseconds(m: number): string {
  if (m < 0) return "";
  const totalSeconds = m / 1000;
  const seconds = totalSeconds % 60;
  const minutes = m / 60_000;
  const builder = [];
  if (minutes >= 1) {
    builder.push(Math.floor(minutes));
    builder.push(":");
    // If we have minutes, we need seconds to always be two digits
    if (seconds < 10) {
      builder.push("0");
    }
  }
  builder.push(seconds.toFixed(3));
  return builder.join("");
}

function findBasicType(s: string): "string" | "number" | "boolean" {
  if (!isNaN(parseFloat(s))) return "number";
  s = s.toLowerCase();
  if (s === "true" || s === "false") return "boolean";
  return "string";
}

// Figure out if data has headers (first row contains string with 2nd row containing non-string)
export function determineHasHeaders(data: any[][]): boolean {
  let hasHeaders = false;
  if (data.length > 1) {
    for (let j = 0; j < data[0].length; j++) {
      const first = findBasicType(data[0][j]);
      const second = findBasicType(data[1][j]);
      // If types in column are not consistent and first is a string, we have headers
      if (first === "string" && second !== "string") {
        hasHeaders = true;
        break;
      }
    }
  }
  return hasHeaders;
}

/**
 * Package version parser based on requirements specifiers documentation
 * https://pip.pypa.io/en/stable/reference/requirement-specifiers/#examples
 * @param packageString : Name of package with specifier, including the version
 * @returns package name and version found
 */
export function parsePackageVersion(packageString: string): { packageName: string | null; version: string | null } {
  const regex = /([^<=>!~]+)(?:([<>=!~]+)([^,]+))?/g;
  const matches = regex.exec(packageString);

  if (matches && matches.length >= 4) {
    const packageName = matches[1].trim();
    const version = matches[3] ? matches[3].trim() : null;
    return { packageName, version };
  } else {
    return { packageName: null, version: null };
  }
}
