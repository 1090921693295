export enum PlotLibrary {
  seaborn = "seaborn",
  plotly = "plotly",
}

export enum PlotType {
  bar = "sns_bar",
  line = "sns_line",
  box = "sns_box",
  regression = "sns_regression",
  scatter = "sns_scatter",
  violin = "sns_violin",
  count = "sns_count",
  pairwise = "sns_pairwise",
  distribution = "sns_distribution",
}

export type PlotTypeDetails = {
  name: string;
  plotType: PlotType;
  library: PlotLibrary;
  imgIcon: string;
  tooltipInfo: string;
  img: string;
  description: string;
};

export const plotsList: PlotTypeDetails[] = [
  {
    name: "Bar",
    plotType: PlotType.bar,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/bar-clustered.svg",
    tooltipInfo: `Show point estimates and errors as rectangle bars.\nClick for more information.`,
    img: "/static/assets/bar-plot.png",
    description: "Show point estimates and errors as rectangle bars.",
  },
  {
    name: "Box",
    plotType: PlotType.box,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/box.svg",
    tooltipInfo: `Draw a box plot to show distributions with respect to categories.\nClick for more information.`,
    img: "/static/assets/box-plot.png",
    description: "Draw a box plot to show distributions with respect to categories.",
  },
  {
    name: "Count",
    plotType: PlotType.count,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/count.svg",
    tooltipInfo: `Show the counts of observations in each categorical bin using bars.\nClick for more information.`,
    img: "/static/assets/count-plot.png",
    description: "Show the counts of observations in each categorical bin using bars.",
  },
  {
    name: "Distribution",
    plotType: PlotType.distribution,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/displot-green.svg",
    tooltipInfo: `Visualize the univariate or bivariate distribution of data.\nClick for more information.`,
    img: "/static/assets/distribution.png",
    description: "Visualize the univariate or bivariate distribution of data.",
  },
  {
    name: "Line",
    plotType: PlotType.line,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/line.svg",
    tooltipInfo: `Draw a line plot with possibility of several semantic groupings.\nClick for more information.`,
    img: "/static/assets/lineplot.png",
    description: "Draw a line plot with possibility of several semantic groupings.",
  },
  {
    name: "Pairwise",
    plotType: PlotType.pairwise,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/pairplot-green.svg",
    tooltipInfo: `Plot pairwise relationships in a dataset.\nClick for more information.`,
    img: "/static/assets/pairwise.png",
    description: "Plot pairwise relationships in a dataset.",
  },
  {
    name: "Regression",
    plotType: PlotType.regression,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/regression.svg",
    tooltipInfo: `Plot data and a linear regression model fit.\nClick for more information.`,
    img: "/static/assets/regression-plot.png",
    description: "Plot data and a linear regression model fit.",
  },
  {
    name: "Scatter",
    plotType: PlotType.scatter,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/scatter.svg",
    tooltipInfo: `Draw a scatter plot with possibility of several semantic groupings.\nClick for more information.`,
    img: "/static/assets/scatter-plot.png",
    description: "Draw a scatter plot with possibility of several semantic groupings.",
  },
  {
    name: "Violin",
    plotType: PlotType.violin,
    library: PlotLibrary.seaborn,
    imgIcon: "/static/assets/violin.svg",
    tooltipInfo: `Plot data and a linear regression model fit.\nClick for more information.`,
    img: "/static/assets/violin-plot.png",
    description: "Draw a combination of boxplot and kernel density estimate",
  },
];

// Lookup object to avoid searching for entry containing plotType
export const plotsLookup = Object.fromEntries(
  plotsList.map((p) => {
    return [p.plotType, p];
  })
);
