import React, { ReactElement } from "react";
import { Button, Dropdown, Option, OptionGroup, Input, Tooltip, Label } from "@fluentui/react-components";
import { ChevronDown20Filled, ChevronUp20Filled } from "@fluentui/react-icons";
import { Row } from "../../../components/Layout/Space";
import { IDropdownOption } from "@fluentui/react";
import "../Viz.scss";

interface DropdownProps<T> {
  label: string;
  labelWidth: number;
  options: T[];
  groupedOptions?: Record<string, T[]>;
  value: string;
  isDisabled?: boolean;
  onChange: (event, data) => void;
  dataTestID?: string;
  placeholder?: string;
  multiselect?: boolean;
  selectedOptions?: string[];
}

export const CustomDropdown: React.FC<DropdownProps<IDropdownOption | string>> = ({
  label,
  labelWidth,
  placeholder,
  options,
  groupedOptions,
  value,
  isDisabled,
  onChange,
  dataTestID,
  multiselect,
  selectedOptions,
}) => {
  const formatSelectedValues = (selectedValues: string | string[]): string => {
    if (Array.isArray(selectedValues)) {
      return selectedValues.join(", ");
    }
  };

  function listOptions(options: (string | IDropdownOption<any>)[], keyPrefix = "") {
    return options.map((option, i) => (
      <Option
        key={`${keyPrefix}${i}`}
        text={typeof option === "string" ? option : option["text"]}
        data-testid={typeof option === "string" ? option : option["text"]}
      >
        {getText(option)}
      </Option>
    ));
  }

  function getText(option: string | IDropdownOption<any>): string {
    if (typeof option === "string") return option;
    if (option.data?.component) return option.data.component;
    return option["text"];
  }

  return (
    <div style={{ margin: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <Dropdown
          className="custom-dropdown"
          data-testid={dataTestID}
          placeholder={placeholder}
          value={multiselect ? formatSelectedValues(value as readonly string[] & string) : (value as string)}
          disabled={isDisabled}
          onOptionSelect={onChange}
          multiselect={multiselect}
          selectedOptions={selectedOptions}
          style={{ minWidth: 135, width: "100%", height: multiselect ? "100%" : 34, minHeight: 34, overflow: "hidden" }}
          // Set positioning so expanded dropdown stays within the current viewport,
          // but can display below or above the initial component. If the number of items
          // are many, scroll bars will appear as necessary.
          // https://react.fluentui.dev/?path=/docs/concepts-developer-positioning-components--docs
          positioning={{ autoSize: true, strategy: "fixed", fallbackPositions: ["below", "above"] }}
          listbox={{ style: { padding: 6 } }}
        >
          {listOptions(options)}
          {options && groupedOptions && (
            // This adds a horizontal line to match the visual style separating groups
            <OptionGroup></OptionGroup>
          )}
          {groupedOptions &&
            Object.keys(groupedOptions).map((optGroup) => (
              <OptionGroup key={optGroup} label={optGroup}>
                {listOptions(groupedOptions[optGroup], `${optGroup}-`)}
              </OptionGroup>
            ))}
        </Dropdown>
      </Row>
    </div>
  );
};

interface DesignInputProps {
  placeholder: string;
  label: string;
  labelWidth: number;
  value: string;
  isDisabled?: boolean;
  onInput: (event: React.FormEvent<HTMLInputElement>) => void;
  dataTestID;
}

export const DesignInputs: React.FC<DesignInputProps> = ({
  placeholder,
  label,
  labelWidth,
  value,
  isDisabled,
  onInput,
  dataTestID,
}) => {
  return (
    <div style={{ margin: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <Input
          data-testid={dataTestID}
          value={value}
          placeholder={placeholder}
          onInput={onInput}
          disabled={isDisabled}
          style={{ minWidth: 135, width: "100%", height: 34 }}
        />
      </Row>
    </div>
  );
};

export const CollapsibleSection = ({
  children,
  isCollapsed,
  toggleCollapse,
  label = "Advanced",
  tooltip,
}: {
  children: React.ReactNode;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  label?: string;
  tooltip?: string;
}) => {
  return (
    <>
      <Row gap={5} justifyContent="space-between">
        {tooltip ? (
          <Tooltip content={tooltip} positioning="below-start" relationship="label" withArrow={true}>
            <Label style={{ paddingTop: 5 }}>{label}</Label>
          </Tooltip>
        ) : (
          <Label style={{ paddingTop: 5 }}>{label}</Label>
        )}
        <Button
          data-testid="advanced-button"
          icon={isCollapsed ? <ChevronDown20Filled /> : <ChevronUp20Filled />}
          onClick={toggleCollapse}
          appearance="transparent"
          style={{ border: "None" }}
        ></Button>
      </Row>
      <div className="collapsible-content">{!isCollapsed && children}</div>
    </>
  );
};

export const WithTooltip = ({
  info,
  disabled,
  children,
}: {
  info: string;
  disabled: boolean;
  children: ReactElement;
}) =>
  disabled ? (
    <Tooltip content={info} relationship="description" withArrow={true} positioning="below-end">
      {children}
    </Tooltip>
  ) : (
    children
  );
