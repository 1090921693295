import React from "react";
import { CodeBuilder } from "../MVCShared/CodeBuilder";
import { BinStats, ChartAttr, Elements, ValueTracker, LineStyle } from "../MVCShared/types";

type DiagonolStyles = {
  bins: number | ValueTracker<number>;
  element: string | ValueTracker<string>;
  stat: string;
  codeRequiresInteraction?: boolean;
};
export function DiagonolKws(args: DiagonolStyles): ChartAttr {
  const { bins, element, stat, codeRequiresInteraction } = args;

  return {
    component: "Pair Plot Advanced Section",
    getReact: () => {
      return <></>;
    },
    getCode: (code: CodeBuilder) => {
      if (!codeRequiresInteraction) return;

      const attributes = [
        element instanceof ValueTracker && element.isUpdated && element.value !== "None"
          ? `element='${Elements[element.value]}'`
          : null,
        bins instanceof ValueTracker && bins.isUpdated ? `bins=${bins.value}` : null,
        stat && stat !== "None" ? `stat='${BinStats[stat]}'` : null,
      ].filter(Boolean);

      if (attributes.length === 0) return;

      const diag_kws = `diag_kws=dict(${attributes.join(", ")})`;
      code.plotAttrs.push(diag_kws);
    },
  };
}

type PlotArgs = {
  lineWidth: number | ValueTracker<number>;
  lineStyle: string | ValueTracker<string>;
  markerSize: number | ValueTracker<number>;
  codeRequiresInteraction?: boolean;
};

export function PlotStyle(args: PlotArgs): ChartAttr {
  const { lineWidth, lineStyle, markerSize, codeRequiresInteraction } = args;

  return {
    component: "Pair Plot Advanced Section",
    getReact: () => {
      return <></>;
    },
    getCode: (code: CodeBuilder) => {
      if (!codeRequiresInteraction) return;
      const attributes = [
        markerSize instanceof ValueTracker && markerSize.isUpdated ? `s=${markerSize.value}` : null,
        lineStyle instanceof ValueTracker && lineStyle.isUpdated ? `ls='${LineStyle[lineStyle.value]}'` : null,
        lineWidth instanceof ValueTracker && lineWidth.isUpdated ? `lw=${lineWidth.value}` : null,
      ].filter(Boolean);

      if (attributes.length === 0) return;

      let start = `plot_kws=dict(${attributes.join(", ")})`;
      code.plotAttrs.push(start);
    },
  };
}
