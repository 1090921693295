import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectedOption } from "react-select-search";
import {
  Body1Stronger,
  Text,
  Button,
  Field,
  Input,
  Textarea,
  Spinner,
  Tooltip,
  Divider,
  InfoLabel,
} from "@fluentui/react-components";
import { Info16Regular, Organization20Regular, PersonAdd20Regular } from "@fluentui/react-icons";
import { Col, Row } from "../../components/Layout/Space";
import { SearchDropdown } from "../../components/SearchDropdown";
import { RoleSelector } from "./RoleSelector";
import ProjectsService from "../../../data/projects/projects-api";
import { getAllOrgUsers, getOrganizationUsers } from "../../../auth/auth-api";
import {
  Project,
  ProjectPermission,
  ProjectPermissionAction,
  SupportedTags,
  ExpandNavigation,
} from "../../../data/projects/models";
import { tagToAppName, tagToRoutePrefix, tagToUserSettings, tagToTitle } from "../../../data/projects/utils";
import { Organization, OrganizationUser, OrganizationUsers } from "../../../auth/models";
import { useProjects } from "../../../queryclient/projects/projects";
import { useOrganizations } from "../../../queryclient/account/account";
import { useAddSettingToDatabase } from "../../../queryclient/settings/settings";
import { snakeEyesRecord } from "../../../analytics/snake-eyes";
import "./NewProject.scss";
import Env from "xlcommon/src/environ";

type UserInvitation = Pick<OrganizationUser, "id" | "email"> & { name: string };

type ProjectNavProps = { projectId: Project["id"] };

export const NewProject = ({ tag }: { tag: SupportedTags }) => {
  const navigate = useNavigate();
  const mutation = useAddSettingToDatabase(tagToUserSettings(tag).LandingSeen);

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string>();

  const { data: existingProjects = [] } = useProjects({ tag });

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  function checkDuplicates(title: string) {
    const duplicates = existingProjects.filter((proj) => proj.title === title);
    const hasDuplicates = duplicates.length > 0;
    setIsDuplicate(hasDuplicates);
    return hasDuplicates;
  }

  const {
    data: organizations = [],
    isLoading: loadingOrganizations,
    isFetched: orgsFetched,
    isSuccess: fetchOrgsSuccess,
  } = useOrganizations();
  const [usersError, setUsersError] = useState<string>("");

  // Note: We only need this hook because the permissions endpoint does not return user name and email.
  //       We may remove this in the future when it does.
  const [usersInfo, setUserInfoForRoles] = useState<Record<string, UserInvitation>>();
  useEffect(() => {
    if (organizations?.length > 0 && !loadingOrganizations && orgsFetched && fetchOrgsSuccess) {
      // Get organization users
      (async () => {
        try {
          const users = await getAllOrgUsers(organizations?.map((org) => org.name));

          // Initialize users state
          let usersMap = {};
          users.forEach((user) => {
            usersMap[user.id] = {
              id: user.id,
              name: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : "",
              email: user.email,
              relation: user.role,
            };
          });
          setUserInfoForRoles({ ...usersMap });
        } catch (e) {
          console.log(e);
          setUsersError("An error occurred obtaining user information.");
        }
      })();
    }
  }, [organizations, loadingOrganizations, orgsFetched, fetchOrgsSuccess]);

  const location = useLocation();
  const { projectId }: ProjectNavProps = location?.state || {};
  const [loadingAccess, setLoadingAccess] = useState<boolean>();
  useEffect(() => {
    // We are in edit mode, so fetch the data we need
    if (projectId && usersInfo) {
      try {
        setLoadingAccess(true);
        (async () => {
          // Populate current project data
          const res = await ProjectsService.getProjectById(projectId);
          const project = await res.json();
          setTitle(project.title);
          setDescription(project.description ?? "");

          // Populate current user roles
          const permsRes = await ProjectsService.getProjectPermissions(projectId);
          let permsMap: Record<string, ProjectPermission> = {};
          let invites: UserInvitation[] = [];
          permsRes.items?.forEach((permission) => {
            if (permission.type === "user_id") {
              if (permsMap[permission.id] && permsMap[permission.id].relation !== "writer") {
                // We already have this user mapped but need to ensure highest privlege
                permsMap[permission.id].relation = permission.relation;
              } else {
                // TODO: Replace this with user data from permissions when available
                // Only render reader, writer, or finder in list since ownership transfer is not yet supported
                if (permission.relation !== "owner") {
                  // Map new found role
                  permsMap[permission.id] = { ...permission };
                  const user = usersInfo[permission.id];
                  invites.push({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                  });
                }
              }

              // Populate general access / organization roles
            } else if (permission.type === "org_id") {
              const orgs = organizations?.filter((org) => org.id === permission.id);
              if (orgs?.length === 1) {
                setGeneralAccess(orgs[0].name);
              }
            } else if (permission.type === "org_name") {
              // TODO: Confirm if API will pass back the org name as the ID if type is org_name
              setGeneralAccess(permission.id);
            }
          });
          setUserRoles({ ...permsMap });
          setInvites([...invites]);
          setLoadingAccess(false);
        })();
      } catch (e) {
        setError("An error occurred when obtaining project data.");
        setLoadingAccess(false);
      }
    }
  }, [projectId, usersInfo]);

  const [userRoles, setUserRoles] = useState<
    Record<string, ProjectPermission & { action?: ProjectPermissionAction["action"] }>
  >({});
  function onRoleSelection(userId: string, role: ProjectPermission["relation"]) {
    setDuplicateMessage("");
    setUserRoles({
      ...userRoles,
      [userId]: {
        id: userId,
        type: "user_id",
        relation: role,
      },
    });
  }

  const [isLoading, setIsLoading] = useState<boolean>();
  const [savedId, setSavedId] = useState<Project["id"] | undefined>();
  async function onSave() {
    try {
      // Manage General Access - If only I, set user type, otherwise set org type.
      //  owner: {
      //     name: <organization name>,
      //     type: organization
      //  }
      setIsLoading(true);

      if (!projectId) {
        // Check duplicate titles against existing project titles if creating a new project
        const hasDuplicates = checkDuplicates(title);
        if (hasDuplicates) return;
      }

      let newProject = {
        title,
        description,
      };
      // Set organization as owner, if specified
      if (generalAccess !== "Only I") {
        newProject["owner"] = {
          name: generalAccess,
          type: "organization",
        };
      }

      let res: Response;
      if (projectId) {
        // Edit Project
        await ProjectsService.updateProjectMetadata(projectId, newProject);
        await sendModifyPermissionsRequest(projectId);
        newProject["id"] = projectId;
      } else {
        // Create Project
        res = await ProjectsService.createProject(tag, newProject);

        if (res.status === 201) {
          const project: Project = await res.json();
          console.log("Created ", project.title);
          newProject["id"] = project.id;
        } else if (res.status === 409) {
          const err_response = await res.json();
          if (err_response?.error?.code?.toLowerCase() === "conflict") {
            // Project already exists -- this could be for several reasons
            const allProjects = await ProjectsService.getMyProjects();
            for (let project of allProjects.items) {
              if (project.title === title) {
                // Check if hidden setting needs to be removed
                if (project.metadata?.hidden) {
                  project.metadata.hidden = false;
                  await ProjectsService.updateProjectMetadata(project.id, { metadata: project.metadata });
                }
                // Check if missing tag needs to be added
                if (project.metadata?.tags && !project.metadata.tags.includes(tag)) {
                  project.metadata.tags.push(tag);
                  await ProjectsService.updateProjectMetadata(project.id, { metadata: project.metadata });
                }
                newProject["id"] = project.id;
                break;
              }
            }
          }

          if (newProject["id"] === null) {
            // no resolution found
            setError("Please verify your project name is unique.");
            setSavedId(undefined);
            return;
          }
        }

        // Invite from organization - Get user role and modify permissions after project creation
        if (newProject["id"]) {
          await sendModifyPermissionsRequest(newProject["id"]);
        }
      }
      // Set success state
      setError("");
      setSavedId(newProject["id"]);
    } catch (e) {
      console.log(e);
      if (e?.error?.code?.toLowerCase() === "conflict") {
        setError("Please verify your project name is unique.");
      } else {
        setError("An error occurred when creating your project. Please try again.");
      }
      setSavedId(undefined);
    } finally {
      setIsLoading(false);
    }
  }

  async function sendModifyPermissionsRequest(projectId: string) {
    const permissions: ProjectPermissionAction[] = Object.values(userRoles).map((userRole) => ({
      ...userRole,
      action: userRole.action ?? "add",
    }));

    let modifiedPermissions = [];
    // TODO: migrate logic to the API -- this is getting complex client-side
    permissions.forEach((permission) => {
      // When removing user permissions, remove both reader and writer permissions
      if (permission.action === "remove") {
        modifiedPermissions.push({
          ...permission,
          relation: permission.relation === "writer" ? "reader" : "writer",
          action: "remove",
        });
      } else {
        // Role has potentially been downgraded, so also request to remove any write permissions
        if (permission.relation === "reader") {
          modifiedPermissions.push({
            ...permission,
            relation: "writer",
            action: "remove",
          });
        }

        // Writer role implies reader role
        if (permission.relation === "writer") {
          modifiedPermissions.push({
            ...permission,
            relation: "reader",
            action: "add",
          });
        }
      }

      // Maintain set permissions
      modifiedPermissions.push({ ...permission });
    });

    await ProjectsService.modifyProjectPermissions(projectId, modifiedPermissions);
    console.log("Modified permissions");
  }

  useEffect(() => {
    if (!error && savedId) {
      navigate(tagToRoutePrefix(tag), { state: { fromPath: "new-project", projectId: savedId } as ExpandNavigation });
    }
  }, [error, savedId]);

  const [generalAccess, setGeneralAccess] = useState<string>("Only I");
  const [orgUsers, setOrgUsers] = useState<OrganizationUsers>({
    filtered_count: 0,
    items: [],
    total_count: 0,
  });

  const [userInvitation, setUserInvitation] = useState<UserInvitation>({
    id: "",
    email: "",
    name: "",
  });
  const [invites, setInvites] = useState<UserInvitation[]>([]);
  const [duplicateInviteMessage, setDuplicateMessage] = useState<string>("");
  function addToInviteList() {
    if (userRoles?.[userInvitation.id] && userRoles?.[userInvitation.id]["action"] !== "remove") {
      setDuplicateMessage("This user already has a specified access level.");
      return;
    }
    setInvites([...invites, { ...userInvitation }]);
    onRoleSelection(userInvitation.id, "reader"); // Default invited user role to reader
  }

  function removeRole(userId: string) {
    const newUserRoles = { ...userRoles };
    newUserRoles[userId]["action"] = "remove";
    setUserRoles(newUserRoles);
    setInvites(invites.filter((user) => user.id !== userId));
  }

  const [selectedOrg, setSelectedOrganization] = useState<Organization["name"]>();
  async function onSetSelectedOrganization(orgName: string) {
    try {
      setSelectedOrganization(orgName);
      const orgUsers: OrganizationUsers = await getOrganizationUsers(orgName);
      setOrgUsers({ ...orgUsers });
    } catch (e) {
      // TODO: Send to future monitoring service
      console.log(e);
    }
  }

  function captureSharing() {
    if (invites.length > 0) {
      snakeEyesRecord({
        event: `${tagToAppName(tag)}/shared-project`,
      });
    }
  }

  return (
    <div className="tab-content new-project">
      <Col gap={15}>
        <Text weight="bold" size={400}>{`${projectId ? "Edit" : "New"} ${tagToTitle(tag)} Project`}</Text>
        <Field
          label="Title"
          validationState={isDuplicate ? "error" : "none"}
          validationMessage={isDuplicate && "Title already exists"}
        >
          <Input
            style={{ padding: 8 }}
            maxLength={120}
            value={title}
            aria-label="title"
            placeholder="Title"
            onChange={(_, data) => setTitle(data.value)}
          />
        </Field>
        <Field label="Description (optional)">
          <Textarea
            maxLength={120}
            value={description}
            aria-label="description"
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Field>
        <Field label="Project Owner">
          {" "}
          {/* General Access */}
          <Row alignItems="center" gap={7}>
            {loadingOrganizations ? (
              <Spinner style={{ marginLeft: 4, marginTop: 2 }} size="tiny" />
            ) : (
              <>
                <SearchDropdown
                  style={{ width: "100%" }}
                  transparent={true}
                  Icon={<Organization20Regular />}
                  data-testid="organizations-dropdown"
                  onChange={(_, option: SelectedOption) => setGeneralAccess(option.name)}
                  defaultValue={generalAccess}
                  value={generalAccess}
                  disabled={!!projectId} // Disabled if editing due to ownership transfer not yet implemented
                  options={[
                    {
                      key: "user",
                      name: "Only I",
                      value: "Only I",
                    },
                    ...organizations.map((org) => ({
                      key: org.id,
                      name: org.name,
                      value: org.name,
                    })),
                  ]}
                />
                <span style={{ width: 120 }}>{generalAccess === "Only I" ? "as an admin" : "admins only"}</span>
              </>
            )}
          </Row>
          <p style={{ fontSize: 13, marginLeft: 4, marginBottom: 0 }}>
            Creating a project owned by an organization grants administrator access to admins of that organization.
            Additionally, you as the creator will have edit access.
          </p>
          <p style={{ fontSize: 13, marginLeft: 4, marginBottom: 0 }}>
            Sharing a project will share all the contents of the project with the selected audience.
          </p>
        </Field>
        <Divider />
        <Field label="Invite from Organization">
          <Col>
            <Field
              label={
                <>
                  <Text weight="bold">Your Organizations</Text>
                  <InfoLabel
                    info={
                      <>
                        <p style={{ textAlign: "center", fontSize: 12 }}>
                          Visit Anaconda Cloud to{" "}
                          <a
                            className="themed-link secondary"
                            href={`${Env.CLOUD_URL}/profile/organizations`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            create organizations
                          </a>
                          .
                        </p>
                      </>
                    }
                  />
                </>
              }
            >
              <SearchDropdown
                style={{ width: "calc(100% - 74px)" }}
                transparent={true}
                placeholder="Click to select"
                Icon={<Organization20Regular />}
                data-testid="invite-organizations-dropdown"
                onChange={(_, option: SelectedOption) => onSetSelectedOrganization(option.name)}
                defaultValue={selectedOrg}
                value={selectedOrg}
                options={[
                  ...organizations.map((org) => ({
                    key: org.id,
                    name: org.name,
                    value: org.name,
                  })),
                ]}
              />
            </Field>
            <Field style={{ marginBottom: 2 }} label="Organization Users">
              <Row alignItems="center">
                <SearchDropdown
                  style={{ width: "100%" }}
                  transparent={true}
                  Icon={<PersonAdd20Regular />}
                  data-testid="invite-org-emails"
                  placeholder="Click to select"
                  onChange={(_, option: SelectedOption) =>
                    setUserInvitation({
                      id: option.key,
                      email: option.name,
                      name: option.firstAndLastName,
                    })
                  }
                  value={userInvitation.email}
                  options={orgUsers?.items?.map((user) => ({
                    key: user.id,
                    name: user.email,
                    value: user.email,
                    firstAndLastName: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : "",
                  }))}
                />
                <Button
                  style={{ minWidth: 50, height: 28 }}
                  appearance="primary"
                  disabled={userInvitation.id === ""}
                  onClick={() => addToInviteList()}
                >
                  Invite
                </Button>
              </Row>
            </Field>
          </Col>
        </Field>
        <Field
          style={{ marginBottom: 85 }}
          label={
            <Row gap={4} alignItems="center">
              <Body1Stronger data-testid="access-header">People with Access</Body1Stronger>
              <Tooltip
                content="Invite people from your organization(s) to access this project."
                relationship="label"
                hideDelay={1}
              >
                <Info16Regular />
              </Tooltip>
            </Row>
          }
          validationMessage={duplicateInviteMessage || usersError}
        >
          <Col gap={10}>
            {invites?.map((userInvite) => (
              <RoleSelector
                key={userInvite.id}
                id={userInvite.id}
                name={userInvite.name}
                email={userInvite.email}
                onRoleSelection={onRoleSelection}
                onRemove={removeRole}
                role={userRoles?.[userInvite.id]?.relation}
              />
            ))}
            {loadingAccess ? (
              <Spinner style={{ marginLeft: 4, marginTop: 2 }} size="tiny" />
            ) : (
              invites?.length === 0 && <span style={{ marginLeft: 4, fontSize: 13 }}>No invites have been sent.</span>
            )}
          </Col>
        </Field>
        <footer style={{ padding: 18, border: 0, backgroundColor: "#fff" }}>
          <Field validationMessage={error}>
            <Button
              style={{ width: "100%" }}
              onClick={async () => {
                mutation.mutate(true);
                await onSave();
                await captureSharing();
              }}
              appearance="primary"
              data-testid="save-new-project"
              // Disable if no title, or if a user was selected to be invited without a specified role
              // If in edit mode, ignore checking number of invitations
              disabled={title === "" || (!projectId && invites.length != Object.keys(userRoles).length)}
            >
              {isLoading ? <Spinner size="tiny" /> : "Save"}
            </Button>
          </Field>
        </footer>
      </Col>
    </div>
  );
};
