import React, { useState } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  DialogActions,
  Button,
  DialogTrigger,
  Divider,
  Spinner,
} from "@fluentui/react-components";
import "../Notification.scss";
import { Col } from "../Layout/Space";
import Env from "xlcommon/src/environ";

export const SignInDialog: React.FC<{
  title: string;
  content: string;
  children?: React.ReactElement;
  onContinue?: () => Promise<void>;
  defaultOpen?: boolean;
  initializing?: boolean;
}> = ({ title, content, children, onContinue, defaultOpen = false, initializing = false }) => {
  const [open, setIsOpen] = useState<boolean>(defaultOpen);
  async function onContinueClick() {
    // Change z-index to allow Web Excel dialog to be visible
    // Note: Fluent UI doesn't expose properties to style the Dialog component in a React-y way
    const dialogSurface = document.getElementsByClassName("custom-dialog-surface")[0] as HTMLDivElement;
    dialogSurface.parentElement.style.zIndex = "99998";

    await onContinue();
    setIsOpen(!open);
  }

  return (
    <Dialog modalType="alert" open={open} onOpenChange={(_, data) => setIsOpen(data.open)} aria-label="Sign In Dialog">
      {children && <DialogTrigger disableButtonEnhancement>{children}</DialogTrigger>}
      <DialogSurface className="custom-dialog-surface">
        <DialogBody>
          <DialogTitle style={{ fontSize: 19 }}>{title}</DialogTitle>
          <DialogContent style={{ fontSize: 16, padding: "20px 0" }}>{content}</DialogContent>
          <DialogActions>
            {/* <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => navigate("/")}>Go Back</Button>
            </DialogTrigger> */}
            <Button size="large" appearance="primary" onClick={() => onContinueClick()} disabled={initializing}>
              {initializing ? <Spinner size="extra-small" /> : "Sign In"}
            </Button>
          </DialogActions>
        </DialogBody>
        <Col>
          <Divider style={{ marginTop: 14 }} />
          <p style={{ textAlign: "center", fontSize: 12 }}>
            Visit Anaconda Cloud to{" "}
            <a className="themed-link secondary" href={Env.CLOUD_URL} target="_blank" rel="noreferrer">
              learn more
            </a>
            {` and `}
            <a className="themed-link secondary" href={Env.CLOUD_URL + "/sign-up"} target="_blank" rel="noreferrer">
              sign up
            </a>
            .
          </p>
        </Col>
      </DialogSurface>
    </Dialog>
  );
};
