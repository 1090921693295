import React from "react";
import { Row } from "./Layout/Space";

export const ValueCircle: React.FC<{ value: number; size?: number; label: string }> = ({ value, size = 15, label }) => (
  <Row justifyContent="space-between" gap={3}>
    <label className="secondary">{label}</label>
    <span className="circle" style={{ height: size, width: size, fontSize: 9 }}>
      {value}
    </span>
  </Row>
);
