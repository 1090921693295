import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { Project } from "./projects/models";

export const PROJECT_DEFINITIONS = "projectDefinitions";
export const VERSION = 2;

export type CloudProjectDefinitions = {
  /*
   * Example: { <id>: { title, name, files: [...] }, ... }
   */
  projects: Record<keyof Pick<Project, "id">, CloudProjectDefinition>;
  version: number;
};

/* 
* Map project file name to associated range selection (file names are unique to the project based on case sensitivity and unicode)
* Example:
{
    title: "March Finances", 
    name: "march-finances",
    files: {
        "marfinances.csv": {
            
        }
    }
}
*/
export type CloudProjectDefinition = Pick<Project, "id" | "title" | "name"> & {
  files: Record<string, CloudProjectFileDefinition>; // fileName: <CloudProjectFileDefinition>
};

export enum Direction {
  Upload = "upload",
  Download = "download",
}

export type CloudProjectFileDefinition = {
  fileName: string; // Redundancy for ease of data usage
  sourceSelection: RangeSelection;
  query?: string; // SQL Query for file download query
  direction: Direction;
  lastUploaded?: string; // Date is serialized as ISO Timestamp
  lastDownloaded?: string; // i.e. "2024-02-23T19:47:25.387Z"
};
