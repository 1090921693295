export const PYTHON_SETTINGS_DEFINITION = "pythonSettings";

export enum PythonExecutionLocation {
  Azure = "Azure",
  Local = "Local",
}

export interface PythonSettings {
  executionLocation: PythonExecutionLocation;
}
