import { RangeSelection, getFullAddressFromRangeSelection, readFromRange } from "xlcommon/src/excel/excel-grid-utils";
import { ChartAttr, Dependency } from "./types";
import { ValueTracker } from "./types";

/****************
 * Dependency Constructors
 ****************/
export function dependencyEqualsValue(object: ChartAttr, value: any): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const componentValue = object.component instanceof ValueTracker ? object.component.value : object.component;
      return valuesAreEqual(componentValue, value);
    },
  };
}

function valuesAreEqual(a: any, b: any): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((element, index) => element === b[index]);
  } else {
    return a === b;
  }
}

export function dependencyNotEqualsValue(object: ChartAttr, excludedValue: any): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const componentValue = object.component instanceof ValueTracker ? object.component.value : object.component;
      return !valuesAreEqual(componentValue, excludedValue);
    },
  };
}

export function dependencyNotOrEqualsValue(objectA: ChartAttr, objectB: ChartAttr, expectedValue: any): Dependency {
  const valueA = objectA.component instanceof ValueTracker ? objectA.component.value : objectA.component;
  const valueB = objectB.component instanceof ValueTracker ? objectB.component.value : objectB.component;
  return {
    object: valueA === expectedValue ? objectA : objectB,
    isSatisfied: () => {
      return valueA !== expectedValue || valueB !== expectedValue;
    },
  };
}

export function dependencyInValues(object: ChartAttr, allowedValues: any[]): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const val = object.component instanceof ValueTracker ? object.component.value : object.component;
      return allowedValues.includes(val);
    },
  };
}

/****************
 * Other Utilities
 ****************/

export const fetchHeaders = async (
  dataRange: RangeSelection,
  hasHeaders: boolean,
  updateSetup: (updates: Record<string, any>) => void
) => {
  const fullAddress = await getFullAddressFromRangeSelection(dataRange);
  if (fullAddress === undefined) return;
  // Read one row of data
  const data = await readFromRange(fullAddress, 1);
  let updatedHeaders: string[] = [];
  if (hasHeaders) {
    updatedHeaders = data[0].filter((item) => typeof item === "string" && item != "");
  } else {
    for (let i = 0; i < data[0].length; i++) {
      updatedHeaders.push(`Column ${(i + 1).toString()}`);
    }
  }

  updateSetup({ headers: updatedHeaders });
  return updatedHeaders;
};
