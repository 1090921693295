import React, { useEffect, useState } from "react";
import { CloudArrowDown16Regular, ChevronRight16Regular } from "@fluentui/react-icons";
import ProjectsExplorer from "../../Projects/ProjectsExplorer";
import { Button, Tab, TabList, Text } from "@fluentui/react-components";
import { Col } from "../../../components/Layout/Space";
import { runExcelSafeContext } from "xlcommon/src/excel/excel-grid-utils";
import { useProjects } from "../../../../queryclient/projects/projects";
import { loadProjectDefinitions } from "../../../../data/data-api";
import { CloudProjectDefinitions } from "../../../../data/models";
import { ConnectorItemCurry } from "./ConnectionItem";
import styles from "../../../styles.module.scss";

type TabTitle = "cloud" | "connections";

function DataConnections() {
  const [activeTab, setActiveTab] = useState<TabTitle>("cloud");

  const { isLoading: projectsLoading, data: projects = [] } = useProjects({ tag: "data" });

  const [projectDefs, setDefs] = useState<CloudProjectDefinitions["projects"]>(
    {} as CloudProjectDefinitions["projects"]
  );
  async function refreshProjectDefinitions() {
    const projectDefinitions = await loadProjectDefinitions();
    setDefs({ ...projectDefinitions?.projects });
  }
  useEffect(() => {
    refreshProjectDefinitions();
  }, []);

  useEffect(() => {
    (async () => {
      await registerExcelWorkbookListeners();
    })();
    return () => {
      (async () => await unRegisterExcelWorkbookListeners())();
    };
  }, []);

  async function registerExcelWorkbookListeners() {
    // Reference: https://learn.microsoft.com/en-us/javascript/api/excel/excel.worksheetcollection?view=excel-js-preview#excel-excel-worksheet[…]tion-onNameChanged-member
    await runExcelSafeContext(async (context) => {
      context.workbook.worksheets.onDeleted.add(refreshProjectDefinitions);
      context.workbook.worksheets.onNameChanged.add(refreshProjectDefinitions);
      await context.sync();
    });
  }

  async function unRegisterExcelWorkbookListeners() {
    await runExcelSafeContext(async (context) => {
      context.workbook.worksheets.onDeleted.remove(refreshProjectDefinitions);
      context.workbook.worksheets.onNameChanged.remove(refreshProjectDefinitions);
      await context.sync();
    });
  }

  async function refreshItems() {
    await refreshProjectDefinitions();
  }

  function shouldDisplayNoConnectionsMessage() {
    if (activeTab !== "connections" || projectsLoading) return false;
    const filteredProjects = projects.filter((project) => projectDefs[project.id]);
    return filteredProjects.length === 0;
  }

  return (
    <div className="data-connections">
      <TabList
        className="bottom-border"
        selectedValue={activeTab}
        onTabSelect={(_, data) => setActiveTab(data.value as TabTitle)}
      >
        <Tab value="cloud">Cloud</Tab>
        <Tab data-testid="connections" value="connections">
          Connections
        </Tab>
      </TabList>
      <div className="tab-content">
        {shouldDisplayNoConnectionsMessage() ? (
          <CreateYourFirstConnection />
        ) : activeTab === "cloud" ? (
          <ProjectsExplorer
            key="all-projects"
            tag="data"
            Item={ConnectorItemCurry({ definitions: projectDefs })}
            refreshItems={refreshItems}
          />
        ) : (
          <ProjectsExplorer
            key="project-connections"
            tag="data"
            Item={ConnectorItemCurry({
              definitions: projectDefs,
              onlyConnections: true,
            })}
            refreshItems={refreshItems}
            allowNewProject={false}
            startExpanded={true}
            additionalProjectFilter={(project) => projectDefs[project.id]}
          />
        )}
      </div>
    </div>
  );
}

function CreateYourFirstConnection() {
  return (
    <div className="first-connection">
      <Col gap={0}>
        <Text data-testid="connection-info-header" as="h2" weight="bold" align="center">
          Create Your First Connection
        </Text>
        <p>
          To connect a dataset from this workbook to Anaconda Cloud, click &quot;New Dataset&quot; on the Cloud tab.
        </p>
        <p>
          To connect a dataset from Anaconda Cloud to this Excel workbook, click the{" "}
          <CloudArrowDown16Regular color={styles.gray400} /> icon next to a Project file on the Cloud tab.
        </p>
        <Button
          size="medium"
          appearance="outline"
          style={{ color: styles.secondary, borderColor: styles.secondary, height: 38, marginTop: 20 }}
          icon={<ChevronRight16Regular />}
          iconPosition="after"
          data-testid="learn-more"
          onClick={() => window.open("https://docs.anaconda.com/excel/data", "_blank")?.focus()}
        >
          Learn More
        </Button>
      </Col>
    </div>
  );
}

export default DataConnections;
