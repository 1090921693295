import React from "react";
import { Col } from "../../components/Layout/Space";

const Trace = ({ content }: { content: string }) => {
  /**
     * 
     * @param codeResult - Full Message Example: 
     * `Traceback (most recent call last):
        File "/lib/python311.zip/_pyodide/_base.py", line 571, in eval_code_async
        await CodeRunner(
        File "/lib/python311.zip/_pyodide/_base.py", line 394, in run_async
        coroutine = eval(self.code, globals, locals)
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        File "", line 30, in
        TypeError: 'NoneType' object is not subscriptable` 
     * @param errorTypeStr - Example substring to find the trace: "Error:"
     * @returns trace string or false is no error
     */
  function extractErrorType(codeResult: string, errorTypeStr = "Error:"): string | false {
    console.log("Extracting error from code result");
    if (codeResult.indexOf("<img") !== -1) {
      return false; // Successful image result
    }
    try {
      // Find the position of the specified error type
      const errorIndex = codeResult.indexOf(errorTypeStr);

      // If the specified error type is found
      if (errorIndex !== -1) {
        // Extract everything before the specified error type
        // (this just indicates the pyodide reference which is not useful currently to the user)
        // const beforeError = codeResult.substring(0, errorIndex).trim();

        // Extract everything after the specified error type and remove line breaks
        let trace = codeResult
          .substring(errorIndex)
          .trim()
          .replace(/<br\s*\/?>/gi, "");
        if (trace.includes("'NoneType' object is not subscriptable")) {
          trace = `${trace}. Does your data contain empty cells or extra data?`;
        }
        return trace;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Col justifyContent="center" alignItems="center" gap={2}>
      <p style={{ fontSize: 13, textAlign: "center" }}>An error occurred in the python script. </p>
      <code className="trace">{extractErrorType(content)}</code>
    </Col>
  );
};

export default Trace;
